import bigDecimal from 'js-big-decimal'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const usePriceRangeFilter = ({
  minPrice,
  maxPrice,
  handleMaxPrice,
  handleMinPrice,
}: {
  minPrice?: string
  maxPrice?: string
  handleMaxPrice: (value: string) => void
  handleMinPrice: (value: string) => void
}) => {
  const [isError, setIsError] = useState(false)
  const [minPriceValue, setMinPriceValue] = useState<string>(minPrice ?? '')
  const [maxPriceValue, setMaxPriceValue] = useState<string>(maxPrice ?? '')

  const handleChangeMinPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isOnlyNumber = e.target.validity.valid
    const value = e.target.value
    if (!isOnlyNumber) return

    setMinPriceValue(value)
    if (
      value !== '' &&
      maxPrice !== undefined &&
      maxPrice !== '' &&
      bigDecimal.compareTo(maxPrice, value) < 0
    ) {
      setIsError(true)
    } else {
      debouncedMinPrice(value)
      debouncedMaxPrice(maxPrice ?? '')
      setIsError(false)
    }
  }

  const handleChangeMaxPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isOnlyNumber = e.target.validity.valid
    const value = e.target.value
    if (!isOnlyNumber) return

    setMaxPriceValue(value)
    if (
      value !== '' &&
      minPrice !== undefined &&
      minPrice !== '' &&
      bigDecimal.compareTo(minPrice, value) > 0
    ) {
      setIsError(true)
    } else {
      debouncedMinPrice(minPrice ?? '')
      debouncedMaxPrice(value)
      setIsError(false)
    }
  }

  const debouncedMinPrice = useMemo(() => debounce(handleMinPrice, 300), [])
  const debouncedMaxPrice = useMemo(() => debounce(handleMaxPrice, 300), [])

  useEffect(() => {
    return () => {
      debouncedMinPrice.cancel()
      debouncedMaxPrice.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClearInputValue = useCallback((type?: 'minPrice' | 'maxPrice') => {
    if (type === undefined) {
      setMinPriceValue('')
      setMaxPriceValue('')
    } else if (type === 'minPrice') {
      setMinPriceValue('')
    } else if (type === 'maxPrice') {
      setMaxPriceValue('')
    }
  }, [])

  const handleResetErrorFromPriceRange = useCallback(() => {
    setIsError(false)
  }, [])

  return {
    minPriceValue,
    maxPriceValue,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    isError,
    handleClearInputValue,
    handleResetErrorFromPriceRange,
  }
}
