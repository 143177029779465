import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountItemsResponse,
  IAccountLikedItemsDebouncedQueryParams,
  IAccountLikedItemsResponse,
  IBaseResponse,
  IGetItemListRequest,
} from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountLikedItems = async (
  accountAddress?: string,
  params?: IGetItemListRequest,
) => {
  const url = `/api/v1/public/accounts/${accountAddress}/like-items`
  const { data } = await withAxios<IBaseResponse<IAccountLikedItemsResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetPublicAccountLikedItems = (
  pathname: string,
  refresh: boolean,
  accountAddress?: string,
  debouncedQueryParams?: IAccountLikedItemsDebouncedQueryParams,
) =>
  useInfiniteQuery<IAccountItemsResponse>(
    [pathname, 'getPublicAccountItems', { debouncedQueryParams }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 10,
        refresh,
      } as IGetItemListRequest
      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }
      const response = await getPublicAccountLikedItems(accountAddress, queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      enabled: accountAddress !== undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
