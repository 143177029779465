import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '..'

export enum Currency {
  KRW = 'KRW',
  USD = 'USD',
}

export const CURRENCY = {
  KRW: { icon: '₩', name: 'KRW' },
  USD: { icon: '$', name: 'USD' },
} as const

export interface ICurrencyState {
  currency: { icon: '₩' | '$'; name: 'KRW' | 'USD' }
}

const initialState: ICurrencyState = {
  currency: CURRENCY[Currency.KRW],
}

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = CURRENCY[action.payload]
    },
  },
})

export const { setCurrency } = currencySlice.actions

export const selectCurrency = (state: AppState) => state.currency.currency

export default currencySlice
