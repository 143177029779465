import { useMutation } from '@tanstack/react-query'

import { ORIGINALS_PAYMENT_TYPE } from '@/shared/constants'
import { Currency } from '@/shared/store/wallet'
import { IPostOriginalsSeatingCardPaymentResponse, ISeatsCheckoutInfoProps } from '@/shared/types'

import withAxios from '../api'

export async function updateSeatsCheckoutCardInfo({
  originalsId,
  eventKey,
  currency,
  selectOptions,
  paymentType,
  holdToken,
}: ISeatsCheckoutInfoProps) {
  const url = '/api/v3/private/kkr-payments/seats-checkout/card'
  const { data } = await withAxios<IPostOriginalsSeatingCardPaymentResponse>({
    url,
    method: 'POST',
    data: {
      originalsId,
      eventKey,
      currency,
      selectOptions,
      paymentType,
      holdToken,
    },
  })

  return data
}

export function useUpdateSeatsCheckoutCardInfo() {
  return useMutation(
    ['useUpdateSeatsCheckoutCardInfo'],
    ({
      originalsId,
      eventKey,
      currency,
      selectOptions,
      paymentType,
      holdToken,
    }: ISeatsCheckoutInfoProps) =>
      updateSeatsCheckoutCardInfo({
        originalsId,
        eventKey,
        currency,
        selectOptions,
        paymentType,
        holdToken,
      }),
  )
}
