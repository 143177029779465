import { useInfiniteQuery } from '@tanstack/react-query'

import { IBaseResponse } from '@/shared/types'
import { IPayoutHistoryListRequest, IPayoutHistoryListResponse } from '@/shared/types/payout'

import withAxios from '../api'

export const getCollectionPayoutHistories = async (params?: IPayoutHistoryListRequest) => {
  const url = `/api/v1/private/current-account/${params?.collectionAddress}/payout/histories`
  const { data } = await withAxios<IBaseResponse<IPayoutHistoryListResponse>>({
    url,
    method: 'GET',
    params: { page: params?.page, perPage: params?.perPage },
  })
  return data
}

export const useGetCollectionPayoutHistories = (
  pathname: string,
  isOwner: boolean,
  collectionAddress?: string,
) =>
  useInfiniteQuery<IPayoutHistoryListResponse>(
    [pathname, 'GetCollectionPayoutHistories', { collectionAddress }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        collectionAddress,
        page: pageParam,
        perPage: 20,
      }
      if (queryParams.collectionAddress === '') {
        delete queryParams['collectionAddress']
      }
      const response = await getCollectionPayoutHistories(queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data?.histories?.length === 20 && data.page + 1) ?? undefined,
      enabled: isOwner,
    },
  )
