import { useQuery } from '@tanstack/react-query'

import { IBaseResponse } from '@/shared/types'

import { IGetItemDetailRequest, IItemDetail } from '../../types/collection'
import withAxios from '../api'

export async function getCollectionItemDetail({
  collectionAddress,
  tokenId,
}: IGetItemDetailRequest) {
  const { data } = await withAxios<IBaseResponse<IItemDetail>>({
    url: `/api/v1/public/collections/${collectionAddress}/${tokenId}`,
    method: 'GET',
  })

  return data
}

export const useCollectionItemDetailQuery = (
  pathname: string,
  { collectionAddress, tokenId }: IGetItemDetailRequest,
) =>
  useQuery(
    [pathname, 'getCollectionItemDetail', { collectionAddress, tokenId }],
    async () => {
      const response = await getCollectionItemDetail({ collectionAddress, tokenId })
      return response.data
    },
    {
      enabled: collectionAddress !== undefined && tokenId !== undefined,
      refetchOnWindowFocus: false,
    },
  )
