import { useQuery } from '@tanstack/react-query'

import { IAccountItemCollectionsResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountItemCollections = async (accountAddress?: string) => {
  const url = `/api/v1/public/accounts/${accountAddress}/items/collections`
  const {
    data: { data: collections },
  } = await withAxios<IBaseResponse<IAccountItemCollectionsResponse>>({ url, method: 'GET' })

  return collections
}

export const useGetPublicAccountItemCollections = (
  pathname: string,
  enabled: boolean,
  accountAddress?: string,
) =>
  useQuery(
    [pathname, 'getPublicAccountItemCollections', { accountAddress }],
    () => getPublicAccountItemCollections(accountAddress),
    {
      enabled: accountAddress !== undefined && enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
