import { ICollectionItemsFilterState } from '../store/filters'
import { isNumeric } from '.'

export const convertCollectionDetailFilterQueryParams = (
  state: ICollectionItemsFilterState,
  options: { exclude?: string[] } = {},
) => {
  const { minPrice, maxPrice } = state

  const filterParam = {
    isListing: state.isListing || isNumeric(minPrice) || isNumeric(maxPrice),
    paymentType: state.paymentType,
    order: state.order,
    orderBy: state.orderBy,
    minPrice: state.minPrice,
    maxPrice: state.maxPrice,
    filterPropertyIds:
      state?.filterPropertyIds
        ?.map(item => item.id)
        .filter(Boolean)
        .join(',')
        .trim() || '',
  }

  if (!isNumeric(minPrice) && !isNumeric(maxPrice)) {
    delete filterParam['paymentType']
  }

  if (options.exclude !== undefined) {
    options.exclude.forEach(key => {
      if (key === 'paymentType') {
        delete filterParam[key]
      }
    })
  }

  return filterParam
}
