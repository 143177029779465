import { useQuery } from '@tanstack/react-query'

import { IAccountActivityCollectionsResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountActivityCollections = async (accountAddress?: string) => {
  const url = `/api/v1/public/accounts/${accountAddress}/activities/collections`
  const {
    data: { data: collections },
  } = await withAxios<IBaseResponse<IAccountActivityCollectionsResponse>>({ url, method: 'GET' })

  return collections
}

export const useGetPublicAccountActivityCollections = (pathname: string, accountAddress?: string) =>
  useQuery(
    [pathname, 'getPublicAccountActivityCollections', { accountAddress }],
    () => getPublicAccountActivityCollections(accountAddress),
    { enabled: accountAddress !== undefined, refetchOnWindowFocus: false },
  )
