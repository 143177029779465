export const DEFAULT_COLLECTION_FILTER = {
  isListing: false,
  paymentType: 'ETH',
  order: undefined,
  orderBy: 'ASC',
  minPrice: undefined,
  maxPrice: undefined,
  filterPropertyIds: '',
} as const

export const DEFAULT_COLLECTION_ACTIVITY_FILTER = {
  orderBy: 'DESC',
  order: 'createdAt',
} as const

export const DEFAULT_ACCOUNT_ACTIVITY_FILTER = {
  order: undefined,
  orderBy: 'DESC',
  tradeTypes: '',
  collectionAddresses: '',
}

export const DEFAULT_ACCOUNT_ITEM_FILTER = {
  isListing: false,
  paymentType: 'ETH',
  order: undefined,
  orderBy: 'DESC',
  minPrice: undefined,
  maxPrice: undefined,
  collectionAddresses: '',
}

export const DEFAULT_ACCOUNT_OFFER_FILTER = {
  order: undefined,
  orderBy: 'DESC',
  collectionAddresses: '',
}

export const DEFAULT_SEARCH_FILTER = {
  isListing: false,
  paymentType: 'ETH',
  order: 'createdAt',
  orderBy: 'DESC',
  minPrice: undefined,
  maxPrice: undefined,
  searchText: '',
}
