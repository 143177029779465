import { useQuery } from '@tanstack/react-query'

import { IHighestPriceOfferByUserForItemResponse } from '@/shared/types'

import withAxios from '../api'

export const getHighestPrice = async (itemId?: string) => {
  const {
    data: { offer },
  } = await withAxios<IHighestPriceOfferByUserForItemResponse>({
    url: `/api/v1/private/items/${itemId?.toLowerCase()}/offer/highest-price`,
    method: 'GET',
  })

  return offer
}

export const useGetHighestPrice = (
  pathname: string,
  accountAddress: string | null,
  isConnectedWallet: boolean,
  itemId?: string,
) => {
  return useQuery(
    [pathname, 'getHighestPrice', { accountAddress, itemId }],
    () => getHighestPrice(itemId),
    {
      enabled: itemId !== undefined && accountAddress !== null && isConnectedWallet,
      refetchOnWindowFocus: false,
    },
  )
}
