import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountSentOfferResponse,
  IAccountSentOffersDebouncedQueryParams,
  IBaseResponse,
  IGetItemListRequest,
  IGetSentOfferListRequest,
} from '@/shared/types'

import withAxios from '../api'

interface IUseGetCurrentAccountSentOffersInfinityQueryProps {
  pathname: string
  refresh: boolean
  debouncedQueryParams?: IAccountSentOffersDebouncedQueryParams
  currentAccountAddress: string | null
}

export const getCurrentAccountSentOffers = async (params?: IGetSentOfferListRequest) => {
  const url = '/api/v1/private/current-account/sent-offers'
  const { data } = await withAxios<IBaseResponse<IAccountSentOfferResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetCurrentAccountSentOffers = ({
  pathname,
  refresh,
  debouncedQueryParams,
  currentAccountAddress,
}: IUseGetCurrentAccountSentOffersInfinityQueryProps) =>
  useInfiniteQuery<IAccountSentOfferResponse>(
    [pathname, 'getCurrentAccountSentOffers', { debouncedQueryParams }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 10,
        refresh,
      } as IGetItemListRequest
      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }
      const response = await getCurrentAccountSentOffers(queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      enabled: currentAccountAddress !== null,
    },
  )
