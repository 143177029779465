import { useQuery } from '@tanstack/react-query'

import { IUseWaitingRoomResponseType } from '@/shared/types'

import { withStrapiAxios } from '../api'

export const getUseWaitingRoom = async () => {
  const url = '/api/use-waiting-rooms'
  const { data } = await withStrapiAxios<IUseWaitingRoomResponseType>({ url })

  return data
}

export const useGetUseWaitingRoom = (pathname: string) =>
  useQuery([pathname, 'getUseWaitingRoom'], () => getUseWaitingRoom(), {
    refetchOnWindowFocus: false,
  })
