import { IAccountActivityFilterState } from '../store/filters/accountActivityFilterSlice'

export const convertAccountActivityItemFilterQueryParams = (state: IAccountActivityFilterState) => {
  return {
    order: state.order,
    orderBy: state.orderBy,
    collectionAddresses: state.collections
      .map(collection => collection.address)
      .filter(Boolean)
      .join(','),
    tradeTypes: state.tradeTypes.filter(Boolean).join(',').trim(),
  }
}
