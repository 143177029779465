import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IUtilitiesItem } from '@/shared/services/market'

import { AppState } from '..'

export interface IBenefitsState {
  benefits: IUtilitiesItem[]
  benefitsRequestIds: string[]
}

const initialState: IBenefitsState = {
  benefits: [],
  benefitsRequestIds: [],
}

export const benefitsSlice = createSlice({
  name: 'benefits',
  initialState,
  reducers: {
    reset: state => {
      state.benefits = []
    },
    mergeData: (state, action: PayloadAction<IBenefitsState>) => {
      state.benefits = [...state.benefits, ...action.payload.benefits]
      state.benefitsRequestIds = [...state.benefitsRequestIds, ...action.payload.benefitsRequestIds]
    },
  },
})

export const selectBenefits = (state: AppState) => state.benefits.benefits
export const selectBenefitsRequestIds = (state: AppState) => state.benefits.benefitsRequestIds

export const { reset, mergeData } = benefitsSlice.actions

export default benefitsSlice
