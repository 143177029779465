import { APP_PATH_TYPE } from '../constants'
import { CustomError } from '../types'
import { getSnapshotInError } from './error'
import { navigate } from './webView'

export const goToAppScreen = (pathType: keyof typeof APP_PATH_TYPE) => {
  const urlMappings = {
    [APP_PATH_TYPE.HOME]: '/MainTabNavigator/ShopStack',
    [APP_PATH_TYPE.MYTEM]: '/MainTabNavigator/NFTStack',
    [APP_PATH_TYPE.DIVE]: null,
    [APP_PATH_TYPE.STORE]: null,
    [APP_PATH_TYPE.VERIFYING]: '/AccountSettingScreen',
  }

  const key = urlMappings[pathType]
  if (key === null) {
    const snapshotInError = getSnapshotInError({
      ...(window !== undefined ? { pathname: window.location.pathname } : {}),
      extra: { pathType },
    })
    new CustomError({
      name: 'GO_TO_APP_SCREEN_ERROR',
      message: '앱 화면으로 이동하지 못했습니다.',
    }).sendToSentry(snapshotInError)
    return null
  }

  return navigate(key)
}
