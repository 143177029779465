import { CHAIN_ID } from '../constants'

export const getChainscanUrl = (chainId: string): string => {
  const urls = {
    [CHAIN_ID.ethereum]: 'https://etherscan.io',
    [CHAIN_ID.sepolia]: 'https://sepolia.etherscan.io',
    [CHAIN_ID.polygon]: 'https://polygonscan.com',
    [CHAIN_ID.amoy]: 'https://www.oklink.com/eth',
  }
  return urls[chainId]
}
