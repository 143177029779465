import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ManagedDialog } from '@/shared/constants'

import { AppState } from '..'

export interface IApplyDialogTextType {
  title: string | null
  description: string | null
}

export interface ICheckRaffleResultDialogTextType {
  title: string | null
  description: string | null
}
export interface ITermsDialogTextsType {
  title: string
  description: string
}

export interface IDialogState {
  selectedDialog: ManagedDialog | null
  isPreparingToOpenDialog: boolean
  applyDialogText: IApplyDialogTextType | null
  checkRaffleResultDialogText: ICheckRaffleResultDialogTextType | null
  termsDialogTexts: ITermsDialogTextsType | null
}

const initialState: IDialogState = {
  selectedDialog: null,
  isPreparingToOpenDialog: false,
  applyDialogText: null,
  checkRaffleResultDialogText: null,
  termsDialogTexts: null,
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    allClose: state => {
      state.selectedDialog = null
      state.isPreparingToOpenDialog = false
    },
    setDialog: (state, action: PayloadAction<ManagedDialog | null>) => {
      state.selectedDialog = action.payload
    },
    setIsPreparingToOpenDialog(state, action: PayloadAction<boolean>) {
      state.isPreparingToOpenDialog = action.payload
    },
    setApplyDialogText(state, action: PayloadAction<IApplyDialogTextType | null>) {
      state.applyDialogText = action.payload
    },
    setCheckRaffleResultDialogText(
      state,
      action: PayloadAction<ICheckRaffleResultDialogTextType | null>,
    ) {
      state.checkRaffleResultDialogText = action.payload
    },
    setTermsDialogTexts(state, action: PayloadAction<ITermsDialogTextsType | null>) {
      state.termsDialogTexts = action.payload
    },
  },
})

export const selectSelectedDialog = (state: AppState) => state.dialog.selectedDialog
export const selectIsPreparingToOpenDialog = (state: AppState) =>
  state.dialog.isPreparingToOpenDialog
export const selectApplyDialogText = (state: AppState) => state.dialog.applyDialogText
export const selectCheckRaffleResultDialogText = (state: AppState) =>
  state.dialog.checkRaffleResultDialogText
export const selectTermsDialogTexts = (state: AppState) => state.dialog.termsDialogTexts

export const {
  allClose,
  setDialog,
  setIsPreparingToOpenDialog,
  setApplyDialogText,
  setCheckRaffleResultDialogText,
  setTermsDialogTexts,
} = dialogSlice.actions

export default dialogSlice
