export const SEARCH_RESULT_TAB_KEY = {
  all: 'all',
  item: 'item',
  collection: 'collection',
  originals: 'originals',
} as const

export const SEARCH_RESULT_TAB: { key: keyof typeof SEARCH_RESULT_TAB_KEY; name: string }[] = [
  {
    key: 'all',
    name: '전체',
  },
  {
    key: 'item',
    name: 'NFT',
  },
  {
    key: 'collection',
    name: '컬렉션',
  },
  {
    key: 'originals',
    name: '오리지널스',
  },
]

export const SEARCH_HOME_LIST_ITEM_THEME: { [key: string]: string } = {
  twoLineSection: 'twoLineSection',
  threeLineSection: 'threeLineSection',
}
