import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountActivityDebouncedQueryParams,
  IAccountActivityListResponse,
  IBaseResponse,
  IGetItemListRequest,
} from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountActivities = async (params?: IGetItemListRequest) => {
  const url = '/api/v1/private/current-account/activities'
  const { data } = await withAxios<IBaseResponse<IAccountActivityListResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetCurrentAccountActivities = (
  pathname: string,
  accountAddress: string | null,
  refresh: boolean,
  debouncedQueryParams?: IAccountActivityDebouncedQueryParams,
) =>
  useInfiniteQuery<IAccountActivityListResponse>(
    [pathname, 'getCurrentAccountActivities', { debouncedQueryParams, refresh }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        refresh,
        perPage: 10,
        page: pageParam,
        tradeTypes: 'SALE,LISTING,TRANSFER',
      } as IGetItemListRequest

      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }

      const response = await getCurrentAccountActivities(queryParams)

      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      enabled: accountAddress !== null,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
