import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IBaseResponse,
  IGetItemDetailQueryProps,
  IItemActivityResponse,
  IUseItemDetailActivitiesInfiniteQueryProps,
} from '@/shared/types'

import withAxios from '../api'

export default async function getItemDetailActivity({
  collectionAddress,
  tokenId,
  params,
}: IGetItemDetailQueryProps) {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IItemActivityResponse>>({
    url: `/api/v1/public/collections/${collectionAddress}/${tokenId}/activities`,
    method: 'GET',
    params,
  })
  return data
}

export function useItemDetailActivitiesInfiniteQuery(
  pathname: string,
  { query, collectionAddress, tokenId }: IUseItemDetailActivitiesInfiniteQueryProps,
) {
  return useInfiniteQuery(
    [pathname, 'getItemDetailActivity', { query }],
    async ({ pageParam }) => {
      const params = { page: pageParam, perPage: 5 }
      const response = await getItemDetailActivity({
        collectionAddress,
        tokenId,
        params,
      })
      return response
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      enabled: collectionAddress !== undefined && tokenId !== undefined,
      refetchOnWindowFocus: false,
    },
  )
}
