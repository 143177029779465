import { getPayAmountsByPrice } from '@modernlion/marketplace-registry'
import { convertPriceToBigNumber, ZERO_ADDRESS } from '@modernlion/solidity-registry'
import { Seaport } from '@opensea/seaport-js'
import { ItemType, OrderType } from '@opensea/seaport-js/lib/constants'
import { CreateOrderInput, OrderComponents, OrderWithCounter } from '@opensea/seaport-js/lib/types'
import dayjs from 'dayjs'

import { PROVIDER_KEYS } from '../constants'
import { getWeb3Provider } from '../services/ethers'
import { IListingItem, instanceOfListingItem, IOfferItem, ITradeAddress } from '../types'
import { getTokenAddress } from './getTokenAddress'

/**
 * listing이나 offer를 위한 거래 정보에 서명하는 함수
 *
 * @param chainId: chainId (해당 아이템이 어떤 chainId에 속하는지)
 * @param resourceForCreateOrder : CreateOrderInput (Seaport의 createOrder 메소드에 넘겨주기 위한 리소스)
 * @param currentAccountAddress : string (거래를 생성하려는 유저의 accountAddress)
 * @param tradeAddress : ITradeAddress (거래를 위한 주소)
 * @param setOrder : (order: OrderWithCounter) => void (생성된 order를 저장하기 위한 함수)
 * @param setOrderHash : (orderHash: string) => void (생성된 orderHash를 저장하기 위한 함수)
 * @param setSignature : (signature: string) => void (생성된 signature를 저장하기 위한 함수)
 * @param setLoading : (loading: boolean) => void (UI의 loading을 제어하기 위한 함수)
 * @param setError : (error: string) => void (UI의 error handling을 제어하기 위한 함수)
 * @returns
 */
export const createOrder = async (
  chainId: keyof typeof PROVIDER_KEYS,
  resourceForCreateOrder: CreateOrderInput,
  currentAccountAddress: string,
  tradeAddress: ITradeAddress,
  setOrder: (order: OrderWithCounter) => void,
  setOrderHash: (orderHash: string) => void,
  setCounter: (counter: number) => void,
  setSignature: (signature: string) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string) => void,
) => {
  const web3Provider = getWeb3Provider(chainId)
  if (web3Provider instanceof Error) {
    return setError(web3Provider.message)
  }
  const signer = web3Provider.getSigner()

  const { conduit, conduitKey } = tradeAddress

  try {
    const signerSeaportInstance = new Seaport(signer, {
      conduitKeyToConduit: { [conduitKey]: conduit },
      overrides: { defaultConduitKey: conduitKey },
    })

    setLoading(true)

    const { executeAllActions } = await signerSeaportInstance.createOrder(
      resourceForCreateOrder,
      currentAccountAddress,
    )

    const order = await executeAllActions()
    const orderHash = signerSeaportInstance.getOrderHash(order.parameters)
    const counter = await signerSeaportInstance.getCounter(currentAccountAddress)
    setOrder(order)
    setSignature(order.signature)
    setOrderHash(orderHash)
    setCounter(counter)
  } catch (e: any) {
    setError(e.message)
  } finally {
    setLoading(false)
  }
}

/**d
 * purchase나 accept를 위해 미리 생성된 order에 fulfill하는 함수
 *
 * @param chainId : keyof typeof PROVIDER_KEYS (해당 아이템이 어떤 chainId에 속하는지)
 * @param currentAccountAddress : string (거래를 생성하려는 유저의 accountAddress)
 * @param order : OrderwithCounter (fulfill하려고 하는 order)
 * @param tradeAddress : ITradeAddress (거래를 위한 주소)
 * @param setTxHash : (txHash: string) => void (트랜젝션 해시값을 처리하기 위한 함수)
 * @param setCompleted : () => void (fulfill이 완료되었는지 여부를 처리하기 위한 함수)
 * @param setLoading : (loading: boolean) => void (UI의 loading을 제어하기 위한 함수)
 * @param setError : (error: string) => void (UI의 error handling을 제어하기 위한 함수)
 * @param setFailedForUserDenied : () => void (유저가 취소한 거래인지를 제어하기 위한 함수)
 * @returns
 */
export const fulfillOrder = async (
  chainId: keyof typeof PROVIDER_KEYS,
  currentAccountAddress: string,
  order: OrderWithCounter,
  tradeAddress: ITradeAddress,
  setTxHash: (txHash: string) => void,
  setCompleted: () => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string) => void,
  setFailedForUserDenied: () => void,
) => {
  const web3Provider = getWeb3Provider(chainId)
  if (web3Provider instanceof Error) {
    return setError(web3Provider.message)
  }
  const signer = web3Provider.getSigner()

  const { conduit, conduitKey } = tradeAddress

  try {
    const signerSeaportInstance = new Seaport(signer, {
      conduitKeyToConduit: { [conduitKey]: conduit },
      overrides: { defaultConduitKey: conduitKey },
    })

    setLoading(true)

    const { executeAllActions } = await signerSeaportInstance.fulfillOrder({
      order,
      accountAddress: currentAccountAddress,
    })

    const tx = await executeAllActions()

    setTxHash(tx.hash)
    const receipt = await tx.wait()
    if (receipt.status === 1) {
      setCompleted()
    }
  } catch (e: any) {
    if (e.code === 4001) setFailedForUserDenied()
    else setError(e.message)
  } finally {
    setLoading(false)
  }
}

/**
 *
 * @param chainId : keyof typeof PROVIDER_KEYS (해당 아이템이 어떤 chainId에 속하는지)
 * @param tokenId : string (취소하려는 아이템의 tokenId)
 * @param itemId : string (취소하려는 아이템의 DB Id)
 * @param tradeAddress : ITradeAddress (거래를 위한 주소)
 * @param tradeItemListToCancel : IListingItem[] | IOfferItem[] (취소하려는 아이템 목록)
 * @param requestCancelTrade : (itemId: string, txHash: string) => Promise<void> (취소가 누락되지 않고 다음 동작을 할 수 있도록 서버에 취소 요청을 보내는 함수)
 * @param setTxHash : (txHash: string) => void (트랜젝션 해시값을 처리하기 위한 함수)
 * @param setCompleted : () => void (cancel이 완료되었는지 여부를 처리하기 위한 함수)
 * @param setLoading : (loading: boolean) => void (UI의 loading을 제어하기 위한 함수)
 * @param setError : (error: string) => void (UI의 error handling을 제어하기 위한 함수)
 * @param setFailedForUserDenied : () => void (유저가 서명을 취소했는지를 제어하기 위한 함수)
 * @returns
 */
export const cancelAllTrade = async (
  chainId: keyof typeof PROVIDER_KEYS,
  tokenId: string,
  itemId: string,
  tradeAddress: ITradeAddress,
  tradeItemListToCancel: IListingItem[] | IOfferItem[],
  requestCancelTrade: (itemId: string, txHash: string) => Promise<boolean>,
  setTxHash: (txHash: string) => void,
  setCompleted: () => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string) => void,
  setFailedForUserDenied: () => void,
) => {
  let txHashForCancel
  try {
    const orders = tradeItemListToCancel.map(tradeItem => {
      if (instanceOfListingItem(tradeItem)) {
        const listingItem = tradeItem as IListingItem
        return makeOrderWithCounterForListing(tokenId, listingItem.listing.signHash, listingItem)
      } else {
        const offerItem = tradeItem as IOfferItem
        return makeOrderWithCounterForOffer(tokenId, offerItem.offer.signHash, offerItem)
      }
    })

    const web3Provider = getWeb3Provider(chainId)
    if (web3Provider instanceof Error) {
      setLoading(false)
      return setError(web3Provider.message)
    }
    const signer = web3Provider.getSigner()

    const { conduit, conduitKey } = tradeAddress

    const signerSeaportInstance = new Seaport(signer, {
      conduitKeyToConduit: { [conduitKey]: conduit },
      overrides: { defaultConduitKey: conduitKey },
    })

    const orderHashes = orders.map((order, index) => {
      return {
        orderIndex: index,
        orderHash: signerSeaportInstance.getOrderHash(order.parameters),
      }
    })

    const orderComponents = await Promise.all(
      orderHashes.map(async ({ orderIndex, orderHash }) => {
        const status = await signerSeaportInstance.getOrderStatus(orderHash)
        if (!status.isCancelled) return orders[orderIndex].parameters
      }),
    )
    const filteredOrderComponents = orderComponents.filter(
      (order: OrderComponents | undefined): order is OrderComponents => order !== undefined,
    )

    const tx = await signerSeaportInstance.cancelOrders(filteredOrderComponents).transact()

    txHashForCancel = tx.hash
    setTxHash(txHashForCancel)

    await tx.wait()
  } catch (e: any) {
    if (e.code === 4001) setFailedForUserDenied()
    else setError(e.message)
  }

  if (!txHashForCancel) {
    setLoading(false)
    setError('txHashForCancel is undefined')
    return
  }

  try {
    await requestCancelTrade(itemId, txHashForCancel)
    setCompleted()
  } catch (e: any) {
    setError(e.message)
  } finally {
    setLoading(false)
  }
}

/**
 * 판매 등록된 아이템을 구매하기 위해 소유자가 생성한 order를 DB값으로 복원하는 함수
 *
 * @param tokenId : string (아이템의 tokenId)
 * @param signature : string (서명값)
 * @param listingItem : IListingItem (판매 등록된 아이템)
 * @returns
 */
export const makeOrderWithCounterForListing = (
  tokenId: string,
  signature: string,
  listingItem: IListingItem,
) => {
  const { listing, royalties, royaltyReceivers, marketFee, marketOwner } = listingItem
  const { conduitKey, zone, zoneHash } = listing.tradeAddress

  const tokenAddress = getTokenAddress(listing.paymentType)
  const defaultConsideration = {
    itemType: tokenAddress === ZERO_ADDRESS ? ItemType.NATIVE : ItemType.ERC20,
    token: tokenAddress,
    identifierOrCriteria: '0',
  }

  const { priceExcludingRoyalties, marketRoyalty, collectionRoyalties } = getPayAmountsByPrice(
    listing.price,
    marketFee,
    royalties,
  )

  const consideration = [
    {
      ...defaultConsideration,
      startAmount: convertPriceToBigNumber(priceExcludingRoyalties, listing.paymentType).toString(),
      endAmount: convertPriceToBigNumber(priceExcludingRoyalties, listing.paymentType).toString(),
      recipient: listing.accountAddress,
    },
    {
      ...defaultConsideration,
      startAmount: convertPriceToBigNumber(marketRoyalty, listing.paymentType).toString(),
      endAmount: convertPriceToBigNumber(marketRoyalty, listing.paymentType).toString(),
      recipient: marketOwner,
    },
    ...royaltyReceivers.map((receiver, index) => ({
      ...defaultConsideration,
      startAmount: convertPriceToBigNumber(
        collectionRoyalties[index],
        listing.paymentType,
      ).toString(),
      endAmount: convertPriceToBigNumber(
        collectionRoyalties[index],
        listing.paymentType,
      ).toString(),
      recipient: receiver,
    })),
  ]

  const order: OrderWithCounter = {
    signature,
    parameters: {
      offer: [
        {
          itemType: ItemType.ERC721,
          token: listing.collectionAddress,
          identifierOrCriteria: tokenId,
          startAmount: '1',
          endAmount: '1',
        },
      ],
      consideration,
      salt: listing.salt,
      orderType: OrderType.FULL_OPEN,
      conduitKey,
      zone,
      endTime: String(dayjs(listing.expiredAt).unix()),
      startTime: String(dayjs(listing.makingTime).unix()),
      counter: listing.counter,
      offerer: listing.accountAddress,
      zoneHash,
      totalOriginalConsiderationItems: consideration.length,
    },
  }

  return order
}

/**
 * 소유 중인 아이템에 온 제안을 수락하기 위해 제안자가 생성한 order를 DB값으로 복원하는 함수
 *
 * @param tokenId : string (아이템의 tokenId)
 * @param signature : string (서명값)
 * @param offerItem : IOfferItem (제안이 온 아이템)
 * @returns
 */
export const makeOrderWithCounterForOffer = (
  tokenId: string,
  signature: string,
  offerItem: IOfferItem,
) => {
  const { offer, royalties, marketFee, marketOwner } = offerItem
  const { conduitKey, zone, zoneHash } = offer.tradeAddress

  const tokenAddress = getTokenAddress(offer.paymentType)
  const defaultOffer = {
    itemType: ItemType.ERC20,
    token: tokenAddress,
    identifierOrCriteria: '0',
  }

  const { marketRoyalty } = getPayAmountsByPrice(offer.price, marketFee, royalties)

  const consideration = [
    {
      itemType: ItemType.ERC721,
      token: offer.collectionAddress,
      identifierOrCriteria: tokenId,
      startAmount: '1',
      endAmount: '1',
      recipient: offer.accountAddress,
    },
    {
      itemType: ItemType.ERC20,
      token: tokenAddress,
      startAmount: convertPriceToBigNumber(marketRoyalty, offer.paymentType).toString(),
      endAmount: convertPriceToBigNumber(marketRoyalty, offer.paymentType).toString(),
      identifierOrCriteria: ZERO_ADDRESS,
      recipient: marketOwner,
    },
  ]

  const order: OrderWithCounter = {
    signature,
    parameters: {
      offer: [
        {
          ...defaultOffer,
          startAmount: convertPriceToBigNumber(offer.price, offer.paymentType).toString(),
          endAmount: convertPriceToBigNumber(offer.price, offer.paymentType).toString(),
        },
      ],
      consideration,
      salt: offer.salt,
      orderType: OrderType.FULL_OPEN,
      conduitKey,
      zone,
      endTime: String(dayjs(offer.expiredAt).unix()),
      startTime: String(dayjs(offer.makingTime).unix()),
      counter: offer.counter,
      offerer: offer.accountAddress,
      zoneHash,
      totalOriginalConsiderationItems: consideration.length,
    },
  }

  return order
}
