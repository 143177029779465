import dayjs from 'dayjs'

export const convertToExpirationTime = (
  value: number,
  selectedExpirationDate: Date | null | undefined,
  selectedExpirationTime: string | null | undefined,
) => {
  if (value === 1) {
    return dayjs().add(1, 'day').format()
  } else if (value === 2) {
    return dayjs().add(3, 'day').format()
  } else if (value === 3) {
    return dayjs().add(1, 'week').format()
  } else if (value === 4) {
    return dayjs().add(1, 'month').format()
  } else if (value === 5) {
    return dayjs().add(3, 'month').format()
  } else if (value === 6) {
    return dayjs().add(6, 'month').format()
  } else {
    const timeData = selectedExpirationTime?.split(':')
    if (timeData === undefined) return dayjs(selectedExpirationDate).format()
    return dayjs(selectedExpirationDate)
      .set('hour', Number(timeData[0]))
      .set('minute', Number(timeData[1]))
      .format()
  }
}
