import { CustomError } from '../types'
import { getSnapshotInError } from './error'

export const sendMessageToApp: <T>(type: string, data: T) => void = (type, data) => {
  if (window === undefined || window.ReactNativeWebView === undefined) return
  try {
    const sendData = { type, data }
    window.ReactNativeWebView.postMessage(JSON.stringify(sendData))
  } catch (e: unknown) {
    if (e instanceof Error) {
      const snapshotInError = getSnapshotInError({
        pathname: window.location.pathname,
      })
      new CustomError({
        name: 'UNHANDLED_ERROR',
        message: e.message,
      }).sendToSentry(snapshotInError)
    }
  }
}

export const navigate: (key: string) => void = key => {
  if (window === undefined || window.ReactNativeWebView === undefined) return
  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'navigation', data: key }))
  } catch (e: unknown) {
    if (e instanceof Error) {
      const snapshotInError = getSnapshotInError({
        pathname: window.location.pathname,
      })
      new CustomError({
        name: 'UNHANDLED_ERROR',
        message: e.message,
      }).sendToSentry(snapshotInError)
    }
  }
}

export const requestLocaleToApp = () => {
  if (window === undefined || window.ReactNativeWebView === undefined) return
  try {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'language' }))
  } catch (e: unknown) {
    if (e instanceof Error) {
      const snapshotInError = getSnapshotInError({
        pathname: window.location.pathname,
      })
      new CustomError({
        name: 'UNHANDLED_ERROR',
        message: e.message,
      }).sendToSentry(snapshotInError)
    }
  }
}
