import { useDebouncedValue } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ManagedDialog } from '../constants'
import { useGetCurrentAccountSentOfferCollections } from '../services/accounts'
import { accountOfferFilterSlice, accountOfferFilterState } from '../store/filters'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { offerCancelDialogOpen, offerEditDialogOpen } from '../store/thunks/thunksForOffer'
import { selectCurrentAccountAddress } from '../store/wallet'
import { IAccountSentOffer, ICollection, ISortOption } from '../types'
import { useDialog } from './useDialog'

// TODO @고영욱 필터 관련 코드(현재 사용X)
export const useAccountSentOffer = () => {
  const { pathname } = useRouter()
  const appDispatch = useAppDispatch()
  const { t } = useTranslation(['common'])
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)
  const { handleSpecificDialog, setIsPreparingToOpenDialog } = useDialog()

  const handlePreparingToOpenDialog = useCallback(
    (isOpen: boolean) => setIsPreparingToOpenDialog(isOpen),
    [setIsPreparingToOpenDialog],
  )
  const handleOfferFormDialog = handleSpecificDialog(ManagedDialog.offerForm)
  const handleOfferCancelDialog = handleSpecificDialog(ManagedDialog.offerCancel)

  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const [state, dispatch] = useReducer(accountOfferFilterSlice.reducer, accountOfferFilterState)

  const { data: accountCollections } = useGetCurrentAccountSentOfferCollections(
    pathname,
    currentAccountAddress,
  )

  const sortOptions: ISortOption[] = [
    { text: t('common.label-list-offer-recent'), order: 'createdAt', orderBy: 'DESC' },
    { text: t('common.label-list-offer-oldest'), order: 'createdAt', orderBy: 'ASC' },
  ]

  const [isClickSort, setIsClickSort] = useState(false)

  const {
    setOwnCollections,
    setOrderAndOrderBy,
    setIsClickCollection,
    removeCollectionFilter,
    toggleSelectedCollection,
    initAccountSentOfferFilter,
  } = accountOfferFilterSlice.actions

  const memoizedQueryParams = useMemo(
    () => ({
      order: state.order,
      orderBy: state.orderBy,
      collectionAddresses: state.collections
        .map(collection => collection.address)
        .filter(Boolean)
        .join(','),
    }),
    [state],
  )

  const [debouncedQueryParams] = useDebouncedValue(memoizedQueryParams, 200)
  const [filterQueryParams, setFilterQueryParams] = useState(debouncedQueryParams)

  useEffect(() => {
    dispatch(setOwnCollections(accountCollections?.collections))
  }, [accountCollections?.collections, setOwnCollections])

  const handleOrderAndSortBy = useCallback(
    ({ order, orderBy }: { order: string; orderBy: string }) => {
      if (state.orderBy !== orderBy) {
        setIsClickSort(true)
      } else {
        setIsClickSort(false)
      }

      dispatch(setOrderAndOrderBy({ order, orderBy }))
    },
    [setOrderAndOrderBy, state.orderBy],
  )

  const handleIsClickCollection = useCallback(
    (value: boolean) => {
      dispatch(setIsClickCollection(value))
    },
    [setIsClickCollection],
  )

  const handleClear = useCallback(() => {
    dispatch(initAccountSentOfferFilter())
    dispatch(setOwnCollections(accountCollections?.collections))
    handleIsClickCollection(false)
    setFilterQueryParams({
      order: undefined,
      orderBy: 'DESC',
      collectionAddresses: '',
    })
  }, [
    accountCollections?.collections,
    handleIsClickCollection,
    initAccountSentOfferFilter,
    setOwnCollections,
  ])

  const handleCollectionClick = useCallback(
    (collection: ICollection) => dispatch(toggleSelectedCollection(collection)),
    [toggleSelectedCollection],
  )

  const handleRemoveCollectionFilter = useCallback(
    (collectionAddress: string) => {
      dispatch(removeCollectionFilter(collectionAddress))
    },
    [removeCollectionFilter],
  )

  const handleEditOfferItem = useCallback(
    (item: IAccountSentOffer) => {
      const selectedItemCollection = accountCollections?.collections.find(
        collection => collection.collectionAddress === item.collectionAddress,
      )
      appDispatch(
        offerEditDialogOpen({
          itemId: item.item.id,
          collectionAddress: item.collectionAddress,
          tokenId: item.item.tokenId,
          collectionTitle: selectedItemCollection?.title ?? '',
          itemTitle: item.item.title,
          mediaUrl: item.item.mediaUrl,
          chainId: item.item.chainId,
          handlePreparingToOpenDialog,
          handleOfferFormDialog,
        }),
      )
    },
    [
      accountCollections?.collections,
      appDispatch,
      handleOfferFormDialog,
      handlePreparingToOpenDialog,
    ],
  )

  const handleCancelOfferItem = useCallback(
    (item: IAccountSentOffer) => {
      const selectedItemCollection = accountCollections?.collections.find(
        collection => collection.collectionAddress === item.collectionAddress,
      )
      appDispatch(
        offerCancelDialogOpen({
          itemId: item.item.id,
          collectionAddress: item.collectionAddress,
          tokenId: item.item.tokenId,
          collectionTitle: selectedItemCollection?.title ?? '',
          itemTitle: item.item.title,
          mediaUrl: item.item.mediaUrl,
          chainId: item.item.chainId,
          handlePreparingToOpenDialog,
          handleOfferCancelDialog,
        }),
      )
    },
    [
      accountCollections?.collections,
      appDispatch,
      handleOfferCancelDialog,
      handlePreparingToOpenDialog,
    ],
  )

  return {
    state,
    isClickSort,
    filterQueryParams,
    setFilterQueryParams,
    debouncedQueryParams,
    collectionList: accountCollections?.collections,
    handleCollectionClick,
    handleIsClickCollection,
    handleClear,
    handleOrderAndSortBy,
    handleRemoveCollectionFilter,
    sortOptions,
    isFilterVisible,
    setIsFilterVisible,
    handleCancelOfferItem,
    handleEditOfferItem,
  }
}
