import { useInfiniteQuery } from '@tanstack/react-query'

import { IBaseResponse, IOriginalsPaymentResponse } from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountOriginalsPaymentsList = async (params: any) => {
  const url = '/api/v1/private/current-account/payment-receipts?orderBy=DESC&order=createdAt'
  const { data } = await withAxios<IBaseResponse<IOriginalsPaymentResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetCurrentAccountOriginalsPaymentListInfinityQuery = (pathname: string) => {
  return useInfiniteQuery(
    [pathname, 'getCurrentAccountOriginalsPaymentsList'],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        page: pageParam,
        perPage: 10,
      }

      const response = await getCurrentAccountOriginalsPaymentsList(queryParams)

      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
}
