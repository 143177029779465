import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { WAITING_ORDER_ACTION } from '../constants'
import { useWaitingRoom } from '../hooks'
import { useGetUseWaitingRoom } from '../services/strapi/getUseWaitingRoom'
import { IConnectionWebSocketResponse } from '../types'

interface IWaitingRoomContext {
  waitingRoomInfo: IConnectionWebSocketResponse | null
  handleRequestGetWaitingRoom: (action: keyof typeof WAITING_ORDER_ACTION) => Promise<void>
  setWaitingRoomInfo: (waitingRoomInfo: IConnectionWebSocketResponse | null) => void
  nextActionRef: React.MutableRefObject<(() => void) | null>
  startTimer: (totalCount: number, estimateSec: number) => void
  stopTimer: () => void
  count: number
  estimateSec: number | null
  connectionId: React.MutableRefObject<string | null>
  isWithinWaitingRoomUsingTime: boolean | null
  clearState: () => void
}

const WaitingRoomContext = createContext<IWaitingRoomContext>({
  waitingRoomInfo: null,
  handleRequestGetWaitingRoom: () => Promise.resolve(),
  setWaitingRoomInfo: () => undefined,
  nextActionRef: { current: null },
  startTimer: () => undefined,
  stopTimer: () => undefined,
  count: 0,
  estimateSec: 0,
  connectionId: { current: null },
  isWithinWaitingRoomUsingTime: null,
  clearState: () => undefined,
})

export const WaitingRoomProvider = ({ children }: { children: ReactNode }) => {
  const waitingRoom = useWaitingRoom()

  const router = useRouter()
  const {
    data: useWaitingRoomData,
    refetch: refetchUseWaitingRoom,
    isError: isUseWaitingRoomError,
  } = useGetUseWaitingRoom(router.pathname)
  const [isWithinWaitingRoomUsingTime, setIsWithinWaitingRoomUsingTime] = useState<boolean | null>(
    null,
  )

  useEffect(() => {
    if (useWaitingRoomData === undefined) return
    dayjs.extend(isBetween)
    const isWithinWaitingRoomUsingTime = dayjs().isBetween(
      dayjs(useWaitingRoomData.startAt),
      dayjs(useWaitingRoomData.endAt),
    )
    setIsWithinWaitingRoomUsingTime(isWithinWaitingRoomUsingTime)
  }, [useWaitingRoomData])

  useEffect(() => {
    if (useWaitingRoomData === undefined && isUseWaitingRoomError) {
      refetchUseWaitingRoom()
    }
  }, [isUseWaitingRoomError, refetchUseWaitingRoom, useWaitingRoomData])

  return (
    <WaitingRoomContext.Provider
      value={{ ...waitingRoom, isWithinWaitingRoomUsingTime, clearState: waitingRoom.clearState }}>
      {children}
    </WaitingRoomContext.Provider>
  )
}

export const useWaitingRoomContext = () => useContext(WaitingRoomContext)
