import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useId } from '@mantine/hooks'
import Image from 'next/image'
import {
  ChangeEvent,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import { cx } from '@/shared/utils'

export interface ITextInputProps extends HTMLAttributes<HTMLInputElement> {
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  leftIconPath?: string
  rightIconPath?: string
  rightElement?: ReactNode
  reset?: () => void
  labelText?: string
  helperText?: string
  isError?: boolean
  pattern?: string
}

export default forwardRef(function TextInput(
  {
    value,
    onChange,
    placeholder = '',
    leftIconPath,
    rightIconPath,
    rightElement,
    reset,
    labelText,
    helperText,
    className = '',
    isError = false,
    pattern = undefined,
    id,
    ...rest
  }: ITextInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const [isTyped, setIsTyped] = useState(false)
  const uuid = useId(id)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const borderColor = cx(
    isError ? 'border-red-500' : 'border-borderTertiary focus-within:border-borderPrimary',
  )
  const helperTextStyle = 'absolute top-[43px] left-0 text-xs leading-normal font-normal'

  const wrapperStyle = cx(
    className,
    'h-[41px] flex items-center rounded-[6px] py-[10px] px-[12px] shadow-sm border-[1px] border-solid gap-[8px] relative',
    borderColor,
  )

  useEffect(() => {
    const inputWrapperElement = wrapperRef.current?.querySelector(`#${uuid}`) as
      | HTMLInputElement
      | undefined
    inputWrapperElement?.value ? setIsTyped(true) : setIsTyped(false)
  }, [uuid, wrapperRef])

  useEffect(() => {
    if (value) setIsTyped(true)
    else setIsTyped(false)
  }, [isTyped, value])

  return (
    <div
      ref={wrapperRef}
      className={
        labelText
          ? cx(wrapperStyle, 'mt-[25px]')
          : helperText
          ? cx(wrapperStyle, 'mb-[24px]')
          : wrapperStyle
      }>
      {labelText && (
        <label
          className="absolute top-[-25px] left-0 text-sm leading-normal font-medium text-textSecondary"
          htmlFor={uuid}>
          {labelText}
        </label>
      )}
      {leftIconPath && <Image width={20} height={20} src={leftIconPath} alt="left_icon" />}
      <input
        ref={ref}
        id={uuid}
        className="text-white text-ellipsis overflow-hidden w-full bg-transparent text-sm leading-normal font-normal placeholder:text-appTextTertiary outline-none focus:border-white/[.40]"
        autoComplete="off"
        value={value}
        onChange={e => {
          if (onChange === undefined) return
          onChange(e)
          e.target.value ? setIsTyped(true) : setIsTyped(false)
        }}
        pattern={pattern}
        placeholder={placeholder}
        {...rest}
      />
      {rightElement}
      {rightIconPath && <Image width={20} height={20} src={rightIconPath} alt="right icon" />}
      {typeof reset === 'function' && value !== undefined && value !== '' && (
        <Image
          onClick={reset}
          width={20}
          height={20}
          src="/img/icon/solid-x-circle.svg"
          alt="reset_icon"
        />
      )}
      {isError && rightElement === undefined && rightIconPath === undefined && (
        <ExclamationCircleIcon className="w-5 h-5 text-[#EF4444]" />
      )}
      {helperText && (
        <div className={cx(isError ? 'text-textError' : 'text-textTertiary', helperTextStyle)}>
          {helperText}
        </div>
      )}
    </div>
  )
})
