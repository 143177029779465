import { useQuery } from '@tanstack/react-query'

import { IUserProfileResponse } from '@/shared/types'

import withAxios from '../api'

export const getUserProfile = async () => {
  try {
    const url = '/api/v3/private/kkr-auth/user/profile'
    const { data } = await withAxios<IUserProfileResponse>({
      url,
      method: 'GET',
    })

    return data
  } catch (e) {
    return {
      dateOfBirth: '',
      dialCode: '',
      name: '',
      phoneNumber: '',
    }
  }
}

export const useGetUserProfile = () => {
  return useQuery(['useGetUserProfile'], getUserProfile)
}
