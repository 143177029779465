import { IAccountItemFilterState } from '../store/filters'
import { isNumeric } from './isNumeric'

export const convertAccountItemFilterQueryParams = (state: IAccountItemFilterState) => {
  const { minPrice, maxPrice } = state

  return {
    isListing: state.isListing || isNumeric(minPrice) || isNumeric(maxPrice),
    hasOffer: state.hasOffer,
    paymentType: state.paymentType,
    order: state.order,
    orderBy: state.orderBy,
    minPrice: state.minPrice,
    maxPrice: state.maxPrice,
    collectionAddresses: state.collections
      .map(collection => collection.address)
      .filter(Boolean)
      .join(','),
  }
}
