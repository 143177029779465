import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '..'

export interface ISwapState {
  selectedChainId: string
  defaultInputTokenAddress?: string
}

const initialState: ISwapState = {
  selectedChainId: '1',
}

export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    setSelectedChainId: (state, action: PayloadAction<string>) => {
      state.selectedChainId = action.payload
    },
    setDefaultInputTokenAddress: (state, action: PayloadAction<string | undefined>) => {
      state.defaultInputTokenAddress = action.payload
    },
  },
})

export const { setSelectedChainId, setDefaultInputTokenAddress } = swapSlice.actions

export const selectSelectedChainId = (state: AppState) => state.swap.selectedChainId
export const selectDefaultInputTokenAddress = (state: AppState) =>
  state.swap.defaultInputTokenAddress

export default swapSlice
