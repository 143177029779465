import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountItemsDebouncedQueryParams,
  IAccountItemsResponse,
  IBaseResponse,
  IGetItemListRequest,
} from '@/shared/types'

import withAxios from '../api'

interface IUseGetCurrentAccountItemsInfinityQueryProps {
  pathname: string
  refresh: boolean
  debouncedQueryParams?: IAccountItemsDebouncedQueryParams
  currentAccountAddress: string | null
}

export const getCurrentAccountItems = async (params?: IGetItemListRequest) => {
  const url = '/api/v1/private/current-account/items'
  const { data } = await withAxios<IBaseResponse<IAccountItemsResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetCurrentAccountItemsInfinityQuery = ({
  pathname,
  refresh,
  debouncedQueryParams,
  currentAccountAddress,
}: IUseGetCurrentAccountItemsInfinityQueryProps) =>
  useInfiniteQuery<IAccountItemsResponse>(
    [pathname, 'getCurrentAccountItems', { debouncedQueryParams }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 10,
        refresh,
      } as IGetItemListRequest
      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }
      const response = await getCurrentAccountItems(queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      suspense: true,
      useErrorBoundary: true,
      enabled: currentAccountAddress !== null,
    },
  )
