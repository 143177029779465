import { useQuery } from '@tanstack/react-query'

import { IOriginalsItemResponse } from '@/shared/types'

import withAxios from '../api'

export const getOriginalsItem = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/v3/private/kkr-originals/originals/${originalsId}`
  const { data } = await withAxios<IOriginalsItemResponse>({ url, method: 'GET' })

  return data
}

export const useGetOriginalsItem = (pathname: string, originalsId?: string) =>
  useQuery(
    [pathname, 'useGetOriginalsItem', { originalsId }],
    () => getOriginalsItem({ originalsId }),
    {
      enabled: originalsId !== undefined,
      refetchOnWindowFocus: false,
    },
  )
