import { useCallback, useEffect, useRef, useState } from 'react'

function isNegative(num: number) {
  return num < 0
}

const initialState = { days: 0, hours: 0, minutes: 0, seconds: 0, on: false }

export const useCountDown = (date?: Date) => {
  const [countDownDate, setCountDownDate] = useState<Date | undefined>(date)
  const [nowFromServer, setNowFromServer] = useState<Date | undefined>()
  const [time, setTime] = useState(initialState)
  const timer = useRef<NodeJS.Timer>()

  const makeDateSet = useCallback((distance: number) => {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    setTime({ days, hours, minutes, seconds, on: false })
  }, [])

  const handleCountDownUsingDate = useCallback(
    (countDownDate: Date) => {
      timer.current = setInterval(() => {
        const dDay = countDownDate.getTime()

        const now = new Date().getTime()
        const distance = dDay - now

        if (isNegative(distance)) {
          setTime({ ...initialState, on: true })
          return () => clearInterval(timer.current)
        }

        makeDateSet(distance)
      }, 1000)
    },
    [makeDateSet],
  )

  const handleCountDownUsingNowFromServer = useCallback(
    (countDownDate: Date, nowFromServer: Date) => {
      const dDay = countDownDate.getTime()
      let distance = dDay - nowFromServer.getTime()
      timer.current = setInterval(() => {
        distance = distance - 1000
        if (isNegative(distance)) {
          setTime({ ...initialState, on: true })
          return () => clearInterval(timer.current)
        }

        makeDateSet(distance)
      }, 1000)
    },
    [makeDateSet],
  )

  useEffect(() => {
    if (countDownDate !== undefined) {
      if (nowFromServer === undefined) {
        handleCountDownUsingDate(countDownDate)
      } else {
        handleCountDownUsingNowFromServer(countDownDate, nowFromServer)
      }
    }

    return () => clearInterval(timer.current)
  }, [countDownDate, handleCountDownUsingDate, handleCountDownUsingNowFromServer, nowFromServer])

  useEffect(() => {
    time.on === true && clearInterval(timer.current)
  }, [time])

  return {
    time,
    countDownDate,
    setCountDownDate,
    setNowFromServer,
  }
}
