import { useQuery } from '@tanstack/react-query'

import { IBaseResponse, IPurchasedItem } from '@/shared/types'

import withAxios from '../api'

export const getPurchasedList = async ({
  originalsId,
}: {
  originalsId?: string
}): Promise<IPurchasedItem[]> => {
  const url = `/api/v3/private/kkr-originals/originals/${originalsId}/purchases`

  const { data } = await withAxios<IPurchasedItem[]>({
    url,
    method: 'GET',
  })

  return data
}

export const useGetPurchasedList = (originalsId?: string) =>
  useQuery(['useGetPurchasedList', { originalsId }], () => getPurchasedList({ originalsId }), {
    enabled: originalsId !== undefined,
    refetchOnWindowFocus: false,
  })
