import {
  AMOY_CHAIN_ID,
  MAINNET_CHAIN_ID,
  POLYGON_CHAIN_ID,
  SEPOLIA_CHAIN_ID,
} from '@modernlion/common-registry'
import { Networks } from '@modernlion/marketplace-registry'

export const DEV_WETH_ADDRESS = Networks[SEPOLIA_CHAIN_ID].tokenAddresses['WETH']
export const PROD_WETH_ADDRESS = Networks[MAINNET_CHAIN_ID].tokenAddresses['WETH']

export const DEV_WPOL_ADDRESS = Networks[AMOY_CHAIN_ID].tokenAddresses['WPOL']
export const PROD_WPOL_ADDRESS = Networks[POLYGON_CHAIN_ID].tokenAddresses['WPOL']
