import { IAccountOfferFilterState } from '../store/filters'

export const convertAccountSentOfferQueryParams = (state: IAccountOfferFilterState) => ({
  order: state.order,
  orderBy: state.orderBy,
  collectionAddresses: state.collections
    .map(collection => collection.address)
    .filter(Boolean)
    .join(','),
})
