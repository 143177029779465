import {
  CHAIN_ID,
  ETHER_LISTING_PAYMENT_TYPE,
  ETHER_OFFER_PAYMENT_TYPE,
  POLYGON_LISTING_PAYMENT_TYPE,
  POLYGON_OFFER_PAYMENT_TYPE,
} from '../constants'
import {
  EtherChainListingPaymentType,
  EtherChainOfferPaymentType,
  PolygonChainListingPaymentType,
  PolygonChainOfferPaymentType,
} from '../types'

export const getChainIdToPaymentType = (
  chainId: string,
  tradeType: 'LISTING' | 'OFFER',
):
  | EtherChainListingPaymentType[]
  | EtherChainOfferPaymentType[]
  | PolygonChainListingPaymentType[]
  | PolygonChainOfferPaymentType[]
  | null => {
  const ethereumMappings = {
    LISTING: Object.values(ETHER_LISTING_PAYMENT_TYPE),
    OFFER: Object.values(ETHER_OFFER_PAYMENT_TYPE),
  }
  const polygonMappings = {
    LISTING: Object.values(POLYGON_LISTING_PAYMENT_TYPE),
    OFFER: Object.values(POLYGON_OFFER_PAYMENT_TYPE),
  }

  const mappings = {
    [CHAIN_ID.ethereum]: ethereumMappings,
    [CHAIN_ID.sepolia]: ethereumMappings,
    [CHAIN_ID.polygon]: polygonMappings,
    [CHAIN_ID.amoy]: polygonMappings,
  }

  return mappings[chainId][tradeType]
}
