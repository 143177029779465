import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import {
  IAccountAdminableCollectionsResponse,
  IBaseResponse,
  IGetCurrentAccountAdminableCollectionsQueryParams,
} from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountAdminableCollections = async (
  params: IGetCurrentAccountAdminableCollectionsQueryParams,
) => {
  const url = '/api/v1/private/current-account/adminable-collections'
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IAccountAdminableCollectionsResponse>>({ url, params })

  return data
}

export const useGetCurrentAccountAdminableCollections = ({
  pathname,
  accountAddress,
  params,
  refresh,
  isUseSuspense,
  isUseErrorBoundary,
}: {
  pathname: string
  accountAddress: string | null
  params: IGetCurrentAccountAdminableCollectionsQueryParams
  refresh: boolean
  isUseSuspense: boolean
  isUseErrorBoundary: boolean
}) => {
  const queryParams = { ...params, refresh }
  return useQuery(
    [pathname, getCurrentAccountAdminableCollections, { queryParams, accountAddress }],
    () => getCurrentAccountAdminableCollections(queryParams),
    {
      suspense: isUseSuspense,
      useErrorBoundary: isUseErrorBoundary,
      keepPreviousData: true,
      enabled: accountAddress !== null,
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetCurrentAccountAdminableCollectionsInfinityQuery = (
  pathname: string,
  accountAddress: string | null,
  refresh: boolean,
  debouncedQueryParams?: IGetCurrentAccountAdminableCollectionsQueryParams,
) =>
  useInfiniteQuery<IAccountAdminableCollectionsResponse>(
    [pathname, 'getCurrentAccountAdminableCollections', { debouncedQueryParams, refresh }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 20,
        refresh,
      } as IGetCurrentAccountAdminableCollectionsQueryParams
      const data = await getCurrentAccountAdminableCollections(queryParams)
      return data
    },
    {
      getNextPageParam: data => (data.collections.length === 20 && data.page + 1) ?? undefined,
      enabled: accountAddress !== null,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
