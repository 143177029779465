import { PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import { cx } from '@/shared/utils'

interface IBottomSheetProps extends PropsWithChildren {
  className?: string
  closeSheet: () => void
}

const BottomSheet = ({ children, closeSheet, className }: IBottomSheetProps) => {
  const bottomSheetRootEl = document.querySelector('#bottom-sheet-root') as HTMLElement

  const el = useRef(document.createElement('div'))

  useEffect(() => {
    if (bottomSheetRootEl === null) return
    const current = el.current
    if (bottomSheetRootEl !== null) bottomSheetRootEl.appendChild(current)

    return () => {
      bottomSheetRootEl !== null && bottomSheetRootEl.removeChild(current)
    }
  }, [bottomSheetRootEl])

  return createPortal(
    <>
      <div
        className={cx(
          className,
          'fixed bottom-0 left-0 h-[75vh] w-[100vw] bg-bgSecondary z-50 rounded-t-[20px]',
        )}>
        {children}
      </div>
      <div
        className="z-40 top-0 left-0 fixed w-[100vw] h-[100vh] bg-[#000000CC]"
        onClick={closeSheet}
      />
    </>,
    el.current,
  )
}

export default BottomSheet
