import { useQuery } from '@tanstack/react-query'

import { IGetUseChannelTalkResponseType } from '@/shared/types'

import { withStrapiAxios } from '../api'

export const getUseChannelTalk = async () => {
  const url = '/api/use-channel-talk'
  const {
    data: { originals },
  } = await withStrapiAxios<IGetUseChannelTalkResponseType>({ url })
  return originals
}

export const useGetUseChannelTalk = (pathname: string) =>
  useQuery([pathname, 'getUseChannelTalk'], () => getUseChannelTalk(), {
    refetchOnWindowFocus: false,
  })
