import { InfiniteData } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

import { useGetItemUtilities } from '@/shared/services/market'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import { selectBenefits, selectBenefitsRequestIds } from '@/shared/store/market'
import { getHasBenefitList, getHasBenefitOnSaleIdList } from '@/shared/utils'

import { mergeData } from '../store/market'
import { IMarketOnSaleItem } from '../types/items'

export const useBenefits = ({ data }: { data: InfiniteData<IMarketOnSaleItem> | undefined }) => {
  const dispatch = useAppDispatch()
  const storedBenefits = useAppSelector(selectBenefits)
  const storedBenefitRequestIds = useAppSelector(selectBenefitsRequestIds)

  const requestUtilitiesIds = useMemo(() => {
    const { itemIds: itemIdListByOnSale } = getHasBenefitOnSaleIdList(data?.pages)

    const storedBenefitsIds =
      storedBenefits.length > 0 ? storedBenefits.flatMap(item => item.itemId) : []

    const removeTargetIds = [...storedBenefitsIds, ...storedBenefitRequestIds]

    return itemIdListByOnSale.filter(item => !removeTargetIds.includes(item))
  }, [data?.pages, storedBenefits, storedBenefitRequestIds])

  const {
    status,
    data: utilitiesData,
    isInitialLoading: isLoadingByFetchUtilities,
  } = useGetItemUtilities({
    itemIds: requestUtilitiesIds,
    hasBenefits: true,
  })

  const resultBenefitList = getHasBenefitList(utilitiesData)

  useEffect(() => {
    if (status === 'success') {
      dispatch(mergeData({ benefits: resultBenefitList, benefitsRequestIds: requestUtilitiesIds }))
    }
  }, [dispatch, requestUtilitiesIds, resultBenefitList, status])

  return {
    storedBenefits,
    isLoadingByFetchUtilities,
    getItemUtilitiesFetchStatus: status,
  }
}
