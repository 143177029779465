import { useMutation } from '@tanstack/react-query'

import {
  IBaseResponse,
  IPatchCollectionEditRequest,
  IPatchCollectionEditResponse,
} from '@/shared/types'

import withAxios from '../api'

export async function patchCollectionEdit({
  collectionAddress,
  body,
}: IPatchCollectionEditRequest) {
  const url = `/api/v1/private/collections/${collectionAddress}`
  const { data } = await withAxios<IBaseResponse<IPatchCollectionEditResponse>>({
    url,
    method: 'PATCH',
    data: body,
  })
  return data
}

export function useCollectionEditMutation() {
  return useMutation(['collection-edit'], (params: IPatchCollectionEditRequest) =>
    patchCollectionEdit(params),
  )
}
