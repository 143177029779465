import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getTradeAddress } from '@/shared/services/itemOrder'
import { ITradeAddress } from '@/shared/types'

import { AppState } from '..'

export interface IContractAddressState {
  tradeAddress: ITradeAddress | null
}

const initialState: IContractAddressState = {
  tradeAddress: null,
}

export const getContractTradeAddress = createAsyncThunk(
  'contractAddress/getTradeAddress',
  async () => {
    try {
      const tradeAddress = await getTradeAddress()
      return tradeAddress
    } catch (e: any) {
      return Promise.reject(e)
    }
  },
)

export const contractAddressSlice = createSlice({
  name: 'contractAddress',
  initialState,
  reducers: {
    resetContractAddress: state => {
      state.tradeAddress = null
    },
    setTradeAddress: (state, action: PayloadAction<ITradeAddress | null>) => {
      state.tradeAddress = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getContractTradeAddress.fulfilled, (state, action) => {
      state.tradeAddress = action.payload
    })
    builder.addCase(getContractTradeAddress.rejected, state => {
      state.tradeAddress = null
    })
  },
})

export const { resetContractAddress, setTradeAddress } = contractAddressSlice.actions

export const selectTradeAddress = (state: AppState) => state.contractAddress.tradeAddress

export default contractAddressSlice
