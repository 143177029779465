import { cx } from '@/shared/utils'

import Spinner from './Spinner'

interface IButtonProps {
  text?: string
  size?: 'xs' | 'sm' | 'base' | 'lg'
  theme?: 'Filled' | 'Outlined' | 'Accent' | 'White'
  color?: string
  type?: 'button' | 'submit' | 'reset'
  className?: string
  onClick?: () => void
  disable?: boolean
  spinner?: boolean
}

function buttonHeight(size: string) {
  if (size === 'xs') return ` h-[35px] text-${size} `
  else if (size === 'sm') return ` h-[41px] text-${size} `
  else if (size === 'base') return ` h-[44px] text-${size} `
  else if (size === 'lg') return ' h-[52px] text-base '
}

function buttonThemeCSS(theme: string) {
  if (theme === 'Filled') return ' bg-bgQuarternary hover:bg-bgButtonHover '
  else if (theme === 'Outlined') return ' border border-twPaletteGray400 hover:bg-bgOutlineHover '
  else if (theme === 'Accent') return ' bg-bgAccent '
  else if (theme === 'White') return ' bg-white '
}

function buttonDisable(disable: boolean) {
  if (disable) return 'opacity-30 cursor-not-allowed'
}

function buttonTextColor(theme: string, color?: string) {
  if (color !== undefined) {
    return color
  }

  if (theme === 'Accent' || theme === 'White') {
    return 'text-textBlack'
  }

  return 'text-textPrimary'
}

export default function Button({
  onClick,
  text = '',
  size = 'base',
  theme = 'Filled',
  className = '',
  color,
  disable = false,
  type = 'button',
  spinner = false,
}: IButtonProps) {
  const defaultButtonClass = 'w-full rounded font-medium cursor-pointer leading-normal shadow-sm '

  const buttonContents = () => {
    if (spinner) {
      return (
        <div className="w-full flex justify-center items-center">
          <Spinner size="xs" />
        </div>
      )
    } else {
      return <>{text}</>
    }
  }

  return (
    <button
      className={cx(
        className,
        buttonTextColor(theme, color),
        buttonHeight(size),
        defaultButtonClass,
        buttonThemeCSS(theme),
        buttonDisable(disable),
      )}
      type={type}
      disabled={disable}
      onClick={onClick}>
      {buttonContents()}
    </button>
  )
}
