import { useMutation } from '@tanstack/react-query'

import { IPostOriginalsRaffle } from '@/shared/types'

import withAxios from '../api'

export async function postOriginalsRaffle({
  agreementTypes,
  selectedOptions,
  originalsId,
}: {
  agreementTypes: string[]
  selectedOptions: string[]
  originalsId: string
}) {
  const url = `/api/v3/private/kkr-originals/originals/${originalsId}/raffle`
  const { data } = await withAxios<IPostOriginalsRaffle>({
    url,
    method: 'POST',
    data: {
      agreementTypes,
      selectedOptions,
    },
  })

  return data
}

export function usePostOriginalsRaffle() {
  return useMutation(
    ['postOriginalsRaffle'],
    ({
      agreementTypes,
      selectedOptions,
      originalsId,
    }: {
      agreementTypes: string[]
      selectedOptions: string[]
      originalsId: string
    }) => postOriginalsRaffle({ agreementTypes, selectedOptions, originalsId }),
  )
}
