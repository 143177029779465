export enum ManagedDialog {
  // payment
  canNotUsePayment = 'canNotUsePayment',
  checkoutImpossible = 'checkoutImpossible',
  checkoutNotWhitelist = 'checkoutNotWhitelist',
  soldout = 'soldout',
  waitingForPayments = 'waitingForPayments',
  waitingOrder = 'waitingOrder',
  terms = 'terms',

  // trade
  acceptFail = 'acceptFail',
  acceptSummary = 'acceptSummary',
  lackOfBalanceForPurchase = 'lackOfBalanceForPurchase',
  listingCancel = 'listingCancel',
  listingComplete = 'listingComplete',
  listingEditOrCancel = 'listingEditOrCancel',
  listingFail = 'listingFail',
  listingForm = 'listingForm',
  listingSummary = 'listingSummary',
  offerCancel = 'offerCancel',
  offerComplete = 'offerComplete',
  offerEditOrCancel = 'offerEditOrCancel',
  offerFail = 'offerFail',
  offerForm = 'offerForm',
  offerSummary = 'offerSummary',
  purchaseFail = 'purchaseFail',
  purchaseSummary = 'purchaseSummary',
  txProgressForAccept = 'txProgressForAccept',
  txProgressForCancel = 'txProgressForCancel',
  txProgressForPurchase = 'txProgressForPurchase',
  walletWaitingForAccept = 'walletWaitingForAccept',
  walletWaitingForListingCancel = 'walletWaitingForListingCancel',
  walletWaitingForListing = 'walletWaitingForListing',
  walletWaitingForOfferCancel = 'walletWaitingForOfferCancel',
  walletWaitingForOffer = 'walletWaitingForOffer',
  walletWaitingForPurchase = 'walletWaitingForPurchase',
  waitingForReceivedOfferedItem = 'waitingForReceivedOfferedItem',
  unSupportTrade = 'unSupportTrade',
  exceedTransfer = 'exceedTransfer',

  // common
  collectionReport = 'collectionReport',
  connectWallet = 'connectWallet',
  disconnectedWallet = 'disconnectedWallet',
  rankingGuide = 'rankingGuide',
  profileEdit = 'profileEdit',
  qr = 'qr',
  uniswap = 'uniswap',
  swapChainSelect = 'swapChainSelect',
  tradeSupportItem = 'tradeSupportItem',
  transferFail = 'transferFail',
  transferForm = 'transferForm',
  txProgressForTransfer = 'txProgressForTransfer',
  walletWaitingForTransfer = 'walletWaitingForTransfer',
  goAppHome = 'goAppHome',
  apply = 'apply',
  checkRaffleResult = 'checkRaffleResult',
  noticeCopyAddress = 'noticeCopyAddress',
  purchaseCancel = 'purchaseCancel',
  changeRegionSettings = 'changeRegionSettings',
}
