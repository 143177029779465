import { useMutation } from '@tanstack/react-query'

import { IListing } from '@/shared/types'

import withAxios from '../api'

interface ICreateListingRequest {
  collectionAddress: string
  signHash: string
  orderHash: string
  paymentType: string
  price: string
  salt: string
  maximumFill: number
  makingTime: string
  expiredAt: string
  payAmount: string
  counter: number
  zoneHash: string
}

export const createListing = (itemId: string, data: ICreateListingRequest) => {
  return withAxios<IListing>({
    url: `/api/v1/private/items/${itemId?.toLowerCase()}/listing`,
    method: 'POST',
    data,
  })
}

export const useCreateListing = () => {
  return useMutation(
    ({ itemId, data }: { itemId: string; data: ICreateListingRequest }) =>
      createListing(itemId, data),
    {
      retry: false,
    },
  )
}
