export const ROUTE_PATH = {
  HOME: '/',

  ON_SALE: '/on-sale',

  CURRENT_ACCOUNT: '/current-account',
  CURRENT_ACCOUNT_HIDDEN: '/current-account/hidden',
  CURRENT_ACCOUNT_LIKED: '/current-account/liked',
  CURRENT_ACCOUNT_ACTIVITY: '/current-account/activities',
  CURRENT_ACCOUNT_SENT_OFFER: '/current-account/sent-offers',
  CURRENT_ACCOUNT_RECEIVED_OFFER: '/current-account/received-offers',
  CURRENT_ACCOUNT_COLLECTION: '/current-account/collections',

  PAYMENT_HISTORY: '/payment-history',
  PAYMENT_HISTORY_DETAIL: '/payment-history/detail/:ordNo',

  REFUND_CONFIRM: '/refund/confirm/:ordNo',
  REFUND_RESULT: '/refund/result/:status',

  ACCOUNT: '/account/:accountAddress',
  ACCOUNT_LIKED: '/account/:accountAddress/liked',
  ACCOUNT_ACTIVITY: '/account/:accountAddress/activities',

  COLLECTIONS: '/collections',

  COLLECTION_DETAIL: '/collection/:collectionAddress',
  COLLECTION_ACTIVITY: '/collection/:collectionAddress/activities',
  COLLECTION_EDIT: '/collection/:collectionAddress/edit',
  COLLECTION_PAYOUT: '/collection/:collectionAddress/payout',

  SEARCH_DETAIL: '/search',

  ITEM_DETAIL: '/item/:collectionAddress/:tokenId',
  ITEM_DETAIL_DETAIL: '/item/:collectionAddress/:tokenId',
  ITEM_DETAIL_ACTIVITY: '/item/:collectionAddress/:tokenId/activity',
  ITEM_DETAIL_OFFER: '/item/:collectionAddress/:tokenId/offer',

  ORIGINALS: '/originals',
  ORIGINALS_BRAND_PAGE: '/originals/:originalsId',
  ORIGINALS_CHECKOUT: '/originals/:originalsId/checkout',
  ORIGINALS_SEATS_CHECKOUT: '/originals/:originalsId/checkout/seats',

  ORIGINALS_CHECKOUT_SUCCESS: '/originals/:originalsId/checkout/succeeded/:checkoutId',
  ORIGINALS_CHECKOUT_FAIL: '/originals/:originalsId/checkout/failed/:errorCode',
  ORIGINALS_CHECKOUT_CALLBACK: '/originals/:originalsId/checkout/:result/:errorCodeOrOrdNo',
  ORIGINALS_RANKINGS: '/rankings/:originalsId',
  ORIGINALS_BOOK_EVENT: '/originals/:originalsId/book-event',
  ORIGINALS_SEAT_RESERVATION: '/originals/:originalsId/:eventKey',
}
