import { useRouter } from 'next/router'
import { useCallback, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_COLLECTION_FILTER } from '@/shared/constants'

import { useGetCollectionProperties } from '../services/collections'
import { collectionItemsFilterSlice, collectionItemsFilterState } from '../store/filters'
import {
  FilterChip,
  IChipMaxPriceType,
  IChipMinPriceType,
  IPropertyValue,
  ISortOption,
  ListingPaymentType,
} from '../types'
import { useMemoCollectionFilterQueryParams } from './useMemoCollectionFilterQueryParams'
import { usePriceRangeFilter } from './usePriceRangeFilter'

export const useCollectionFilter = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const { pathname, query } = useRouter()
  const { collectionAddress } = query as { collectionAddress?: string }

  const { data: collectionProperty } = useGetCollectionProperties(pathname, collectionAddress)
  const { t } = useTranslation(['common', 'web-collection'])

  const [state, dispatch] = useReducer(
    collectionItemsFilterSlice.reducer,
    collectionItemsFilterState,
  )

  const {
    initCollectionItemsFilter,
    setOrderAndOrderBy,
    initPrice,
    toggleIsListing,
    setMaxPrice,
    setMinPrice,
    setPaymentType,
    setPaymentTypeList,
    setFilterPropertyIds,
    removeChip,
    setIsClickChip,
    removePropertyFilter,
  } = collectionItemsFilterSlice.actions

  const debouncedQueryParams = useMemoCollectionFilterQueryParams(state)
  const [filterQueryParams, setFilterQueryParams] = useState(debouncedQueryParams)

  const sortOptions: ISortOption[] = [
    {
      text: `${t('common:common.label-listing-lowest-price')}`,
      order: 'listingPrice',
      orderBy: 'ASC',
    },
  ]

  const handleOrderAndSortBy = useCallback(
    ({ order, orderBy }: { order: string; orderBy: string }) => {
      dispatch(setOrderAndOrderBy({ order, orderBy }))
    },
    [setOrderAndOrderBy],
  )

  const handleToggleIsListing = useCallback(() => dispatch(toggleIsListing()), [toggleIsListing])
  const handleMinPrice = useCallback((price: string) => dispatch(setMinPrice(price)), [setMinPrice])
  const handleMaxPrice = useCallback((price: string) => dispatch(setMaxPrice(price)), [setMaxPrice])
  const handlePaymentType = useCallback(
    (paymentType: ListingPaymentType) => dispatch(setPaymentType({ paymentType })),
    [setPaymentType],
  )
  const handlePaymentTypeList = useCallback(
    (paymentTypeList: ListingPaymentType[]) => {
      dispatch(setPaymentTypeList(paymentTypeList))
    },
    [setPaymentTypeList],
  )

  const handleFilterProperty = useCallback(
    (value: IPropertyValue, i: number, j: number) => {
      if (collectionProperty === undefined) return

      const chipName = `${collectionProperty[i].title} ${collectionProperty[i].values[j].value}`

      const exist = state.filterPropertyIds.some(element => element.id === value.id)
      if (exist) {
        const index = state.filterPropertyIds.findIndex(item => item.id === value.id)
        const newPropertyIds = [
          ...state.filterPropertyIds.slice(0, index),
          ...state.filterPropertyIds.slice(index + 1),
        ]
        dispatch(setFilterPropertyIds(newPropertyIds))
      } else {
        dispatch(setFilterPropertyIds([...state.filterPropertyIds, { id: value.id, chipName }]))
      }
    },
    [collectionProperty, setFilterPropertyIds, state.filterPropertyIds],
  )

  const {
    maxPriceValue,
    minPriceValue,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    isError,
    handleClearInputValue,
    handleResetErrorFromPriceRange,
  } = usePriceRangeFilter({
    maxPrice: state.maxPrice,
    minPrice: state.minPrice,
    handleMaxPrice,
    handleMinPrice,
  })

  const handleClickChip = useCallback(
    (isClickChip: boolean) => {
      dispatch(setIsClickChip(isClickChip))
    },
    [setIsClickChip],
  )

  const handleRemoveChip = useCallback(
    (chip: FilterChip) => {
      dispatch(removeChip(chip))
      if (Object.keys(chip)[0] === 'minPriceChip' || Object.keys(chip)[0] === 'maxPriceChip') {
        const priceChip = Object.keys(chip)[0] as keyof FilterChip
        const chipType = chip[priceChip] as IChipMinPriceType | IChipMaxPriceType
        handleClearInputValue(chipType.type as 'minPrice' | 'maxPrice')
      }
    },
    [handleClearInputValue, removeChip],
  )

  const handleRemovePropertyFilter = useCallback(
    (propertyId: number) => {
      dispatch(removePropertyFilter(propertyId))
    },
    [removePropertyFilter],
  )

  const handleInitPrice = useCallback(() => {
    dispatch(initPrice())
    handleClearInputValue()
    handleResetErrorFromPriceRange()
  }, [handleResetErrorFromPriceRange, handleClearInputValue, initPrice])

  const handleFilterClear = useCallback(
    ({ isFetch }: { isFetch: boolean }) => {
      dispatch(initCollectionItemsFilter())
      handleInitPrice()
      handleClickChip(false)
      handleResetErrorFromPriceRange()

      if (isFetch) {
        setFilterQueryParams(DEFAULT_COLLECTION_FILTER)
      }
    },
    [initCollectionItemsFilter, handleInitPrice, handleClickChip, handleResetErrorFromPriceRange],
  )

  return {
    debouncedQueryParams,
    handleToggleIsListing,
    maxPriceValue,
    minPriceValue,
    isInputPriceError: isError,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    handlePaymentType,
    handlePaymentTypeList,
    collectionProperty,
    handleFilterProperty,
    state,
    handleRemoveChip,
    handleRemovePropertyFilter,
    handleFilterClear,
    isFilterVisible,
    setIsFilterVisible,
    sortOptions,
    handleOrderAndSortBy,
    handleInitPrice,
    filterQueryParams,
    setFilterQueryParams,
    handleResetErrorFromPriceRange,
    handleClickChip,
  }
}
