import { InfiniteData } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'

import { getHighestOffers } from '../services/items'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { mergeData, selectHightestOffers } from '../store/trades'
import { IItem } from '../types'

export const useHighestOffers = <T extends { items: IItem[] }>(
  data: InfiniteData<T> | undefined,
  isRefresh = false,
) => {
  const dispatch = useAppDispatch()
  const highestOffers = useAppSelector(selectHightestOffers)

  const fetchHighestOffers = useCallback(
    async (cachedItemIds: Set<string>, pages: T[]) => {
      pages.map(async items => {
        const itemIds = items.items.map(item => item.id)
        const itemIdsToFetch = itemIds
          .map(itemId => {
            return cachedItemIds.has(itemId) ? false : itemId
          })
          .filter(Boolean) as string[]

        if (itemIdsToFetch.length === 0) return

        const highestOffers = await getHighestOffers(itemIdsToFetch)
        dispatch(mergeData(highestOffers))
      })
    },
    [dispatch],
  )

  useEffect(() => {
    if (data === undefined) return
    const cachedItemIds = new Set(!isRefresh ? Object.keys(highestOffers) : [])
    fetchHighestOffers(cachedItemIds, data.pages)
  }, [data, fetchHighestOffers, highestOffers, isRefresh])

  return { highestOffers }
}
