import { IBaseResponse, IOriginalsVerifyResponse } from '@/shared/types'

import withAxios from '../api'

export const getOriginalsCheckoutVerify = async (originalsId?: string) => {
  const url = `/api/v1/private/originals/${originalsId}/verify`

  const {
    data: { data: isVerified },
  } = await withAxios<IBaseResponse<IOriginalsVerifyResponse>>({ url, method: 'GET' })
  return isVerified
}
