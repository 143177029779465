import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import { useCollectionImageUploadQuery } from '@/shared/services/collections/postCollectionImageUpload'

interface IUseCollectionDropZone {
  collectionAddress: string
  onSuccess: (url: string) => void
  path: 'background' | 'main'
}

export default function useCollectionDropZone({
  collectionAddress,
  onSuccess,
  path,
}: IUseCollectionDropZone) {
  const { mutate } = useCollectionImageUploadQuery()

  return useDropzone({
    onDrop: acceptedFiles => {
      const files: File[] = acceptedFiles
      const formData = new FormData()
      formData.append('image', files[0])
      formData.append('path', path)
      mutate(
        {
          collectionAddress,
          body: formData,
        },
        {
          onSuccess: ({ data }) => {
            onSuccess(data)
          },
          onError: () => {
            toast('업로드를 실패했어요. 다시 시도해주세요.')
          },
        },
      )
    },
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'], 'image/jpg': ['.jpg'] },
  })
}
