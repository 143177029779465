import { useQuery } from '@tanstack/react-query'

import { IListingItem } from '@/shared/types'

import withAxios from '../api'

export const getListingItem = async (itemId: string, listingId: string) => {
  const { data } = await withAxios<IListingItem>({
    url: `/api/v1/private/items/${itemId.toLowerCase()}/listing/${listingId}`,
    method: 'GET',
  })
  return data
}

export const useGetListingItem = (pathname: string, itemId: string, listingId: string) => {
  return useQuery(
    [pathname, 'getListingItem', { itemId, listingId }],
    async () => await getListingItem(itemId, listingId),
  )
}
