import { useQuery } from '@tanstack/react-query'

import { IHighestPriceOfferForItemResponse } from '@/shared/types'

import withAxios from '../api'

export const getPublicHighestPrice = async (itemId?: string) => {
  const {
    data: { offer },
  } = await withAxios<IHighestPriceOfferForItemResponse>({
    url: `/api/v1/public/items/${itemId?.toLowerCase()}/offer/highest-price`,
    method: 'GET',
  })

  return offer
}

export const useGetPublicHighestPrice = (pathname: string, itemId?: string) => {
  return useQuery(
    [pathname, 'getPublicHighestPrice', { itemId }],
    async () => await getPublicHighestPrice(itemId),
    { enabled: !!itemId },
  )
}
