import { ICancelListingResponse } from '@/shared/types'

import withAxios from '../api'

export const cancelListing = async (itemId: string, txHash: string) => {
  const {
    data: { isCanceled },
  } = await withAxios<ICancelListingResponse>({
    url: `/api/v1/private/items/${itemId.toLowerCase()}/listings/auto-canceled/${txHash}`,
    method: 'PATCH',
  })

  return isCanceled
}
