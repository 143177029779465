import { InfiniteData } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

import { useGetCheckOfferItem } from '../services/itemOrder/getCheckOfferItem'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  mergeOfferedItemIds,
  mergeRequestedItemIds,
  resetOfferedItemIds,
  resetRequestedOfferedIds,
  selectOfferedItemIds,
  selectRequestedItemIds,
} from '../store/offer'
import { IAccountItemsResponse } from '../types'

export const useGetCheckOfferedItemId = ({
  data,
  pathname,
}: {
  pathname: string
  data?: InfiniteData<IAccountItemsResponse>
}) => {
  const dispatch = useAppDispatch()
  const storedOfferedItemIds = useAppSelector(selectOfferedItemIds)
  const storedRequestedItemIds = useAppSelector(selectRequestedItemIds)

  const requestItemIds = useMemo(() => {
    const accountItemIds = data?.pages.flatMap(page => page.items.map(item => item.id))
    if (storedRequestedItemIds.length === 0) return accountItemIds ?? []

    const filteredAccountItemIds = accountItemIds?.filter(
      id => !storedRequestedItemIds.includes(id),
    )

    return filteredAccountItemIds ?? []
  }, [data?.pages, storedRequestedItemIds])

  const {
    status,
    isRefetching,
    data: offeredItemIds,
    refetch: refetchGetCheckOfferItem,
  } = useGetCheckOfferItem({
    pathname,
    itemIds: requestItemIds,
  })

  useEffect(() => {
    if (isRefetching) {
      dispatch(resetOfferedItemIds())
      dispatch(resetRequestedOfferedIds())
      return
    }

    if (status === 'success') {
      dispatch(
        mergeRequestedItemIds({
          requestedItemIds: requestItemIds || [],
        }),
      )

      dispatch(
        mergeOfferedItemIds({
          offeredItemIds: offeredItemIds || [],
        }),
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dispatch, offeredItemIds, isRefetching])

  return { refetchGetCheckOfferItem, offeredItemIds: storedOfferedItemIds }
}
