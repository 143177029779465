import { forwardRef, HTMLAttributes } from 'react'

import { cx } from '@/shared/utils'

export interface ITextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  isError?: boolean
  placeholder?: string
}

export default forwardRef(function Textarea(
  { value, onChange, isError = false, placeholder = '', ...rest }: ITextareaProps,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  const wrapper = cx(
    'h-[120px] py-[10px] px-[12px] border-[1px] border-solid drop-shadow-sm rounded-[6px]',
  )

  const borderColor = cx(
    isError ? 'border-red-500' : 'border-borderTertiary focus-within:border-borderPrimary',
  )

  return (
    <div className={cx(wrapper, borderColor)}>
      <textarea
        ref={ref}
        className={cx(
          isError ? 'text-red-500' : 'text-white',
          'w-full h-full bg-transparent border-transparent focus:border-transparent focus:ring-0 outline-none border-none resize-none placeholder:text-textTertiary text-sm placeholder:text-sm leading-normal placeholder:leading-normal font-normal placeholder:font-normal',
        )}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  )
})
