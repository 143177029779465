import { getPayAmountsByPrice } from '@modernlion/marketplace-registry'
import { OrderWithCounter } from '@opensea/seaport-js/lib/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { getCollectionStats } from '@/shared/services/collections'
import {
  createListing,
  getListingList,
  getLowestPrice,
  getRoyaltyInfo,
  getTradeAddress,
} from '@/shared/services/itemOrder'
import {
  ICreateListingFormData,
  IListingItemInfo,
  instanceOfCollectionStats,
  instanceOfListing,
  instanceOfListingItemList,
  instanceOfRoyaltyInfo,
  instanceofTradeAddress,
  IRoyaltyInfo,
} from '@/shared/types'

import { AppState } from '..'

export interface IListingState {
  action: 'create' | 'edit' | 'cancel' | null
  listingItemInfo: IListingItemInfo | null
  listingFormData: ICreateListingFormData | null

  isFetchingListingItemInfo: boolean
  isFetchingListingItemInfoError: string | null

  order: OrderWithCounter | null
  orderHash: string | null
  counter: number | null

  isListingCreated: boolean
  isListingCreating: boolean
  isCreateListingError: string | null
}

const initialState: IListingState = {
  action: null,
  listingItemInfo: null,
  listingFormData: null,
  isFetchingListingItemInfo: false,
  isFetchingListingItemInfoError: null,
  order: null,
  orderHash: null,
  counter: null,
  isListingCreated: false,
  isListingCreating: false,
  isCreateListingError: null,
}

export const getListingItemInfo = createAsyncThunk(
  'listing/getListingItemInfo',
  async ({
    itemId,
    collectionAddress,
    tokenId,
    collectionTitle,
    itemTitle,
    mediaUrl,
    ownerAddress,
    chainId,
  }: {
    itemId: string
    collectionAddress: string
    tokenId: number | string
    collectionTitle: string
    itemTitle: string
    mediaUrl: string
    ownerAddress: string
    chainId: string
  }) => {
    const promises = [
      getTradeAddress(),
      getRoyaltyInfo(itemId),
      getCollectionStats(collectionAddress),
      getLowestPrice(itemId),
      getListingList(itemId),
    ]
    try {
      const [
        tradeAddress,
        royaltyInfo,
        collectionStats,
        listingItemToEdit,
        listingItemListToCancel,
      ] = await Promise.all(promises)

      return {
        itemId,
        collectionAddress,
        tokenId,
        collectionTitle,
        itemTitle,
        mediaUrl,
        ownerAddress,
        chainId,
        royaltyInfo: instanceOfRoyaltyInfo(royaltyInfo) ? royaltyInfo : null,
        collectionStats: instanceOfCollectionStats(collectionStats) ? collectionStats : null,
        listingItemToEdit: instanceOfListing(listingItemToEdit) ? listingItemToEdit : null,
        listingItemListToCancel: instanceOfListingItemList(listingItemListToCancel)
          ? listingItemListToCancel
          : null,
        tradeAddress: instanceofTradeAddress(tradeAddress) ? tradeAddress : null,
      }
    } catch (e: any) {
      return Promise.reject(e)
    }
  },
)

export const registerToSellForItem = createAsyncThunk(
  'listing/createListing',
  async ({
    itemId,
    collectionAddress,
    listingFormData,
    order,
    orderHash,
    counter,
    royaltyInfo,
    signature,
  }: {
    itemId: string
    collectionAddress: string
    listingFormData: ICreateListingFormData
    order: OrderWithCounter
    orderHash: string
    counter: number
    royaltyInfo: IRoyaltyInfo
    signature: string
  }) => {
    const { price, paymentType } = listingFormData
    const { salt, startTime, endTime, zoneHash } = order.parameters
    const { marketFee, royalties } = royaltyInfo
    const { priceExcludingRoyalties } = getPayAmountsByPrice(price, marketFee, royalties)

    try {
      await createListing(itemId, {
        collectionAddress,
        signHash: signature,
        orderHash,
        paymentType,
        price,
        payAmount: priceExcludingRoyalties,
        salt: salt,
        maximumFill: 1,
        makingTime: dayjs.unix(startTime as number).format(),
        expiredAt: dayjs.unix(endTime as number).format(),
        counter,
        zoneHash,
      })
      return true
    } catch (error: any) {
      return Promise.reject(error)
    }
  },
)

export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    resetListingState: state => {
      state.action = null
      state.listingItemInfo = null
      state.listingFormData = null
      state.isFetchingListingItemInfo = false
      state.isFetchingListingItemInfoError = null
      state.order = null
      state.orderHash = null
      state.counter = null
      state.isListingCreated = false
      state.isListingCreating = false
      state.isCreateListingError = null
    },
    setListingAction: (state, action: PayloadAction<'create' | 'edit' | 'cancel' | null>) => {
      state.action = action.payload
    },
    setListingItemInfo: (state, action: PayloadAction<IListingItemInfo | null>) => {
      state.listingItemInfo = action.payload
    },
    setListingFormData: (state, action: PayloadAction<ICreateListingFormData | null>) => {
      state.listingFormData = action.payload
    },
    setOrderForListing: (state, action: PayloadAction<OrderWithCounter | null>) => {
      state.order = action.payload
    },
    setOrderHashForListing: (state, action: PayloadAction<string | null>) => {
      state.orderHash = action.payload
    },
    setCounterForListing: (state, action: PayloadAction<number | null>) => {
      state.counter = action.payload
    },
    setIsListingCreated: (state, action: PayloadAction<boolean>) => {
      state.isListingCreated = action.payload
    },
    setIsListingCreating: (state, action: PayloadAction<boolean>) => {
      state.isListingCreating = action.payload
    },
    setIsCreateListingError: (state, action: PayloadAction<string | null>) => {
      state.isCreateListingError = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getListingItemInfo.pending, (state, action) => {
      state.listingItemInfo = null
      state.listingFormData = null
      state.isFetchingListingItemInfo = true
      state.isFetchingListingItemInfoError = null
    })
    builder.addCase(getListingItemInfo.fulfilled, (state, action) => {
      state.listingItemInfo = action.payload
      state.isFetchingListingItemInfo = false
    })
    builder.addCase(getListingItemInfo.rejected, (state, action) => {
      state.isFetchingListingItemInfo = false
      if (action.error.message) {
        state.isFetchingListingItemInfoError = action.error.message
      }
    })
    builder.addCase(registerToSellForItem.pending, (state, action) => {
      state.isListingCreating = true
    })
    builder.addCase(registerToSellForItem.fulfilled, (state, action) => {
      state.isListingCreated = true
      state.isListingCreating = false
    })
    builder.addCase(registerToSellForItem.rejected, (state, action) => {
      state.isListingCreating = false
      if (action.error.message) {
        state.isCreateListingError = action.error.message
      }
    })
  },
})

export const selectListingAction = (state: AppState) => state.listing.action
export const selectListingItemInfo = (state: AppState) => state.listing.listingItemInfo
export const selectIsFetchingListingItemInfo = (state: AppState) =>
  state.listing.isFetchingListingItemInfo
export const selectIsFetchingListingInfoError = (state: AppState) =>
  state.listing.isFetchingListingItemInfoError
export const selectListingFormData = (state: AppState) => state.listing.listingFormData
export const selectIsListingCreated = (state: AppState) => state.listing.isListingCreated
export const selectIsListingCreating = (state: AppState) => state.listing.isListingCreating
export const selectIsCreateListingError = (state: AppState) => state.listing.isCreateListingError
export const selectCreateListingStatus = (state: AppState) => ({
  isListingCreated: state.listing.isListingCreated,
  isListingCreating: state.listing.isListingCreating,
  isCreateListingError: state.listing.isCreateListingError,
})

export const {
  resetListingState,
  setListingAction,
  setListingItemInfo,
  setListingFormData,
  setOrderForListing,
  setOrderHashForListing,
  setCounterForListing,
  setIsListingCreated,
  setIsListingCreating,
  setIsCreateListingError,
} = listingSlice.actions

export default listingSlice
