/* eslint-disable @next/next/no-img-element */
import { cx } from '@/shared/utils'

const convertSize = {
  lg: 'w-14 h-14',
  base: 'w-14 h-14',
  sm: 'w-6 h-6',
  xs: 'w-6 h-6',
}

export default function Spinner({ size = 'base' }: { size?: 'xs' | 'sm' | 'base' | 'lg' }) {
  return (
    <div className="flex items-center justify-center">
      <div className={cx(convertSize[size], 'animate-spin')}>
        <img src="/img/common/loading.svg" alt="loading" className="w-full h-full" />
      </div>
    </div>
  )
}
