import { useQueries, useQuery } from '@tanstack/react-query'

import { IItemOfferListResponse, IOfferItem } from '@/shared/types'

import withAxios from '../api'

export const getPublicOfferList = async (itemId?: string) => {
  const {
    data: { offers },
  } = await withAxios<IItemOfferListResponse>({
    url: `/api/v1/public/items/${itemId?.toLowerCase()}/offers`,
    method: 'GET',
  })
  return offers
}

export const useGetPublicOfferList = (pathname: string, itemId?: string) => {
  return useQuery(
    [pathname, 'getPublicOfferList', { itemId }],
    async () => await getPublicOfferList(itemId),
    { enabled: itemId !== undefined },
  )
}

export const useGetPublicOfferListOfItems = (pathname: string, itemIdList: string[]) => {
  return useQueries({
    queries: itemIdList.map(itemId => ({
      queryKey: [pathname, 'getPublicOfferList', { itemId }],
      queryFn: async () => await getPublicOfferList(itemId),
      enabled: itemId !== undefined,
      select: (data: IOfferItem[]) => ({
        itemId,
        data,
      }),
    })),
  })
}
