import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

import withAxios from '../api'

export interface IUpdateCurrentAccountUsernameRequest {
  username: string
}

export const updateCurrentAccountUsername = async ({
  username,
}: IUpdateCurrentAccountUsernameRequest) => {
  const { data } = await withAxios({
    url: '/api/v1/private/current-account',
    method: 'PATCH',
    data: { username },
  })

  return data
}

export const useUpdateCurrentAccountUsername = (pathname: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ username }: { username: string }) => updateCurrentAccountUsername({ username }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([pathname])
      },
      onError: error => {
        let message =
          '프로필 변경을 실패했어요. 영문과 숫자로 4글자에서 16글자까지 입력할 수 있어요.'
        if (axios.isAxiosError(error)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.response?.data.message === 'duplicated username') {
            message = '이미 사용중인 닉네임이에요.'
          }
        }
        toast(message, {
          toastId: 'updateCurrentAccountUsernameError',
        })
      },
      retry: false,
    },
  )
}
