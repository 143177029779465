import { useMediaQuery } from '@mantine/hooks'

export default function useBreakpoint() {
  const isMobile = useMediaQuery('(max-width: 639px)')
  const isXS = useMediaQuery('(max-width: 360px)')
  const is2XS = useMediaQuery('(min-width: 428px)')
  const isSM = useMediaQuery('(min-width: 640px)')
  const isMD = useMediaQuery('(min-width: 768px)')
  const isLG = useMediaQuery('(min-width: 1024px)')
  const isXL = useMediaQuery('(min-width: 1280px)')
  const is2XL = useMediaQuery('(min-width: 1440px)')
  const is3XL = useMediaQuery('(min-width: 1600px)')
  const is4XL = useMediaQuery('(min-width: 1920px)')
  return {
    isMobile,
    isSM,
    isMD,
    isLG,
    isXL,
    is2XL,
    is3XL,
    is4XL,
    isXS,
    is2XS,
  }
}
