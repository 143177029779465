import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../store/hooks'
import { getContractTradeAddress } from '../store/trades'
import { getBalance, selectCurrentAccountAddress } from '../store/wallet'

export const useInitApp = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useRouter()
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentAccountAddress) dispatch(getBalance())
      else clearInterval(interval)
    }, 30000)

    return () => clearInterval(interval)
  }, [currentAccountAddress, dispatch])

  useEffect(() => {
    dispatch(getContractTradeAddress())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
}
