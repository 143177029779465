import { useQuery } from '@tanstack/react-query'

import { IBaseResponse } from '@/shared/types'

import withAxios from '../api'

interface IGetLikeCheckRequestProps {
  tokenId?: string
  collectionAddress?: string
}
interface IUseGetLikeCheckProps extends IGetLikeCheckRequestProps {
  pathname: string
  isConnectedWallet: boolean
}

export const getLikeCheck = async ({ tokenId, collectionAddress }: IGetLikeCheckRequestProps) => {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<{ isLike: boolean }>>({
    url: `/api/v1/private/collections/${collectionAddress?.toLowerCase()}/${tokenId}/like-check`,
    method: 'GET',
  })
  return data
}

export const useGetLikeCheck = ({
  tokenId,
  pathname,
  collectionAddress,
  isConnectedWallet,
}: IUseGetLikeCheckProps) => {
  return useQuery(
    [pathname, 'getLikeCheck', { tokenId, collectionAddress, isConnectedWallet }],
    () => getLikeCheck({ collectionAddress, tokenId }),
    {
      enabled: !!tokenId && !!collectionAddress && isConnectedWallet,
    },
  )
}
