import { useQuery } from '@tanstack/react-query'

import { IOriginalsScheduleResponse } from '@/shared/types'

import withAxios from '../api'

export const getOriginalsSchedule = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/v3/public/kkr-originals/originals/${originalsId}/floating-bar-info`
  const { data } = await withAxios<IOriginalsScheduleResponse>({ url })
  return data
}

export const useGetOriginalsSchedule = (pathname: string, originalsId?: string) =>
  useQuery(
    [pathname, 'getOriginalsSchedule', { originalsId }],
    () => getOriginalsSchedule({ originalsId }),
    { enabled: originalsId !== undefined },
  )
