import { useCallback } from 'react'
import TagManager from 'react-gtm-module'

import { GTM_EVENT_NAME } from '../constants'
import { IBeginCheckoutEventProps, IPurchaseEventPropsType } from '../types'
import { getEnvValue } from '../utils'

export const useGtmCustomEvent = () => {
  const GTM_ID = getEnvValue('gtmId')

  const pullTheTrigger = useCallback(
    ({ eventName, eventData }: { eventName: keyof typeof GTM_EVENT_NAME; eventData?: object }) => {
      if (GTM_ID !== '')
        TagManager.dataLayer({
          dataLayer: {
            ...eventData,
            event: GTM_EVENT_NAME[eventName],
          },
        })
    },
    [GTM_ID],
  )

  const searchGtmEvent = (pathname: string, searchText: string) => {
    pullTheTrigger({
      eventName: 'SEARCH',
      eventData: { page_path: pathname, search_term: searchText },
    })
  }

  const setUserId = useCallback(
    (userId: string) => {
      pullTheTrigger({
        eventName: 'USER_ID',
        eventData: {
          user_id: userId,
        },
      })
    },
    [pullTheTrigger],
  )

  const purchaseGtmEvent = useCallback(
    (eventDataProps: IPurchaseEventPropsType) => {
      pullTheTrigger({
        eventName: 'PURCHASE',
        eventData: {
          ecommerce: {
            transaction_id: eventDataProps.ordNo,
            value: eventDataProps.value,
            currency: 'KRW',
            item_id: eventDataProps.originalsId,
            item_name: eventDataProps.item_name,
          },
        },
      })
    },
    [pullTheTrigger],
  )

  const beginCheckoutGtmEvent = useCallback(
    (eventDataProps: IBeginCheckoutEventProps) => {
      pullTheTrigger({
        eventName: 'BEGIN_CHECKOUT',
        eventData: {
          ecommerce: null,
        },
      })
      pullTheTrigger({
        eventName: 'BEGIN_CHECKOUT',
        eventData: {
          ecommerce: {
            items: [
              {
                currency: 'KRW',
                item_id: eventDataProps.originalsId,
                item_name: eventDataProps.item_name,
                price: eventDataProps.price,
              },
            ],
          },
        },
      })
    },
    [pullTheTrigger],
  )

  return {
    pullTheTrigger,
    searchGtmEvent,
    purchaseGtmEvent,
    beginCheckoutGtmEvent,
    setUserId,
  }
}
