import { useCallback, useEffect, useState } from 'react'

import { useEventListener } from './useEventListener'

const useGetShowGoToAppDialog = () => {
  const [isShowGoToAppDialog, setIsShowGoToAppDialog] = useState(false)
  const handleListener = useCallback((event: any) => {
    try {
      const { type, message } = JSON.parse(event.data)

      if (type === 'ShowDialog' && message === 'HOME') {
        setIsShowGoToAppDialog(true)
      }
    } catch (error) {
      setIsShowGoToAppDialog(false)
    }
  }, [])

  const { setEventListener, clearEventListener } = useEventListener(handleListener)

  useEffect(() => {
    ;(() => {
      setEventListener()
    })()
    return () => {
      clearEventListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isShowGoToAppDialog,
    setIsShowGoToAppDialog,
  }
}

export default useGetShowGoToAppDialog
