import { IUtilitiesItem } from '@/shared/services/market'

export const getBenefitItemInfo = ({
  tokenId,
  hasBenefit,
  benefitList,
  collectionAddress,
}: {
  tokenId: string
  hasBenefit: boolean
  collectionAddress: string
  benefitList: IUtilitiesItem[] | undefined
}) => {
  if (hasBenefit === false || benefitList === undefined) {
    return null
  }

  const onSaleID = `${collectionAddress}-${tokenId}`
  const result = benefitList.filter(item => item.itemId === onSaleID)[0]

  if (result === undefined) {
    return null
  }

  return result
}
