import { useQuery } from '@tanstack/react-query'

import withAxios from '../api'

interface IGetCheckOfferItemResponse {
  data: {
    itemIds: string[]
  }
}

export const getCheckOfferItem = async ({ itemIds }: { itemIds: string[] }) => {
  const url = '/api/v1/private/current-account/is-offered'
  const {
    data: { data },
  } = await withAxios<IGetCheckOfferItemResponse>({
    url,
    method: 'POST',
    data: { itemIds },
  })

  return data.itemIds
}

export const useGetCheckOfferItem = ({
  itemIds,
  pathname,
}: {
  itemIds: string[]
  pathname: string
}) => {
  return useQuery([pathname, { itemIds }], () => getCheckOfferItem({ itemIds }), {
    useErrorBoundary: true,
    enabled: itemIds.length !== 0,
  })
}
