import { useDebouncedValue } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useGetCurrentAccountActivityCollections,
  useGetPublicAccountActivityCollections,
} from '../services/accounts'
import {
  accountActivityFilterSlice,
  accountActivityFilterState,
} from '../store/filters/accountActivityFilterSlice'
import { useAppSelector } from '../store/hooks'
import { selectCurrentAccountAddress } from '../store/wallet'
import { ActivityFilterType, ICollection, ISortOption } from '../types'

// TODO @고영욱 필터 관련 코드(현재 사용X)
export const useAccountActivityFilter = (isCurrentAccountPage = true) => {
  const { pathname, query } = useRouter()
  const { t } = useTranslation(['common'])
  const { accountAddress } = query as { accountAddress?: string }
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const [state, dispatch] = useReducer(
    accountActivityFilterSlice.reducer,
    accountActivityFilterState,
  )

  const {
    initAccountActivityFilter,
    removeCollectionFilter,
    setOrderAndOrderBy,
    setOwnCollections,
    toggleSelectedCollection,
    setIsClickChip,
    setTradeTypes,
  } = accountActivityFilterSlice.actions

  const sortOptions: ISortOption[] = [
    { text: t('common.label-list-activity-recent'), order: 'createdAt', orderBy: 'DESC' },
    { text: t('common.label-list-activity-oldest'), order: 'createdAt', orderBy: 'ASC' },
  ]

  const [isClickSort, setIsClickSort] = useState(false)

  const memoizedQueryParams = useMemo(
    () => ({
      order: state.order,
      orderBy: state.orderBy,
      collectionAddresses: state.collections
        .map(collection => collection.address)
        .filter(Boolean)
        .join(','),
      tradeTypes: state.tradeTypes.filter(Boolean).join(',').trim(),
    }),
    [state],
  )
  const [debouncedQueryParams] = useDebouncedValue(memoizedQueryParams, 200)

  const [filterQueryParams, setFilterQueryParams] = useState(debouncedQueryParams)

  const { data: accountCollections } = useGetCurrentAccountActivityCollections(
    pathname,
    currentAccountAddress,
  )
  const { data: publicAccountCollections } = useGetPublicAccountActivityCollections(
    pathname,
    accountAddress,
  )

  const collections = useMemo(() => {
    const mappings: {
      [key in 'currentAccount' | 'publicAccount']: ICollection[] | undefined
    } = {
      currentAccount: accountCollections?.collections,
      publicAccount: publicAccountCollections?.collections,
    }
    return mappings[isCurrentAccountPage ? 'currentAccount' : 'publicAccount']
  }, [accountCollections?.collections, isCurrentAccountPage, publicAccountCollections?.collections])

  useEffect(() => {
    dispatch(setOwnCollections(collections))
  }, [collections, setOwnCollections])

  const handleOrderAndSortBy = useCallback(
    ({ order, orderBy }: { order: string; orderBy: string }) => {
      if (state.orderBy !== orderBy) {
        setIsClickSort(true)
      } else {
        setIsClickSort(false)
      }

      dispatch(setOrderAndOrderBy({ order, orderBy }))
    },
    [setOrderAndOrderBy, state.orderBy],
  )

  const handleClickChip = useCallback(
    (isClickChip: boolean) => {
      dispatch(setIsClickChip(isClickChip))
    },
    [setIsClickChip],
  )

  const handleClear = useCallback(() => {
    dispatch(initAccountActivityFilter())
    dispatch(setOwnCollections(collections))
    handleClickChip(false)
    setFilterQueryParams({
      order: undefined,
      orderBy: 'DESC',
      collectionAddresses: '',
      tradeTypes: '',
    })
  }, [collections, handleClickChip, initAccountActivityFilter, setOwnCollections])

  const createFilteredTradeTypes = useCallback(
    (filterType: ActivityFilterType): ActivityFilterType[] => {
      const tradeTypes = state.tradeTypes
      let selectedFilters: ActivityFilterType[]

      const typeIndex = tradeTypes.indexOf(filterType)
      if (typeIndex < 0) {
        selectedFilters = [...tradeTypes, filterType]
      } else {
        selectedFilters = [...tradeTypes.slice(0, typeIndex), ...tradeTypes.slice(typeIndex + 1)]
      }

      return selectedFilters
    },
    [state.tradeTypes],
  )

  const handleActivityType = useCallback(
    (filterType: ActivityFilterType) => {
      const selectedFilters = createFilteredTradeTypes(filterType)
      dispatch(setTradeTypes(selectedFilters))
    },
    [createFilteredTradeTypes, setTradeTypes],
  )

  const handleCollectionClick = useCallback(
    (collection: ICollection) => dispatch(toggleSelectedCollection(collection)),
    [toggleSelectedCollection],
  )

  const handleRemoveCollectionFilter = useCallback(
    (collectionAddress: string) => {
      dispatch(removeCollectionFilter(collectionAddress))
    },
    [removeCollectionFilter],
  )

  return {
    state,
    isClickSort,
    handleClear,
    handleClickChip,
    filterQueryParams,
    setFilterQueryParams,
    debouncedQueryParams,
    handleCollectionClick,
    handleActivityType,
    handleOrderAndSortBy,
    handleRemoveCollectionFilter,
    sortOptions,
    isFilterVisible,
    setIsFilterVisible,
    collectionList: collections,
  }
}
