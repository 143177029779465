import Script from 'next/script'

import { getEnvValue } from '@/shared/utils'

const VConsole = () => {
  const isDev = getEnvValue('mode') === 'development'
  if (!isDev) return null
  return (
    <Script
      src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
      onLoad={() => {
        new window.VConsole()
      }}
    />
  )
}

export default VConsole
