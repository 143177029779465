import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ActivityFilterType, FilterChip, OrderByType } from '@/shared/types'

export interface ICollectionActivityFilterState {
  order?: string
  chips: FilterChip[]
  orderBy: OrderByType
  isClickChip: boolean
  tradeTypes: Array<ActivityFilterType>
}

export const collectionActivityFilterState: ICollectionActivityFilterState = {
  chips: [],
  orderBy: 'DESC',
  order: 'createdAt',
  tradeTypes: [],
  isClickChip: false,
}

export const collectionActivityFilterSlice = createSlice({
  name: 'collectionActivityFilter',
  initialState: collectionActivityFilterState,
  reducers: {
    initCollectionActivityFilter: () => collectionActivityFilterState,
    setIsClickChip: (state, action) => {
      state.isClickChip = action.payload
    },
    setOrderAndOrderBy: (state, action) => {
      state.order = action.payload.order
      state.orderBy = action.payload.orderBy
    },
    setTradeTypes: (state, action: PayloadAction<Array<ActivityFilterType>>) => {
      state.tradeTypes = action.payload
    },
  },
})
