import { useMutation } from '@tanstack/react-query'

import { IBaseResponse, IPostLikeItemRequest, IPostLikeItemResponse } from '@/shared/types'

import withAxios from '../api'

export async function postLikeItem({ itemId }: IPostLikeItemRequest) {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IPostLikeItemResponse>>({
    url: `/api/v1/private/items/${itemId}/likes`,
    method: 'POST',
  })

  return data
}

export function usePostLikeItem() {
  return useMutation(['postLikeItem'], ({ itemId }: { itemId: string }) => postLikeItem({ itemId }))
}
