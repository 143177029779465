import { useMutation } from '@tanstack/react-query'

import { IOffer } from '@/shared/types'

import withAxios from '../api'

interface ICreateOfferRequest {
  collectionAddress: string
  signHash: string
  orderHash: string
  paymentType: string
  price: string
  salt: string
  maximumFill: number
  makingTime: string
  expiredAt: string
  payAmount: string
  counter: number
  zoneHash: string
}

export const createOffer = async (itemId: string, data: ICreateOfferRequest) => {
  return withAxios<IOffer>({
    url: `/api/v1/private/items/${itemId.toLowerCase()}/offer`,
    method: 'POST',
    data,
  })
}

export const useCreateOffer = () => {
  return useMutation(
    ({ itemId, data }: { itemId: string; data: ICreateOfferRequest }) => createOffer(itemId, data),
    {
      retry: false,
    },
  )
}
