import bigDecimal from 'js-big-decimal'

import { ORIGINALS_PAYMENT_TYPE } from '@/shared/constants'
import { IOriginalsOption } from '@/shared/types'

/**
 *
 * @param selectedOptionList 클릭한 옵션
 * @param selectedPaymentType 클릭한 결제 수단
 * @returns 결제 수단에 따라 클릭한 옵션 총합을 계산합니다.
 */
export const getTotalPriceForOptions = (
  selectedOptionList: IOriginalsOption[],
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE,
): number => {
  const totalPrice = selectedOptionList.reduce((acc, current) => {
    const selectedPaymentTypePrice = current?.prices?.find(
      priceItem => priceItem.type === selectedPaymentType,
    )?.salePrice

    if (selectedPaymentTypePrice === undefined) return acc

    return parseInt(bigDecimal.add(acc, selectedPaymentTypePrice), 10)
  }, 0)
  return totalPrice
}
