import { Middleware } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { currencySlice, walletSlice } from '../wallet'

export const persistConfig = {
  key: 'konkrit-persist-state',
  whitelist: [currencySlice.name],
  storage,
}

const persistMiddleware: Middleware = () => dispatch => async action => {
  if (action.type === REHYDRATE && action.key === 'root') {
    dispatch(action)
  } else {
    dispatch(action)
  }
}

export default persistMiddleware
