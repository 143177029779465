export const ITEM_DETAIL_TAB = {
  detailInfo: '상세 정보',
  activity: '거래 내역',
  offer: '제안 현황',
} as const

export const ITEM_CARD_OPTIONS = {
  PURCHASE: 'common:common.cta-transaction-buy',
  OFFER: 'common:common.cta-label-offer',
  OFFER_EDIT: '제안 수정/취소',
  HIDE: 'common:common.label-hide-verb',
  UN_HIDE: 'common:common.label-cancel-hide',
  TRANSFER: 'common:common.item-transfer',
  LISTING: 'common:common.cta-label-sale',
  LISTING_EDIT: '판매 수정/취소',
  SHARE: 'common:common.cta-label-share',
  REFRESH: 'common:common.label-data-refresh',
} as const

export const COLLECTION_CARD_OPTIONS = {
  COLLECTION_EDIT: '수정하기',
  PAYOUT: '창작자 수익 보기',
} as const
