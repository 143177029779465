import { IOfferItem } from '@/shared/types'

import withAxios from '../api'

export const getOfferItem = async (itemId: string, offerId: string) => {
  const { data } = await withAxios<IOfferItem>({
    url: `/api/v1/private/items/${itemId.toLowerCase()}/offer/${offerId}`,
    method: 'GET',
  })

  return data
}
