import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { cancelListing, cancelOffer } from '@/shared/services/itemOrder'
import { CustomError } from '@/shared/types'
import { getSnapshotInError } from '@/shared/utils'
import { cancelAllTrade } from '@/shared/utils/order'

import { AppState, AppThunk } from '..'

export interface ICancelState {
  isCancelRequired: boolean
  isCancelCompleted: boolean
  isCanceling: boolean
  isCancelError: string | null
  isCancelFailedForUserDenied: boolean
  txHashForCancel: string | null
}

const initialState: ICancelState = {
  isCancelRequired: false,
  isCancelCompleted: false,
  isCanceling: false,
  isCancelError: null,
  isCancelFailedForUserDenied: false,
  txHashForCancel: null,
}

export const cancelSlice = createSlice({
  name: 'cancel',
  initialState,
  reducers: {
    resetCancelState: state => {
      state.isCancelRequired = false
      state.isCancelCompleted = false
      state.isCanceling = false
      state.isCancelError = null
      state.isCancelFailedForUserDenied = false
      state.txHashForCancel = null
    },
    setCancelIsRequired: (state, action: PayloadAction<boolean>) => {
      state.isCancelRequired = action.payload
    },
    setCancelIsCompleted: (state, action: PayloadAction<boolean>) => {
      state.isCancelCompleted = action.payload
    },
    setIsCanceling: (state, action: PayloadAction<boolean>) => {
      state.isCanceling = action.payload
    },
    setCancelIsError: (state, action: PayloadAction<string | null>) => {
      state.isCancelError = action.payload
      if (action.payload !== null) {
        const snapshotInError = getSnapshotInError({
          ...(typeof window !== 'undefined' ? { pathname: window.location.pathname } : {}),
          extra: {
            approveSlice: state,
          },
        })
        new CustomError({
          name: 'CANCEL_ERROR',
          message: action.payload,
        }).sendToSentry(snapshotInError)
      }
    },
    setCancelFailedForUserDenied: (state, action: PayloadAction<boolean>) => {
      state.isCancelFailedForUserDenied = action.payload
    },
    setTxHashForCancel: (state, action: PayloadAction<string | null>) => {
      state.txHashForCancel = action.payload
    },
  },
})

export const selectIsCancelRequired = (state: AppState) => state.cancel.isCancelRequired
export const selectIsCancelCompleted = (state: AppState) => state.cancel.isCancelCompleted
export const selectIsCancelFailedForUserDenied = (state: AppState) =>
  state.cancel.isCancelFailedForUserDenied
export const selectIsCancelError = (state: AppState) => state.cancel.isCancelError
export const selectIsCanceling = (state: AppState) => state.cancel.isCanceling
export const selectTxHashForCancel = (state: AppState) => state.cancel.txHashForCancel
export const selectCancelStatus = (state: AppState) => ({ ...state.cancel })

export const {
  resetCancelState,
  setCancelIsRequired,
  setCancelFailedForUserDenied,
  setCancelIsCompleted,
  setCancelIsError,
  setIsCanceling,
  setTxHashForCancel,
} = cancelSlice.actions

export const cancelAllListing = (): AppThunk => async (dispatch, getState) => {
  dispatch(setIsCanceling(true))

  const listingItemInfo = getState().listing.listingItemInfo
  const tradeAddress = getState().contractAddress.tradeAddress
  const action = getState().listing.action

  if (tradeAddress === null) {
    return dispatch(setCancelIsError('No trade addresses'))
  }

  if (listingItemInfo === null) {
    dispatch(setIsCanceling(false))
    return dispatch(setCancelIsError('No listing item info'))
  }

  if (action === 'create' || action === null) {
    dispatch(setIsCanceling(false))
    return dispatch(setCancelIsError('No cancel or edit action'))
  }

  const { listingItemListToCancel } = listingItemInfo

  if (listingItemListToCancel === null) {
    dispatch(setIsCanceling(false))
    return dispatch(setCancelIsError('No listing item list to cancel'))
  }

  await cancelAllTrade(
    listingItemInfo.chainId,
    String(listingItemInfo.tokenId),
    listingItemInfo.itemId,
    tradeAddress,
    listingItemListToCancel,
    cancelListing,
    (txHash: string) => dispatch(setTxHashForCancel(txHash)),
    () => dispatch(setCancelIsCompleted(true)),
    (loading: boolean) => dispatch(setIsCanceling(loading)),
    (error: string) => dispatch(setCancelIsError(error)),
    () => dispatch(setCancelFailedForUserDenied(true)),
  )
}

export const cancelAllOffer = (): AppThunk => async (dispatch, getState) => {
  dispatch(setIsCanceling(true))

  const offerItemInfo = getState().offer.offerItemInfo
  const tradeAddress = getState().contractAddress.tradeAddress
  const action = getState().offer.action

  if (tradeAddress === null) {
    return dispatch(setCancelIsError('No trade addresses'))
  }

  if (offerItemInfo === null) {
    dispatch(setIsCanceling(false))
    return dispatch(setCancelIsError('No offer item info'))
  }

  if (action === 'create' || action === null) {
    dispatch(setIsCanceling(false))
    return dispatch(setCancelIsError('No cancel or edit action'))
  }

  const { offerItemListToCancel } = offerItemInfo

  if (offerItemListToCancel === null) {
    dispatch(setIsCanceling(false))
    return dispatch(setCancelIsError('No offer item list to cancel'))
  }

  await cancelAllTrade(
    offerItemInfo.chainId,
    String(offerItemInfo.tokenId),
    offerItemInfo.itemId,
    tradeAddress,
    offerItemListToCancel,
    cancelOffer,
    (txHash: string) => dispatch(setTxHashForCancel(txHash)),
    () => dispatch(setCancelIsCompleted(true)),
    (loading: boolean) => dispatch(setIsCanceling(loading)),
    (error: string) => dispatch(setCancelIsError(error)),
    () => dispatch(setCancelFailedForUserDenied(true)),
  )
}

export default cancelSlice
