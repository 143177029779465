import { useQuery } from '@tanstack/react-query'

import { IAccountProfileResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountProfile = async () => {
  const url = '/api/v1/private/current-account'
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IAccountProfileResponse>>({ url, method: 'GET' })
  return data
}

export const useGetCurrentAccountProfile = (pathname: string, isConnectedWallet: boolean) =>
  useQuery(
    [pathname, 'getCurrentAccountProfile', { isConnectedWallet }],
    () => getCurrentAccountProfile(),
    {
      enabled: isConnectedWallet,
      refetchOnWindowFocus: false,
    },
  )
