/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ContractInterface, ethers } from 'ethers'

import { CHAIN_ID, PROVIDER_KEYS } from '@/shared/constants'
import { ERC20, ERC721 } from '@/shared/constants/abi'

export const getInjectedKonkritProviderFromApp = (
  chainId: keyof typeof PROVIDER_KEYS = CHAIN_ID.polygon,
): ethers.providers.ExternalProvider | Error => {
  if (typeof window === 'undefined') return new Error('No window object found')

  const providerKey = PROVIDER_KEYS[chainId]
  const provider = window[providerKey]
  if (!provider) return new Error('No injected provider found')

  return provider
}

export const getWeb3Provider = (
  chainId: keyof typeof PROVIDER_KEYS = CHAIN_ID.polygon,
  provider?: ethers.providers.JsonRpcFetchFunc | ethers.providers.ExternalProvider,
): ethers.providers.Web3Provider | Error => {
  const injectedProvider = provider ?? getInjectedKonkritProviderFromApp(chainId)
  if (injectedProvider instanceof Error) return injectedProvider

  return new ethers.providers.Web3Provider(injectedProvider)
}

export const getAccountAddressForEthereum = async () => {
  const web3Provider = getWeb3Provider(CHAIN_ID.ethereum)
  if (web3Provider instanceof Error) {
    return Promise.reject(web3Provider)
  }
  const signer = web3Provider.getSigner()
  const accountAddress = await signer.getAddress()

  return accountAddress
}

export const getAccountAddressForPolygon = async () => {
  const web3Provider = getWeb3Provider()
  if (web3Provider instanceof Error) {
    return Promise.reject(web3Provider)
  }
  const signer = web3Provider.getSigner()
  const accountAddress = await signer.getAddress()

  return accountAddress
}

export const getContractInstance = (
  contractAddress: string,
  contractAbi: ContractInterface,
  chainId: keyof typeof PROVIDER_KEYS = CHAIN_ID.ethereum,
  provider?: ethers.providers.JsonRpcFetchFunc | ethers.providers.ExternalProvider,
): ethers.Contract | Error => {
  const web3Provider = getWeb3Provider(chainId, provider)
  if (web3Provider instanceof Error) return web3Provider

  const contractInstance = new ethers.Contract(contractAddress, contractAbi, web3Provider)
  return contractInstance
}

export const getERC721ContractInstance = (
  contractAddress: string,
  chainId: keyof typeof PROVIDER_KEYS = CHAIN_ID.polygon,
) => {
  return getContractInstance(contractAddress, ERC721, chainId)
}

export const getERC20ContractInstance = (
  contractAddress: string,
  chainId: keyof typeof PROVIDER_KEYS = CHAIN_ID.polygon,
) => {
  return getContractInstance(contractAddress, ERC20, chainId)
}
