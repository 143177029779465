export const ORIGINALS_SALE_TYPE = {
  pre: 'pre',
  public: 'public',
  earlyBird: 'earlyBird',
} as const

export const ORIGINALS_PAYMENT_TYPE = {
  POL: 'POL', // POL
  BANK: 'BANK',
  FREE: 'FREE',
  CARD: 'CARD', // 전체 카드
  CARD_HYUNDAI: 'CARD_HYUNDAI', // 현대카드
  CARD_EVENT: 'CARD_EVENT', // 현대카드, 하나카드 제외한 카드
  PAYPAL: 'PAYPAL',
} as const

export const PAYMENT_STATUS_TYPE = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  SUCCESS_PAYMENT_APPROVE: 'SUCCESS_PAYMENT',
  SUCCESS_METADATA: 'SUCCESS_METADATA',
  FAILED_METADATA: 'FAILED_METADATA',
  PENDING_AIRDROP: 'PENDING_AIRDROP',
  SUCCESS_AIRDROP: 'SUCCESS_AIRDROP',
  FAILED_AIRDROP: 'FAILED_AIRDROP',
  SUCCESS_STOCK: 'SUCCESS_STOCK',
  SUCCESS_LAZY_MINTING: 'SUCCESS_LAZY_MINTING',
  FAILED_LAZY_MINTING: 'FAILED_LAZY_MINTING',
  FAILED_AIRDROP_SUCCESS_LAZY_MINTING: 'FAILED_AIRDROP_SUCCESS_LAZY_MINTING',
  FAILED_AIRDROP_FAILED_LAZY_MINTING: 'FAILED_AIRDROP_FAILED_LAZY_MINTING',
  FAILED_PAYMENT_APPROVE: 'FAILED_PAYMENT',
  SUCCESS_PAYMENT_CANCEL: 'SUCCESS_PAYMENT_CANCEL',
  FAILED_PAYMENT_CANCEL: 'FAILED_PAYMENT_CANCEL',
  FAILED_STOCK: 'FAILED_STOCK',
  SOLD_OUT: 'SOLD_OUT',
  PENDING_REFUND: 'PENDING_REFUND', // 환불요청
  SUCCESS_REFUND_APPROVE: 'SUCCESS_REFUND_APPROVE',
  FAILED_REFUND_APPROVE: 'FAILED_REFUND_APPROVE',
  REJECT_REFUND_APPROVE: 'REJECT_REFUND_APPROVE',
} as const

export const TERMS_LIST_TYPE = {
  PRIVACY_POLICY_AND_PAYMENT_TERNS_AGREEMENT: 'PRIVACY_POLICY_AND_PAYMENT_TERNS_AGREEMENT',
  REFUND_CANCEL_POLICY_AGREEMENT: 'REFUND_CANCEL_POLICY_AGREEMENT',
  AGE_19_OVER_AGREEMENT: 'AGE_19_OVER_AGREEMENT',
}

export const ORIGINALS_DISCOUNT_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED: 'FIXED',
  NONE: 'NONE',
}

export const ORIGINALS_PAYMENT_TYPE_NAME = {
  POL: 'POL', // POL
  BANK: '계좌이체',
  FREE: '무료',
  CARD: '카드결제', // 전체 카드
  CARD_HYUNDAI: '현대카드', // 현대카드
  CARD_EVENT: '카드결제', // 현대카드, 하나카드 제외한 카드
  PAYPAL: 'Pay with PayPal',
} as const

export const ORIGINALS_PAYMENT_TYPE_NAME_EN = {
  POL: 'POL', // POL
  BANK: 'Bank',
  FREE: 'Free',
  CARD: 'Card', // 전체 카드
  CARD_HYUNDAI: 'Hyundai Card', // 현대카드
  CARD_EVENT: 'Card', // 현대카드, 하나카드 제외한 카드
  PAYPAL: 'Pay with PayPal',
}

export const FLOATING_BAR_BUTTON_ACTION = {
  DISABLED: 'DISABLED',
  NONE: 'NONE',
  CHECKOUT: 'CHECKOUT',
  EXTERNAL: 'EXTERNAL',
  QR: 'QR',
  BOOK_EVENT: 'BOOK_EVENT',
} as const

export const OPTION_SELECT_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
} as const

export const ORIGINALS_ID = {
  DEFAULT: 'default',
  DAVINCI: 'davinci',
  ROONG_MATE: 'roong-mate',
  BUWOO_BUWOO: 'buwoo-buwoo',
  CADEJO: 'cadejo-2023',
  SUZUME: 'suzume-2023',
}

export const CHECKOUT_OPTION_THEME = {
  flat: 'flat',
  radio: 'radio',
} as const

export const ORIGINALS_CHECKOUT_OPTION_TYPE: { [key: string]: keyof typeof CHECKOUT_OPTION_THEME } =
  {
    default: 'flat',
    davinci: 'flat',
    'cadejo-2023': 'flat',
    'suzume-2023': 'flat',
  }

export const ORIGINALS_OPTION_GROUP_NAME = {
  PIECE: '일일권',
  INTEGRATED: '통합권',
} as const

export const ORIGINALS_PROJECT_TYPE = {
  NORMAL: 'NORMAL',
  FESTIVAL: 'FESTIVAL',
} as const

export const PAY_ERROR = {
  ALREADY_PAID_OPTION: 'ALREADY_PAID_OPTION',
  FAILED_PURCHASE_POSSIBILITY: 'FAILED_PURCHASE_POSSIBILITY',
}

export const ORIGINALS_TERMS_KEY = {
  PERSONALITY: 'PERSONALITY',
  CANCEL_REFUND: 'CANCEL_REFUND',
  ADULT: 'ADULT',
} as const

export const ALL_AGREE_TERMS_BLACK_LIST = ['davinci']
