export const makeCategoryTabList = ({
  data,
  handler,
}: {
  data: { categoryId: string; categoryTitle: string }[] | undefined
  handler: (value: string) => void
}) => {
  if (data === undefined) {
    return null
  }

  const defaultTab = {
    key: '',
    name: 'All',
    handler,
  }

  const resultTabList = data.map(
    ({ categoryId, categoryTitle }: { categoryId: string; categoryTitle: string }) => ({
      key: categoryId,
      name: categoryTitle,
      handler,
    }),
  )

  resultTabList.unshift(defaultTab)

  return resultTabList
}
