import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IBaseResponse, IUnHiddenItemResponse } from '@/shared/types'

import withAxios from '../api'

export const unHideItem = (itemId: string) => {
  return withAxios<IBaseResponse<IUnHiddenItemResponse>>({
    url: `/api/v1/private/items/${itemId}/hidden`,
    method: 'DELETE',
  })
}

export const useUnHideItem = (pathname: string, itemId: string) => {
  const { t } = useTranslation('web-current-account')

  const queryClient = useQueryClient()
  return useMutation(['unhide'], () => unHideItem(itemId), {
    onSuccess: () => {
      toast(t('web-current-account:current-account.toast-hide-cancel-nft'))
      queryClient.invalidateQueries([pathname])
    },
  })
}
