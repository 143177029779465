import { useCallback } from 'react'

export const useEventListener = (handler: (event: any) => void) => {
  const handleEvent = useCallback((event: any, handler: (event: any) => void) => {
    const isInvalidEvent =
      !event.type ||
      event.data === 'undefined' ||
      event.origin === 'https://pg.bluewalnut.co.kr' ||
      !window.ReactNativeWebView

    if (isInvalidEvent) return

    handler(event)
  }, [])
  const setEventListener = () => {
    document.addEventListener('message', (event: any) => handleEvent(event, handler))
    window.addEventListener('message', (event: any) => handleEvent(event, handler))
  }

  const clearEventListener = () => {
    document.removeEventListener('message', (event: any) => handleEvent(event, handler))
    window.removeEventListener('message', (event: any) => handleEvent(event, handler))
  }

  return {
    setEventListener,
    clearEventListener,
  }
}
