const videoExtensions = new Set(['mp4', 'webm', 'ogg', 'avi', 'mov', 'mkv'])

export const getFileExtension = (fileName?: string): string => {
  if (!fileName) return ''

  const [fileExtension] = fileName.split('.').pop()?.toLowerCase().split('?') || ['']

  return fileExtension
}

export const isCheckVideoFileExtension = (fileName?: string): boolean => {
  const fileExtension = getFileExtension(fileName)

  return videoExtensions.has(fileExtension)
}
