import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ActivityFilterType,
  FilterChip,
  IChipCollectionType,
  ICollection,
  OrderByType,
} from '@/shared/types'

export interface IAccountActivityFilterState {
  chips: FilterChip[]
  order?: string
  orderBy: OrderByType
  collections: IChipCollectionType[]
  ownCollections: ICollection[]
  selectedCollectionIds: string[]
  tradeTypes: ActivityFilterType[]
  isClickChip: boolean
}

export const accountActivityFilterState: IAccountActivityFilterState = {
  chips: [],
  order: undefined,
  orderBy: 'DESC',
  collections: [],
  ownCollections: [],
  selectedCollectionIds: [],
  tradeTypes: [],
  isClickChip: false,
}

export const accountActivityFilterSlice = createSlice({
  name: 'accountActivityFilter',
  initialState: accountActivityFilterState,
  reducers: {
    initAccountActivityFilter: () => accountActivityFilterState,
    setIsClickChip: (state, action) => {
      state.isClickChip = action.payload
    },
    setOrderAndOrderBy: (state, action) => {
      state.order = action.payload.order
      state.orderBy = action.payload.orderBy
    },
    setOwnCollections: (state, action) => {
      state.ownCollections = action.payload || []
    },
    setTradeTypes: (state, action: PayloadAction<ActivityFilterType[]>) => {
      state.tradeTypes = action.payload
    },
    toggleSelectedCollection: (state, action) => {
      const collectionAddress = action.payload.collectionAddress
      if (state.selectedCollectionIds.includes(collectionAddress)) {
        state.selectedCollectionIds = state.selectedCollectionIds.filter(
          id => id !== collectionAddress,
        )
        state.collections = state.collections.filter(
          collection => collection.address !== collectionAddress,
        )
      } else {
        const tmp = state.ownCollections.filter(
          collection => collection.collectionAddress === collectionAddress,
        )
        const selectedCollection = tmp.length > 0 ? tmp[0] : undefined
        if (selectedCollection) {
          state.selectedCollectionIds = [...state.selectedCollectionIds, collectionAddress]
          state.collections = [
            ...state.collections,
            {
              name: selectedCollection.title,
              address: selectedCollection.collectionAddress,
              type: 'collection',
            } as IChipCollectionType,
          ]
        }
      }
    },
    removeCollectionFilter: (state, action) => {
      const collectionAddress = action.payload
      state.collections = state.collections.filter(
        collection => collection.address !== collectionAddress,
      )
      state.selectedCollectionIds = state.selectedCollectionIds.filter(
        address => address !== collectionAddress,
      )
    },
  },
})
