import { useMutation } from '@tanstack/react-query'

import { IOriginalsCheckoutPaymentsProps, IPostOriginalsBankPaymentsResponse } from '@/shared/types'

import withAxios from '../api'

export async function updateOriginalsCheckoutBankPayments({
  originalsId,
  currency,
  selectOptions,
  paymentType,
}: IOriginalsCheckoutPaymentsProps) {
  const url = '/api/v3/private/kkr-payments/originals-checkout/bank'
  const { data } = await withAxios<IPostOriginalsBankPaymentsResponse>({
    url,
    method: 'POST',
    data: {
      originalsId,
      currency,
      selectOptions,
      paymentType,
    },
  })

  return data
}

export function useUpdateOriginalsCheckoutBankPayments() {
  return useMutation(
    ['useUpdateOriginalsCheckoutBankPayments'],
    ({ originalsId, currency, selectOptions, paymentType }: IOriginalsCheckoutPaymentsProps) =>
      updateOriginalsCheckoutBankPayments({
        originalsId,
        currency,
        selectOptions,
        paymentType,
      }),
  )
}
