import { IBaseResponse, IOriginalsETHPaymentsStatusResponse } from '@/shared/types'

import withAxios from '../api'

export const postETHPaymentsStatus = async ({
  ordNo,
  transactionHash,
}: {
  ordNo: string
  transactionHash: string
}) => {
  const url = '/api/v1/private/eth-payments/status'
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IOriginalsETHPaymentsStatusResponse>>({
    url,
    method: 'POST',
    data: {
      ordNo,
      transactionHash,
      paymentResult: 'SUCCESS',
    },
  })
  return data
}
