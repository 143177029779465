import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountItemsDebouncedQueryParams,
  IAccountItemsResponse,
  IBaseResponse,
  IGetItemListRequest,
} from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountLikedItems = async (params?: IGetItemListRequest) => {
  const url = '/api/v1/private/current-account/liked-items'
  const { data } = await withAxios<IBaseResponse<IAccountItemsResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetCurrentAccountLikedItems = (
  pathname: string,
  accountAddress: string | null,
  refresh: boolean,
  debouncedQueryParams?: IAccountItemsDebouncedQueryParams,
) =>
  useInfiniteQuery<IAccountItemsResponse>(
    [pathname, 'getCurrentAccountLikedItems', { debouncedQueryParams }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 10,
        refresh,
      } as IGetItemListRequest
      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }
      const response = await getCurrentAccountLikedItems(queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      enabled: accountAddress !== null,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
