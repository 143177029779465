import { useQuery } from '@tanstack/react-query'

import { ICheckoutDetailItems, IOriginalsPaymentResultResponse } from '@/shared/types'

import withAxios from '../api'
import { refundCalculator } from '../utilities/common'

export const getPaymentResult = async ({ ordNo }: { ordNo?: string }) => {
  const url = `/api/v3/private/kkr-payments/checkout/${ordNo}`
  const { data } = await withAxios<IOriginalsPaymentResultResponse>({ url })
  return data
}

export const useGetPaymentResult = (pathname: string, ordNo?: string) =>
  useQuery([pathname, 'getPaymentResult', { ordNo }], () => getPaymentResult({ ordNo }), {
    enabled: ordNo !== undefined,
    select: data => {
      let totalRefundAmount = 0
      let totalRefundFee = 0
      const checkoutDetailItemsWithRefundData: ICheckoutDetailItems[] = []

      data?.checkoutDetailItems?.forEach(item => {
        const refundData = refundCalculator(item.eventDate, item.totalPrice, data.paidAt)
        totalRefundAmount = totalRefundAmount + (refundData?.refundAmount || 0)
        totalRefundFee = totalRefundFee + (refundData?.refundFee || 0)

        checkoutDetailItemsWithRefundData.push({
          ...item,
          ...refundData,
        })
      })

      return {
        ...data,
        checkoutDetailItems: checkoutDetailItemsWithRefundData || data.checkoutDetailItems,
        totalRefundAmount,
        totalRefundFee,
      }
    },
  })
