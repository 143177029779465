import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IBaseResponse,
  ICollectionActivityDebouncedQueryParams,
  ICollectionActivityResponse,
} from '@/shared/types'

import withAxios from '../api'

interface IGetCollectionActivityRequest extends ICollectionActivityDebouncedQueryParams {
  page: number
  perPage: number
}

export async function getCollectionActivities(
  collectionAddress?: string,
  params?: IGetCollectionActivityRequest,
): Promise<ICollectionActivityResponse> {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<ICollectionActivityResponse>>({
    url: `/api/v1/public/collections/${collectionAddress?.toLowerCase()}/activities`,
    params,
    method: 'GET',
  })
  return data
}

export const useInfiniteGetCollectionActivities = (
  pathname: string,
  collectionAddress?: string,
  debouncedQueryParams?: ICollectionActivityDebouncedQueryParams,
) => {
  return useInfiniteQuery(
    [pathname, 'collectionActivity', { collectionAddress, debouncedQueryParams }],
    async ({ pageParam }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 20,
      } as IGetCollectionActivityRequest
      const response = await getCollectionActivities(collectionAddress, queryParams)
      return response
    },
    {
      getNextPageParam: data => (data.activities.length === 20 && data.page + 1) ?? undefined,
      enabled: collectionAddress !== undefined,
    },
  )
}
