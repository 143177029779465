import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { i18n } from 'next-i18next'

import { CustomError } from '../types'
import { getEnvValue, getSnapshotInError, LocalStorage } from '../utils'
import { exchangingAccessToken } from './auth/exchangingAccessToken'

export interface IRequestConfig extends AxiosRequestConfig {
  suppressStatusCode?: number[]
}

const changeRefreshTokenToAccessToken = async (
  originalRequest: IRequestConfig,
): Promise<AxiosResponse<unknown, any> | undefined> => {
  if (typeof window === 'undefined') return

  const refreshToken = LocalStorage.getItem('refreshToken')
  if (refreshToken === null) {
    throw new CustomError({
      name: 'ACCESS_TOKEN_ERROR',
      message: 'localStorage에 저장된 refreshToken이 없습니다.',
    })
  }

  const token = await exchangingAccessToken(refreshToken)
  if (token !== undefined) {
    LocalStorage.setItem('accessToken', token.accessToken as string)
    LocalStorage.setItem('refreshToken', token.refreshToken as string)
    LocalStorage.setItem('refreshTokenExpiresAt', token.refreshTokenExpiresAt?.toString())
    return withAxios(originalRequest)
  }
}

async function onErrorResponse(error: AxiosError | Error) {
  if (axios.isAxiosError(error)) {
    const originalRequest = error?.config as IRequestConfig
    const response = error?.response as AxiosResponse

    if (response?.status === 401) {
      try {
        changeRefreshTokenToAccessToken(originalRequest)
      } catch (e: unknown) {
        const snapshotInError = getSnapshotInError({
          ...(window !== undefined ? { pathname: window.location.pathname } : {}),
          extra: { originalRequest, response, e },
        })
        if (e instanceof CustomError) {
          e.sendToSentry(snapshotInError)
        } else {
          new CustomError({
            name: 'UNHANDLED_ERROR',
            message: '알 수 없는 에러가 발생했습니다.',
          }).sendToSentry(snapshotInError)
        }
      }
    }
  } else {
    if (error instanceof Error) {
      const snapshotInError = getSnapshotInError({
        ...(window !== undefined ? { pathname: window.location.pathname } : {}),
        extra: { error },
      })
      new CustomError({
        name: 'UNHANDLED_ERROR',
        message: '알 수 없는 에러가 발생했습니다.',
      }).sendToSentry(snapshotInError)
    }
  }
  return Promise.reject(error)
}

async function onResponse(response: AxiosResponse): Promise<AxiosResponse> {
  const originalRequest = response.config
  const { status } = response
  if (status === 401) {
    try {
      changeRefreshTokenToAccessToken(originalRequest)
    } catch (e: unknown) {
      const snapshotInError = getSnapshotInError({
        ...(window !== undefined ? { pathname: window.location.pathname } : {}),
        extra: { originalRequest, response, e },
      })
      if (e instanceof CustomError) {
        e.sendToSentry(snapshotInError)
      } else {
        new CustomError({
          name: 'UNHANDLED_ERROR',
          message: '알 수 없는 에러가 발생했습니다.',
        }).sendToSentry(snapshotInError)
      }
      return Promise.reject(e)
    }
  } else if (status >= 400 && status !== 401) {
    return Promise.reject(response)
  }
  return response
}

function onRequest(config: InternalAxiosRequestConfig) {
  const pass = ['oauth', 'public', 'lambda']
  if (pass.filter(u => config.url?.includes(u)).length !== 0) return config

  if (typeof window === 'undefined') return Promise.reject('Cannot get access token')

  const accessToken = LocalStorage.getItem('accessToken')
  if (!accessToken) return Promise.reject('No Access Token')

  config.headers.Authorization = `Bearer ${accessToken}`

  return config
}

export default async function withAxios<T>(requestConfig: IRequestConfig) {
  const instance = axios.create() // 기본 axios 인스턴스 생성

  // 요청 인터셉터를 추가합니다.
  instance.interceptors.request.use(
    config => {
      const locale = i18n?.language || 'ko'

      if (locale) {
        config.headers['Accept-Language'] = locale as string
      }

      return onRequest(config)
    },
    error => Promise.reject(error),
  )

  // 응답 인터셉터 설정은 변경하지 않습니다.
  if (typeof window !== 'undefined') {
    instance.interceptors.response.use(
      response => onResponse(response),
      error => onErrorResponse(error),
    )
  }

  const apiBaseUrl = getEnvValue('apiBaseUrl') || ''

  const response = await instance.request<T>({
    ...requestConfig,
    baseURL: apiBaseUrl,
  })

  return response
}

export const withStrapiAxios = async <T>(requestConfig: IRequestConfig) => {
  const instance = axios.create()

  if (typeof window !== 'undefined') {
    instance.interceptors.request.use(
      config => config,
      error => Promise.reject(error),
    )
    instance.interceptors.response.use(
      response => onResponse(response),
      error => onErrorResponse(error),
    )
  }

  const apiBaseUrl = getEnvValue('strapiApiBaseUrl') || ''

  const isDev = getEnvValue('mode') === 'development'
  const publicationState = isDev ? 'preview' : 'live'

  const locale = i18n?.language || 'ko'

  const response = await instance.request<T>({
    ...requestConfig,
    baseURL: apiBaseUrl,
    params: {
      ...requestConfig.params,
      populate: 'deep',
      publicationState: publicationState,
      locale,
    },
  })

  return response
}
