import { IOriginalsRanking } from '../types'

export const makePossibleToWinForRanking = (
  collectionHolderRank: Array<IOriginalsRanking>,
  rewardLimit: number | null,
) => {
  if (rewardLimit === null) return []
  const rankingGroups: IOriginalsRanking[][] = []
  collectionHolderRank.forEach(item => {
    if (rankingGroups.length === 0) return rankingGroups.push([item])
    if (rankingGroups[rankingGroups.length - 1][0].rank === item.rank)
      rankingGroups[rankingGroups.length - 1].push(item)
    else rankingGroups.push([item])
  })

  let possibleToWin: IOriginalsRanking[] = []
  let done = false
  rankingGroups.forEach(group => {
    if (done) return
    if (possibleToWin.length === 0) {
      possibleToWin = possibleToWin.concat(group)
      return
    }
    const temp: IOriginalsRanking[] = JSON.parse(JSON.stringify(possibleToWin))
    if (temp.concat(group).length > rewardLimit) {
      done = true
      return
    } else {
      possibleToWin = possibleToWin.concat(group)
    }
  })
  return possibleToWin
}
