import { CombinedState } from '@reduxjs/toolkit'
import { Scope, SeverityLevel } from '@sentry/nextjs'
import { AxiosError, AxiosResponse } from 'axios'

import { IReducerStates } from '../store'
import { getEnvValue, getErrorName } from '../utils'

export type CustomErrorName =
  | 'GET_ORD_NO_ERROR'
  | 'UNHANDLED_ERROR'
  | 'GET_BALANCE_ERROR'
  | 'LOGIN_ERROR'
  | 'NO_RESOURCE_ERROR'
  | 'CHECKOUT_ERROR'
  | 'GET_WAITING_ROOM_ERROR'
  | 'ACCESS_TOKEN_ERROR'
  | 'LISTING_ERROR'
  | 'OFFER_ERROR'
  | 'LISTING_CANCEL_ERROR'
  | 'OFFER_CANCEL_ERROR'
  | 'CANCEL_ERROR'
  | 'PURCHASE_ERROR'
  | 'ACCEPT_OFFER_ERROR'
  | 'APPROVE_ERROR'
  | 'GET_LISTING_LIST_ERROR'
  | 'GO_TO_APP_SCREEN_ERROR'
  | 'UPDATE_CHECKOUT_INFO_ERROR'

export interface IErrorData {
  name?: CustomErrorName
  error?: string
  message: string
  status?: number
}

export interface ISnapshotInError extends Record<string, unknown> {
  pathname?: string
  page?: string
  state?: CombinedState<IReducerStates>
  extra?: Record<string, unknown>
}

export class CustomError extends Error {
  public status?: number

  constructor(error: IErrorData) {
    super(error.message)
    this.name = error.error || error.name || 'UNHANDLED_ERROR'
    this.message = error.message
    this.status = error.status
  }

  public isCustomError(): this is CustomError {
    return this instanceof CustomError
  }

  public sendToSentry(snapshotInError: ISnapshotInError, level?: SeverityLevel, tag?: 'string') {
    const isDev = getEnvValue('mode') === 'development'

    if (isDev) {
      console.log(this, snapshotInError)
      return
    }

    const scope = new Scope()

    scope.setLevel(level || 'debug')

    scope.setTags({ customError: this.name, status: this.status, tag: tag })

    scope.setFingerprint([this.name, this.message])

    scope.setContext('snapshot', snapshotInError)
  }
}

export const isCustomError = (error: any): error is CustomError => {
  return error instanceof CustomError
}

export class CustomAxiosError<T = unknown, D = any> extends AxiosError {
  public code?: string

  public request?: any

  public response?: AxiosResponse<T>

  public isAxiosError: boolean

  public errorStatus: number

  public toJSON: () => any

  constructor(error: AxiosError<T, D>, message?: string) {
    super(message ?? error.message)
    const errorStatus = error.response?.status || 0

    this.name = getErrorName(errorStatus)
    this.errorStatus = errorStatus
    this.stack = error.stack
    this.config = error.config
    this.code = error.code
    this.request = error.request
    this.response = error.response
    this.isAxiosError = error.isAxiosError
    this.toJSON = error.toJSON
  }

  public sendToSentry(snapshotInError: ISnapshotInError) {
    const isDev = getEnvValue('mode') === 'development'

    if (isDev) {
      console.log(this, snapshotInError)
      return
    }

    const scope = new Scope()

    scope.setLevel(this.errorStatus >= 500 ? 'error' : 'warning')

    scope.setTags({
      api: this.name,
      status: this.errorStatus,
    })

    scope.setFingerprint([
      this.config?.method || 'unknown method',
      this.name,
      this.config?.url || 'unknown url',
    ])

    scope.setContext('snapshot', snapshotInError)
  }
}
