import { useQuery } from '@tanstack/react-query'

import { ICheckRaffleResultResponseType } from '@/shared/types'

import withAxios from '../api'

export async function getCheckRaffleResult({ originalsId }: { originalsId?: string }) {
  const url = `/api/v3/private/kkr-originals/originals/${originalsId}/check-raffle-result`
  const { data } = await withAxios<ICheckRaffleResultResponseType>({
    url,
    method: 'GET',
  })

  return data
}

export const useGetCheckRaffleResult = (originalsId?: string, isConnectedWallet?: boolean) =>
  useQuery(['getCheckRaffleResult'], () => getCheckRaffleResult({ originalsId }), {
    enabled: originalsId !== undefined || isConnectedWallet === true,
    refetchOnWindowFocus: false,
  })
