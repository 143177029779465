import {
  IConSizeType,
  ITradeTypeIconReturn,
  ITradeTypeIconReturnSpecificSize,
  PaymentType,
} from '@/shared/types'

import { PAYMENT_TYPE } from '../constants'

const tradeTypeIcon: Record<PaymentType, ITradeTypeIconReturn> = {
  [PAYMENT_TYPE.ETH]: {
    url: '/img/icon/ethereum.svg',
    xs: [6, 9.6],
    sm: [8, 12.8],
    base: [8, 12.8],
    lg: [10, 16],
    xl: [12, 19.2],
    '2xl': [12, 19.2],
  },
  [PAYMENT_TYPE.WETH]: {
    url: '/img/icon/wrapped-ethereum.svg',
    xs: [6, 9.6],
    sm: [8, 12.8],
    base: [8, 12.8],
    lg: [10, 16],
    xl: [12, 19.2],
    '2xl': [12, 19.2],
  },
  [PAYMENT_TYPE.POL]: {
    url: '/img/icon/matic.svg',
    xs: [12, 12],
    sm: [12, 12],
    base: [14, 14],
    lg: [14, 14],
    xl: [16, 16],
    '2xl': [16, 16],
  },
  [PAYMENT_TYPE.WPOL]: {
    url: '/img/icon/wmatic.svg',
    xs: [12, 12],
    sm: [12, 12],
    base: [14, 14],
    lg: [14, 14],
    xl: [16, 16],
    '2xl': [16, 16],
  },
}

export const getTradeTypeIcon = (
  paymentType: PaymentType,
  size?: IConSizeType,
): ITradeTypeIconReturn | ITradeTypeIconReturnSpecificSize => {
  if (!size) {
    return tradeTypeIcon[paymentType]
  }

  const [width, height] = tradeTypeIcon[paymentType][size]

  return {
    url: tradeTypeIcon[paymentType].url,
    width,
    height,
  }
}
