import { useMutation } from '@tanstack/react-query'

import {
  IOriginalsCheckoutPaymentsProps,
  IPostOriginalsPaypalPaymentsResponse,
} from '@/shared/types'

import withAxios from '../api'

export async function updateOriginalsCheckoutPaypalPayments({
  originalsId,
  currency,
  selectOptions,
  paymentType,
}: IOriginalsCheckoutPaymentsProps) {
  const url = '/api/v3/private/kkr-payments/originals-checkout/paypal'
  const { data } = await withAxios<IPostOriginalsPaypalPaymentsResponse>({
    url,
    method: 'POST',
    data: {
      originalsId,
      currency,
      selectOptions,
      paymentType,
    },
  })

  return data
}

export function useUpdateOriginalsCheckoutPaypalPayments() {
  return useMutation(
    ['useUpdateOriginalsCheckoutPaypalPayments'],
    ({ originalsId, currency, selectOptions, paymentType }: IOriginalsCheckoutPaymentsProps) =>
      updateOriginalsCheckoutPaypalPayments({
        originalsId,
        currency,
        selectOptions,
        paymentType,
      }),
  )
}
