/* eslint-disable @typescript-eslint/ban-ts-comment */
import '@/styles/globals.css'
import '@/styles/originals.css'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { MantineProvider } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Image from 'next/image'
import { appWithTranslation } from 'next-i18next'
import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import { Spinner } from '@/components/core-ui'
import { VConsole } from '@/components/dev'
import { DialogProvider } from '@/components/dialogs'
import { AuthProvider, WaitingRoomProvider } from '@/shared/contexts'
import { useInitApp } from '@/shared/hooks'
import { getConfig } from '@/shared/services/config'
import wrapper from '@/shared/store'
import { getEnvValue, LocalStorage } from '@/shared/utils'

import i18nextConfig from '../../next-i18next.config'

export type NextPageWithLayout<P = Record<any, any>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { store } = wrapper.useWrappedStore(pageProps)

  const isMobile = useMediaQuery('(max-width: 690px)')
  const GTM_ID = getEnvValue('gtmId')

  const [queryClient] = useState(() => new QueryClient())
  useInitApp()

  const fetchConfig = useCallback(async () => {
    try {
      const config = await getConfig()
      LocalStorage.setItem('config', JSON.stringify(config))
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig])

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID ?? '' })
  }, [GTM_ID])

  const getLayout = Component.getLayout ?? (page => page)

  return (
    <>
      <Head>
        <title>대체 불가능한 NFT 거래 플랫폼 콘크릿 KONKRIT</title>
        <meta name="description" content="Generated by create next app" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />

        <link rel="icon" href="/img/head/favicon.png" />
        <meta name="theme-color" content="#18191a" />
        <meta
          name="description"
          content="NFT 거래, 더 KONKRIT(콘크릿)하게. 실생활 밀착형 유틸리티 기반의 NFT부터 PFP, Art, 연예인 NFT까지. 모두 NFT 마켓 플레이스 콘크릿에서 만나보세요."
        />
        <meta property="og:title" content="대체 불가능한 NFT 거래 플랫폼 KONKRIT" />
        <meta name="keywords" content="콘크릿, 컨크릿, 콘크릿 베타, 컨크릿 베타" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="NFT 거래, 더 KONKRIT(콘크릿)하게. 실생활 밀착형 유틸리티 기반의 NFT부터 PFP, Art, 연예인 NFT까지. 모두 NFT 마켓 플레이스 콘크릿에서 만나보세요."
        />
        <meta property="og:image" content="/img/head/og_image.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="apple-touch-icon" href="/img/head/favicon_192.png" />

        <link rel="manifest" href="/manifest.json" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <MantineProvider>
            <AuthProvider>
              <WaitingRoomProvider>
                <PersistGate
                  loading={
                    <div className="min-h-[100vh] flex align-center justify-center">
                      <Spinner />
                    </div>
                  }
                  // @ts-ignore
                  persistor={store.__persistor}>
                  <DialogProvider>
                    {isMobile && getLayout(<Component {...pageProps} />)}
                  </DialogProvider>
                </PersistGate>
              </WaitingRoomProvider>
            </AuthProvider>
            <ToastContainer
              autoClose={2000}
              toastClassName={() =>
                'text-sm leading-[18px] text-appTextPrimary bg-appBgSecondary rounded-md p-4 mb-5'
              }
              icon={() => (
                <Image
                  width={20}
                  height={20}
                  alt="toast check icon"
                  src="/img/icon/green-outline-check.svg"
                />
              )}
              position="bottom-center"
              hideProgressBar={true}
              pauseOnHover={true}
              closeButton={false}
            />
          </MantineProvider>
        </Hydrate>
        <VConsole />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default wrapper.withRedux(appWithTranslation(App, i18nextConfig))
