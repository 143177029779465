import { useQuery } from '@tanstack/react-query'

import {
  IAccountItemsWithReceivedOfferDebouncedQueryParams,
  IAccountItemsWithReceivedOfferResponse,
  IBaseResponse,
  OrderByType,
} from '@/shared/types'

import withAxios from '../api'

interface IUseGetCurrentAccountItemsWithReceivedOfferProps {
  pathname: string
  refresh: boolean
  debouncedQueryParams?: IAccountItemsWithReceivedOfferDebouncedQueryParams
  currentAccountAddress: string | null
}

export const getCurrentAccountItemsWithReceivedOffer = async (params?: {
  order?: string
  orderBy?: OrderByType
  collectionAddresses?: string
  refresh: boolean
}) => {
  const url = '/api/v1/private/current-account/received-offers'
  const {
    data: {
      data: { items },
    },
  } = await withAxios<IBaseResponse<IAccountItemsWithReceivedOfferResponse>>({
    url,
    method: 'GET',
    params,
  })
  return items
}

export const useGetCurrentAccountItemsWithReceivedOffer = ({
  pathname,
  refresh,
  debouncedQueryParams,
  currentAccountAddress,
}: IUseGetCurrentAccountItemsWithReceivedOfferProps) =>
  useQuery(
    [pathname, 'getCurrentAccountItemsWithReceivedOffer', { ...debouncedQueryParams }],
    () => {
      if (debouncedQueryParams?.collectionAddresses === '') {
        delete debouncedQueryParams['collectionAddresses']
      }

      return getCurrentAccountItemsWithReceivedOffer({ ...debouncedQueryParams, refresh })
    },
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      enabled: currentAccountAddress !== null,
    },
  )
