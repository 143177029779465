import { useQuery } from '@tanstack/react-query'

import { IBaseResponse, IGetCollectionPropertiesResponse } from '@/shared/types'

import withAxios from '../api'

export const getCollectionProperties = async (collectionAddress?: string) => {
  const {
    data: {
      data: { properties },
    },
  } = await withAxios<IBaseResponse<IGetCollectionPropertiesResponse>>({
    url: `/api/v1/public/collections/${collectionAddress}/properties`,
    method: 'GET',
  })
  return properties
}

export const useGetCollectionProperties = (pathname: string, collectionAddress?: string) => {
  return useQuery(
    [pathname, 'getCollectionProperties', { collectionAddress }],
    async () => {
      return await getCollectionProperties(collectionAddress)
    },
    {
      enabled: collectionAddress !== undefined,
    },
  )
}
