import { useQuery } from '@tanstack/react-query'

import { ISeatEventsInfo } from '@/shared/types'

import withAxios from '../api'

export const getSeatEventList = async ({
  originalsId,
}: {
  originalsId?: string
}): Promise<ISeatEventsInfo[]> => {
  const url = `/api/v3/public/kkr-originals/originals/${originalsId}/seat-events`

  const { data } = await withAxios<ISeatEventsInfo[]>({
    url,
    method: 'GET',
  })

  return data
}

export const useGetSeatEventList = (originalsId?: string) =>
  useQuery(['useGetSeatEventList', { originalsId }], () => getSeatEventList({ originalsId }), {
    refetchOnWindowFocus: false,
    enabled: originalsId !== undefined,
  })
