import {
  ETHER_LISTING_PAYMENT_TYPE,
  ETHER_OFFER_PAYMENT_TYPE,
  LISTING_PAYMENT_TYPE,
  OFFER_PAYMENT_TYPE,
  PAYMENT_TYPE,
  POLYGON_LISTING_PAYMENT_TYPE,
  POLYGON_OFFER_PAYMENT_TYPE,
} from '../constants/order'
import { ICollectionStats } from './collection'
import { ITradeAddress, ITradeAddressForOrderItem } from './contracts'

export type PaymentType = keyof typeof PAYMENT_TYPE

export type ListingPaymentType = keyof typeof LISTING_PAYMENT_TYPE
export type OfferPaymentType = keyof typeof OFFER_PAYMENT_TYPE

export type EtherChainListingPaymentType = keyof typeof ETHER_LISTING_PAYMENT_TYPE
export type EtherChainOfferPaymentType = keyof typeof ETHER_OFFER_PAYMENT_TYPE

export type PolygonChainListingPaymentType = keyof typeof POLYGON_LISTING_PAYMENT_TYPE
export type PolygonChainOfferPaymentType = keyof typeof POLYGON_OFFER_PAYMENT_TYPE

export interface IMarketInfo {
  market: {
    accountAddress: string
    chainId: string
    feeRate: string
  }[]
}

export interface IListingItemInfo {
  itemId: string
  collectionAddress: string
  tokenId: number | string
  collectionTitle: string
  itemTitle: string
  mediaUrl: string
  ownerAddress: string
  chainId: string

  royaltyInfo: IRoyaltyInfo | null
  collectionStats: ICollectionStats | null
  listingItemToEdit: IListing | null
  listingItemListToCancel: IListingItem[] | null
  tradeAddress: ITradeAddress | null
}

export interface IOfferItemInfo {
  itemId: string
  collectionAddress: string
  tokenId: number | string
  collectionTitle: string
  itemTitle: string
  mediaUrl: string
  chainId: string

  royaltyInfo: IRoyaltyInfo | null
  collectionStats: ICollectionStats | null
  offerItemToEdit: IOffer | null
  offerItemListToCancel: IOfferItem[] | null
  publicHighestPriceOffer: IOffer | null
  inactiveListingList: IListingItem[] | null
  tradeAddress: ITradeAddress | null
}

export interface ICreateListingFormData {
  price: string
  paymentType: ListingPaymentType
  expirationTime: string
}

export interface ICreateOfferFormData {
  price: string
  paymentType: OfferPaymentType
  expirationTime: string
}

export interface IRoyaltyInfo {
  marketFee: string
  marketOwner: string
  royalties: string[]
  royaltyReceivers: string[]
}

export interface IListing {
  id: string
  itemId: string
  collectionAddress: string
  signHash: string
  orderHash: string
  accountAddress: string
  paymentType: ListingPaymentType
  price: string
  payAmount: string
  salt: string
  filled: number
  maximumFill: number
  isCanceled: boolean
  makingTime: string
  expiredAt: string
  createdAt: string
  counter: number
  tradeAddress: ITradeAddressForOrderItem
}

export interface IOffer {
  id: string
  itemId: string
  collectionAddress: string
  signHash: string
  orderHash: string
  accountAddress: string
  paymentType: OfferPaymentType
  price: string
  payAmount: string
  salt: string
  filled: number
  maximumFill: number
  isCanceled: boolean
  makingTime: string
  expiredAt: string
  createdAt: string
  counter: number
  tradeAddress: ITradeAddressForOrderItem
}

export interface IListingItem {
  listing: IListing
  marketFee: string
  marketOwner: string
  royaltyReceivers: string[]
  royalties: string[]
}

export interface IOfferItem {
  offer: IOffer
  marketFee: string
  marketOwner: string
  royaltyReceivers: string[]
  royalties: string[]
}

export const instanceOfRoyaltyInfo = (royaltyInfo: any): royaltyInfo is IRoyaltyInfo => {
  return (
    'royaltyReceivers' in royaltyInfo &&
    'royalties' in royaltyInfo &&
    'marketFee' in royaltyInfo &&
    'marketOwner' in royaltyInfo
  )
}

export const instanceOfListing = (listing: any): listing is IListing => {
  if (listing === null) {
    return true
  }
  return (
    'id' in listing &&
    'itemId' in listing &&
    'collectionAddress' in listing &&
    'signHash' in listing &&
    'orderHash' in listing &&
    'accountAddress' in listing &&
    'paymentType' in listing &&
    'price' in listing &&
    'payAmount' in listing &&
    'salt' in listing &&
    'filled' in listing &&
    'maximumFill' in listing &&
    'isCanceled' in listing &&
    'makingTime' in listing &&
    'expiredAt' in listing &&
    'createdAt' in listing &&
    'counter' in listing &&
    'tradeAddress' in listing
  )
}

export const instanceOfListingItem = (listingItem: any): listingItem is IListingItem => {
  if (listingItem === null) {
    return true
  }
  return (
    'listing' in listingItem &&
    'marketFee' in listingItem &&
    'marketOwner' in listingItem &&
    'royaltyReceivers' in listingItem &&
    'royalties' in listingItem
  )
}

export const instanceOfListingItemList = (
  listingItemList: any,
): listingItemList is IListingItem[] => {
  if (listingItemList.length === 0) return true
  return listingItemList.every(
    (listing: any) =>
      'listing' in listing &&
      'marketFee' in listing &&
      'marketOwner' in listing &&
      'royaltyReceivers' in listing &&
      'royalties' in listing,
  )
}

export const instanceOfOfferItem = (offer: any): offer is IOffer => {
  if (offer === null) {
    return true
  }
  return (
    'id' in offer &&
    'itemId' in offer &&
    'collectionAddress' in offer &&
    'signHash' in offer &&
    'orderHash' in offer &&
    'accountAddress' in offer &&
    'paymentType' in offer &&
    'price' in offer &&
    'payAmount' in offer &&
    'salt' in offer &&
    'filled' in offer &&
    'maximumFill' in offer &&
    'isCanceled' in offer &&
    'makingTime' in offer &&
    'expiredAt' in offer &&
    'createdAt' in offer &&
    'counter' in offer &&
    'tradeAddress' in offer
  )
}

export const instanceOfOfferItemList = (offerItemList: any): offerItemList is IOfferItem[] => {
  if (offerItemList.length === 0) return true
  return offerItemList.every(
    (offer: any) =>
      'offer' in offer &&
      'marketFee' in offer &&
      'marketOwner' in offer &&
      'royaltyReceivers' in offer &&
      'royalties' in offer,
  )
}

export interface ILowestPriceListingResponse {
  listing: IListing | null
}

export interface IItemOfferListResponse {
  page: number
  perPage: number
  totalPage: number
  totalCount: number
  offers: IOfferItem[]
}

export interface IItemOfferListByUserResponse {
  offers: IOfferItem[]
}

export interface IHighestPriceOfferForItemResponse {
  offer: IOffer | null
}

export interface IItemListingListResponse {
  listings: IListingItem[]
}

export interface IItemInactivateListingListResponse {
  listings: IListingItem[]
}

export interface IHighestPriceOfferByUserForItemResponse {
  offer: IOffer | null
}

export interface ICancelOfferResponse {
  isCanceled: boolean
}

export interface ICancelListingResponse {
  isCanceled: boolean
}

export type TradeItemInfoForApp = {
  collectionTitle: string
  itemTitle: string
  itemImage: string
}

export type TradePriceInfoForApp = {
  price: string
  paymentType: string
}
