import dynamic from 'next/dynamic'

const AcceptFailDialogSSR = dynamic(() => import('./AcceptFailDialog'), { ssr: false })
const AcceptSummaryDialogSSR = dynamic(() => import('./AcceptSummaryDialog'), { ssr: false })
const LackOfBalanceForPurchaseDialogSSR = dynamic(
  () => import('./LackOfBalanceForPurchaseDialog'),
  {
    ssr: false,
  },
)
const ListingCancelDialogSSR = dynamic(() => import('./ListingCancelDialog'), { ssr: false })
const ListingCompleteDialogSSR = dynamic(() => import('./ListingCompleteDialog'), { ssr: false })
const ListingEditOrCancelDialogSSR = dynamic(() => import('./ListingEditOrCancelDialog'), {
  ssr: false,
})
const ListingFailDialogSSR = dynamic(() => import('./ListingFailDialog'), { ssr: false })
const ListingFormDialogSSR = dynamic(() => import('./ListingFormDialog'), { ssr: false })
const ListingSummaryDialogSSR = dynamic(() => import('./ListingSummaryDialog'), { ssr: false })
const OfferCancelDialogSSR = dynamic(() => import('./OfferCancelDialog'), { ssr: false })
const OfferCompleteDialogSSR = dynamic(() => import('./OfferCompleteDialog'), { ssr: false })
const OfferEditOrCancelDialogSSR = dynamic(() => import('./OfferEditOrCancelDialog'), {
  ssr: false,
})
const OfferFailDialogSSR = dynamic(() => import('./OfferFailDialog'), { ssr: false })
const OfferFormDialogSSR = dynamic(() => import('./OfferFormDialog'), { ssr: false })
const OfferSummaryDialogSSR = dynamic(() => import('./OfferSummaryDialog'), { ssr: false })
const PurchaseFailDialogSSR = dynamic(() => import('./PurchaseFailDialog'), { ssr: false })
const PurchaseSummaryDialogSSR = dynamic(() => import('./PurchaseSummaryDialog'), { ssr: false })
const TxProgressForAcceptDialogSSR = dynamic(() => import('./TxProgressForAcceptDialog'), {
  ssr: false,
})
const TxProgressForCancelDialogSSR = dynamic(() => import('./TxProgressForCancelDialog'), {
  ssr: false,
})
const TxProgressForPurchaseDialogSSR = dynamic(() => import('./TxProgressForPurchaseDialog'), {
  ssr: false,
})
const WalletWaitingForAcceptDialogSSR = dynamic(() => import('./WalletWaitingForAcceptDialog'), {
  ssr: false,
})
const WalletWaitingForListingCancelDialogSSR = dynamic(
  () => import('./WalletWaitingForListingCancelDialog'),
  {
    ssr: false,
  },
)
const WalletWaitingForListingDialogSSR = dynamic(() => import('./WalletWaitingForListingDialog'), {
  ssr: false,
})
const WalletWaitingForOfferCancelDialogSSR = dynamic(
  () => import('./WalletWaitingForOfferCancelDialog'),
  {
    ssr: false,
  },
)
const WalletWaitingForOfferDialogSSR = dynamic(() => import('./WalletWaitingForOfferDialog'), {
  ssr: false,
})
const WalletWaitingForPurchaseDialogSSR = dynamic(
  () => import('./WalletWaitingForPurchaseDialog'),
  {
    ssr: false,
  },
)
const WaitingForReceivedOfferedItemDialogSSR = dynamic(
  () => import('./WaitingForReceivedOfferedItemDialog'),
  {
    ssr: false,
  },
)

const UnSupportTradeDialogSSR = dynamic(() => import('./UnSupportTradeDialog'), { ssr: false })

export {
  AcceptFailDialogSSR,
  AcceptSummaryDialogSSR,
  LackOfBalanceForPurchaseDialogSSR,
  ListingCancelDialogSSR,
  ListingCompleteDialogSSR,
  ListingEditOrCancelDialogSSR,
  ListingFailDialogSSR,
  ListingFormDialogSSR,
  ListingSummaryDialogSSR,
  OfferCancelDialogSSR,
  OfferCompleteDialogSSR,
  OfferEditOrCancelDialogSSR,
  OfferFailDialogSSR,
  OfferFormDialogSSR,
  OfferSummaryDialogSSR,
  PurchaseFailDialogSSR,
  PurchaseSummaryDialogSSR,
  TxProgressForAcceptDialogSSR,
  TxProgressForCancelDialogSSR,
  TxProgressForPurchaseDialogSSR,
  UnSupportTradeDialogSSR,
  WaitingForReceivedOfferedItemDialogSSR,
  WalletWaitingForAcceptDialogSSR,
  WalletWaitingForListingCancelDialogSSR,
  WalletWaitingForListingDialogSSR,
  WalletWaitingForOfferCancelDialogSSR,
  WalletWaitingForOfferDialogSSR,
  WalletWaitingForPurchaseDialogSSR,
}
