import { useQuery } from '@tanstack/react-query'

import { IAccountItemCollectionsResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountHiddenItemCollections = async () => {
  const url = '/api/v1/private/current-account/hidden-items/collections'
  const {
    data: { data: collections },
  } = await withAxios<IBaseResponse<IAccountItemCollectionsResponse>>({ url })

  return collections
}

export const useGetCurrentAccountHiddenItemCollections = (
  pathname: string,
  accountAddress: string | null,
  enabled: boolean,
) =>
  useQuery(
    [pathname, 'getCurrentAccountHiddenItemCollections', { accountAddress }],
    getCurrentAccountHiddenItemCollections,
    {
      enabled: accountAddress !== null && enabled,
      refetchOnWindowFocus: false,
    },
  )
