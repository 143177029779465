import { TFunction } from 'i18next'

export const ORIGINALS_PAYMENT_ERROR_CODE: {
  [key: string]: (t: TFunction<[string, string, string, string], undefined>) => string
} = {
  COMMON_PAY_ERROR: t => t('common:common.error-payment'),
  COMMON_QUANTITY_LIMIT_EXCEEDED: t => t('common:common.payment-maximum-description'),
  COMMON_BREAK_TIME_OF_BANK: t => t('web-checkout:checkout.result-fail-page-description-error-201'),
  BANK_NOT_ENOUGH_CASH: t => t('web-checkout:checkout.result-fail-page-description-error-202'),
  EASY_PAYMENT_SYSTEM_MAINTENANCE: t =>
    t('web-checkout:checkout.result-fail-page-description-error-203'),
  COMMON_TIMEOUT: t => t('web-checkout:checkout.result-fail-page-description-error-204'),
  INVALID_BANK_ACCOUNT_STATUS: t =>
    t('web-checkout:checkout.result-fail-page-description-error-205'),
  ARS_ASSIGN_FAIL: t => t('web-checkout:checkout.result-fail-page-description-error-206'),
  COMMON_PAY_EXCEED_LIMIT: t => t('web-checkout:checkout.result-fail-page-description-error-207'),
  COMMON_PAY_CANCEL: t => t('web-checkout:checkout.result-fail-page-description-error-208'),
  BANK_ACCOUNT_HOLDER_VERIFICATION_REQUIRED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-209'),
  UNSUPPORTED_BANK: t => t('web-checkout:checkout.result-fail-page-description-error-210'),
  COMMON_INVALID_PASSWORD: t => t('before-auth:before-auth.password-incorrect'),
  COMMON_INVALID_RRN_OR_BRN: t => t('web-checkout:checkout.result-fail-page-description-error-212'),
  COMMON_INTERNAL_SYSTEM_ERROR: t =>
    t('web-checkout:checkout.result-fail-page-description-error-402'),
  REFUND_IN_PROGRESS: t => t('web-checkout:checkout.result-fail-page-description-error-401'),
  COMMON_SYSTEM_ERROR: t => t('web-checkout:checkout.result-fail-page-description-error-215'),
  CARD_PAY_LIMIT_EXCEEDED: t => t('web-checkout:checkout.result-fail-page-description-error-217'),
  CARD_INSTALLMENT_MONTHS_EXCEEDED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-218'),
  CARD_EXPIRATION_DATE_ERROR: t =>
    t('web-checkout:checkout.result-fail-page-description-error-219'),
  PAY_CANCEL_ITS_NOT_CANCELABLE_STATUS: t =>
    t('web-checkout:checkout.result-fail-page-description-error-404'),
  CARD_PERSONAL_CANCEL_LIMIT_EXCEEDED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-222'),
  CARD_BUSINESS_CANCEL_LIMIT_EXCEEDED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-223'),
  CARD_TIMEOUT_ERROR: t => t('web-checkout:checkout.result-fail-page-description-error-224'),
  CARD_PASSWORD_ERROR: t => t('web-checkout:checkout.result-fail-page-description-error-221'),
  COMMON_PAYMENT_DUPLICATION: t =>
    t('web-checkout:checkout.result-fail-page-description-error-220'),
  REFUND_ERROR: t => t('web-checkout:checkout.result-fail-page-description-error-227'),
  CARD_NOT_ENOUGH_CASH: t => t('web-checkout:checkout.result-fail-page-description-error-214'),
  BANK_POLICY_FAILURE: t => t('web-checkout:checkout.result-fail-page-description-error-216'),
  CARD_EXISTING_PAYMENT: t => t('web-checkout:checkout.result-fail-page-description-error-220'),
  BANK_SYSTEM_ERROR: t => t('web-checkout:checkout.result-fail-page-description-error-215'),
  ALREADY_PAID_ORD_NO: t => t('web-checkout:checkout.result-fail-page-description-error-220'),
  FORGERY_PAYMENT: t => t('web-checkout:checkout.result-fail-page-description-error-303'),
  NOT_FOUND_CHECKOUT: t => t('web-checkout:checkout.result-fail-page-description-error-306'),
  REFUND_ALREADY_REFUNDED: t => t('web-checkout:checkout.result-fail-page-description-error-402'),
  PARTIAL_REFUND_NOT_ALLOWED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-405'),
  COMMON_LABEL_PURCHASE_FAILED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-age'),
  SOLD_OUT: t => t('web-checkout:checkout.result-fail-page-description-error-227'),
  MAX_QUANTITY_PER_WALLET_EXCEEDED: t =>
    t('web-checkout:checkout.result-fail-page-description-error-308'),
  MAX_QUANTITY_PER_PURCHASE_EXCEEDED: t => t('common.common.payment-maximum-description'),
}
