import { useQueries, useQuery } from '@tanstack/react-query'

import { IItemOfferListByUserResponse, IOfferItem } from '@/shared/types'

import withAxios from '../api'

export const getOfferList = async (itemId?: string) => {
  const {
    data: { offers },
  } = await withAxios<IItemOfferListByUserResponse>({
    url: `/api/v1/private/items/${itemId?.toLowerCase()}/offers`,
    method: 'GET',
  })
  return offers
}

export const useGetOfferList = (
  pathname: string,
  accountAddress: string | null,
  itemId?: string,
) => {
  return useQuery(
    [pathname, 'getOfferList', { accountAddress, itemId }],
    () => getOfferList(itemId),
    { enabled: itemId !== undefined && accountAddress !== null },
  )
}

export const useGetOfferListOfItems = (
  pathname: string,
  accountAddress: string | null,
  itemIdList: string[],
) => {
  return useQueries({
    queries: itemIdList.map(itemId => ({
      queryKey: [pathname, 'getOfferList', { itemId }],
      queryFn: () => getOfferList(itemId),
      enabled: itemId !== undefined && accountAddress !== null,
      select: (data: IOfferItem[]) => ({
        itemId,
        offerList: data,
      }),
    })),
  })
}
