import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getCollectionStats } from '@/shared/services/collections'
import { getInactiveListingList } from '@/shared/services/itemOrder'
import {
  CustomError,
  ICollectionStats,
  IListingItem,
  instanceOfCollectionStats,
  instanceOfListingItemList,
  IOfferItem,
} from '@/shared/types'
import { getSnapshotInError } from '@/shared/utils'

import { AppState } from '..'

interface IAcceptItemInfo {
  itemId: string
  collectionAddress: string
  tokenId: number | string
  collectionTitle: string
  itemTitle: string
  mediaUrl: string
  collectionStats: ICollectionStats | null
  offerItem: IOfferItem | null
  inactiveListingList: IListingItem[] | null
  chainId: string
}

export interface IAcceptState {
  acceptItemInfo: IAcceptItemInfo | null

  isFetchingAcceptItemInfo: boolean
  isFetchingAcceptItemInfoError: string | null

  isAcceptSigning: boolean
  isAcceptSuccessful: boolean

  txHashForAccept: string | null
  isAcceptFailedForUserDenied: boolean
  isAcceptError: string | null
}

const initialState: IAcceptState = {
  acceptItemInfo: null,
  isFetchingAcceptItemInfo: false,
  isFetchingAcceptItemInfoError: null,

  isAcceptSigning: false,
  isAcceptSuccessful: false,

  txHashForAccept: null,
  isAcceptFailedForUserDenied: false,
  isAcceptError: null,
}

export const getAcceptItemInfo = createAsyncThunk(
  'accept/getAcceptItemInfo',
  async ({
    itemId,
    collectionAddress,
    tokenId,
    collectionTitle,
    itemTitle,
    mediaUrl,
    offerItem,
    chainId,
  }: {
    itemId: string
    collectionAddress: string
    tokenId: number | string
    collectionTitle: string
    itemTitle: string
    mediaUrl: string
    offerItem: IOfferItem
    chainId: string
  }) => {
    const promises = [getCollectionStats(collectionAddress), getInactiveListingList(itemId)]
    try {
      const [collectionStats, inactiveListingList] = await Promise.all(promises)

      return {
        itemId,
        collectionAddress,
        tokenId,
        collectionTitle,
        itemTitle,
        mediaUrl,
        chainId,
        collectionStats: instanceOfCollectionStats(collectionStats) ? collectionStats : null,
        inactiveListingList: instanceOfListingItemList(inactiveListingList)
          ? inactiveListingList
          : null,
        offerItem,
      }
    } catch (e: unknown) {
      return Promise.reject(e)
    }
  },
)

export const acceptSlice = createSlice({
  name: 'accept',
  initialState,
  reducers: {
    resetAcceptState: state => {
      state.acceptItemInfo = null
      state.isFetchingAcceptItemInfo = false
      state.isFetchingAcceptItemInfoError = null
      state.isAcceptSigning = false
      state.isAcceptSuccessful = false
      state.isAcceptError = null
      state.txHashForAccept = null
      state.isAcceptFailedForUserDenied = false
    },
    setAcceptItemInfo: (state, action: PayloadAction<IAcceptItemInfo | null>) => {
      state.acceptItemInfo = action.payload
    },
    setIsAcceptSigning(state, action: PayloadAction<boolean>) {
      state.isAcceptSigning = action.payload
    },
    setIsAcceptSuccessful(state, action: PayloadAction<boolean>) {
      state.isAcceptSuccessful = action.payload
    },
    setIsAcceptError(state, action: PayloadAction<string | null>) {
      state.isAcceptError = action.payload
      if (action.payload !== null) {
        const snapshotInError = getSnapshotInError({
          ...(typeof window !== 'undefined' ? { pathname: window.location.pathname } : {}),
          extra: {
            acceptState: state,
          },
        })
        new CustomError({
          name: 'ACCEPT_OFFER_ERROR',
          message: action.payload,
        }).sendToSentry(snapshotInError)
      }
    },
    setTxHashForAccept(state, action: PayloadAction<string | null>) {
      state.txHashForAccept = action.payload
    },
    setIsAcceptFailedForUserDenied(state, action: PayloadAction<boolean>) {
      state.isAcceptFailedForUserDenied = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getAcceptItemInfo.pending, state => {
      state.acceptItemInfo = null
      state.isFetchingAcceptItemInfo = true
      state.isFetchingAcceptItemInfoError = null
    })
    builder.addCase(getAcceptItemInfo.fulfilled, (state, action) => {
      state.acceptItemInfo = action.payload
      state.isFetchingAcceptItemInfo = false
    })
    builder.addCase(getAcceptItemInfo.rejected, (state, action) => {
      state.isFetchingAcceptItemInfo = false
      if (action.error.message) {
        state.isFetchingAcceptItemInfoError = action.error.message
      }
    })
  },
})

export const selectAcceptItemInfo = (state: AppState) => state.accept.acceptItemInfo
export const selectIsFetchingAcceptItemInfo = (state: AppState) =>
  state.accept.isFetchingAcceptItemInfo
export const selectIsFetchingAcceptInfoError = (state: AppState) =>
  state.accept.isFetchingAcceptItemInfoError
export const selectIsAcceptError = (state: AppState) => state.accept.isAcceptError

export const selectTxHashForAccept = (state: AppState) => state.accept.txHashForAccept

export const selectAcceptStatus = (state: AppState) => ({
  isAcceptSuccessful: state.accept.isAcceptSuccessful,
  isAcceptSigning: state.accept.isAcceptSigning,
  isAcceptError: state.accept.isAcceptError,
  isAcceptFailedForUserDenied: state.accept.isAcceptFailedForUserDenied,
})

export const {
  resetAcceptState,
  setAcceptItemInfo,
  setIsAcceptError,
  setIsAcceptFailedForUserDenied,
  setIsAcceptSigning,
  setIsAcceptSuccessful,
  setTxHashForAccept,
} = acceptSlice.actions

export default acceptSlice
