import { TokenInfo } from '@uniswap/widgets'

import { CHAIN_ID } from '@/shared/constants/chain'

import {
  DEV_WETH_ADDRESS,
  DEV_WPOL_ADDRESS,
  PROD_WETH_ADDRESS,
  PROD_WPOL_ADDRESS,
} from '../constants/tokenAddress'
import { getEnvValue } from './getEnvValue'

export const getSwapTokenList = (chainId: string): TokenInfo[] | null => {
  const isDev = getEnvValue('mode') === 'development'

  const polygonTokenInfoList: TokenInfo[] = [
    {
      name: 'Wrapped POL',
      address: isDev ? DEV_WPOL_ADDRESS : PROD_WPOL_ADDRESS,
      symbol: 'WPOL',
      decimals: 18,
      chainId: Number(chainId),
      logoURI:
        'https://assets-cdn.trustwallet.com/blockchains/polygon/assets/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png',
    },
  ]

  const ethereumTokenInfoList: TokenInfo[] = [
    {
      name: 'Wrapped Ether',
      address: isDev ? DEV_WETH_ADDRESS : PROD_WETH_ADDRESS,
      symbol: 'WETH',
      decimals: 18,
      chainId: Number(chainId),
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
  ]

  return CHAIN_ID.amoy || chainId === CHAIN_ID.polygon
    ? polygonTokenInfoList
    : ethereumTokenInfoList
}
