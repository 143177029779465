import {
  Action,
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
} from '@reduxjs/toolkit'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import logger from 'redux-logger'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import thunk from 'redux-thunk'

import { bookEventSlice, IBookEventState } from './bookEvent'
import { dialogSlice, IDialogState } from './dialog'
import { benefitsSlice, IBenefitsState } from './market'
import { persistConfig } from './middleware'
import { IOfferedItemIds, offeredItemIdsSlice } from './offer'
import { IOriginalsState, originalsSlice } from './originals'
import { ISearchState, searchSlice } from './search'
import {
  acceptSlice,
  cancelSlice,
  contractAddressSlice,
  highestOfferSlice,
  IAcceptState,
  ICancelState,
  IContractAddressState,
  IHighestOfferState,
  IListingState,
  IOfferState,
  IPurchaseState,
  ISignatureState,
  ITransactionScopeState,
  listingSlice,
  offerSlice,
  purchaseSlice,
  signatureSlice,
  transactionScopeSlice,
} from './trades'
import { ITransferState, transferSlice } from './transfer'
import {
  currencySlice,
  ICurrencyState,
  ISwapState,
  IWalletState,
  swapSlice,
  walletSlice,
} from './wallet'

export interface IReducerStates {
  [currencySlice.name]: ICurrencyState
  [walletSlice.name]: IWalletState
  [acceptSlice.name]: IAcceptState
  [cancelSlice.name]: ICancelState
  [contractAddressSlice.name]: IContractAddressState
  [listingSlice.name]: IListingState
  [offerSlice.name]: IOfferState
  [originalsSlice.name]: IOriginalsState
  [purchaseSlice.name]: IPurchaseState
  [signatureSlice.name]: ISignatureState
  [transferSlice.name]: ITransferState
  [highestOfferSlice.name]: IHighestOfferState
  [dialogSlice.name]: IDialogState
  [searchSlice.name]: ISearchState
  [benefitsSlice.name]: IBenefitsState
  [swapSlice.name]: ISwapState
  [offeredItemIdsSlice.name]: IOfferedItemIds
  [transactionScopeSlice.name]: ITransactionScopeState
  [bookEventSlice.name]: IBookEventState
}

const rootReducer = (state: IReducerStates, action: AnyAction): CombinedState<IReducerStates> => {
  switch (action.type) {
    case HYDRATE:
      return {
        state,
        ...action.payload,
      }

    default: {
      const combinedReducer = combineReducers({
        [currencySlice.name]: currencySlice.reducer,
        [walletSlice.name]: walletSlice.reducer,
        [acceptSlice.name]: acceptSlice.reducer,
        [cancelSlice.name]: cancelSlice.reducer,
        [contractAddressSlice.name]: contractAddressSlice.reducer,
        [listingSlice.name]: listingSlice.reducer,
        [offerSlice.name]: offerSlice.reducer,
        [originalsSlice.name]: originalsSlice.reducer,
        [purchaseSlice.name]: purchaseSlice.reducer,
        [signatureSlice.name]: signatureSlice.reducer,
        [transferSlice.name]: transferSlice.reducer,
        [highestOfferSlice.name]: highestOfferSlice.reducer,
        [dialogSlice.name]: dialogSlice.reducer,
        [searchSlice.name]: searchSlice.reducer,
        [benefitsSlice.name]: benefitsSlice.reducer,
        [swapSlice.name]: swapSlice.reducer,
        [offeredItemIdsSlice.name]: offeredItemIdsSlice.reducer,
        [transactionScopeSlice.name]: transactionScopeSlice.reducer,
        [bookEventSlice.name]: bookEventSlice.reducer,
      })
      return combinedReducer(state, action)
    }
  }
}

const makeConfiguredStore = (
  reducer: Reducer<IReducerStates | (IReducerStates & PersistPartial), AnyAction>,
) =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })

      if (process.env.NODE_ENV === 'development') {
        middleware.concat(logger)
      }
      return middleware.concat(thunk)
    },
    devTools: process.env.NODE_ENV === 'development',
  })

const makeStore = () => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    return makeConfiguredStore(rootReducer as Reducer<IReducerStates, AnyAction>)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { persistStore, persistReducer } = require('redux-persist')

    const persistedReducer = persistReducer(
      persistConfig,
      rootReducer as Reducer<IReducerStates, AnyAction>,
    )
    const store = makeConfiguredStore(persistedReducer)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.__persistor = persistStore(store)

    return store
  }
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>

const wrapper = createWrapper<AppStore>(makeStore, {
  debug: process.env.NODE_ENV === 'development',
})

export default wrapper
