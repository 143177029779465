import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountItemsDebouncedQueryParams,
  IAccountItemsResponse,
  IBaseResponse,
  IGetItemListRequest,
} from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountItems = async (
  accountAddress?: string,
  params?: IGetItemListRequest,
) => {
  const url = `/api/v1/public/accounts/${accountAddress}/items`
  const { data } = await withAxios<IBaseResponse<IAccountItemsResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetPublicAccountItems = (
  pathname: string,
  refresh: boolean,
  accountAddress?: string,
  debouncedQueryParams?: IAccountItemsDebouncedQueryParams,
) =>
  useInfiniteQuery<IAccountItemsResponse>(
    [pathname, 'getPublicAccountItems', { debouncedQueryParams, accountAddress }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 10,
        refresh,
      } as IGetItemListRequest
      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }
      const { data } = await getPublicAccountItems(accountAddress, queryParams)
      return data
    },
    {
      enabled: accountAddress !== undefined,
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      suspense: true,
      useErrorBoundary: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
