import { useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  selectIsConnectedWallet,
  setIsRequestDisconnectWallet,
  setIsWalletConnecting,
} from '../store/wallet'
import { sendMessageToApp } from '../utils'
import { useEventListener } from './useEventListener'

export const useGetLoginCode: () => {
  data: string
  isError: boolean
  requestCodeForLogin: () => void
  isRequestingCodeForLogin: boolean
  resetState: () => void
} = () => {
  const dispatch = useAppDispatch()

  const isConnectedWallet = useAppSelector(selectIsConnectedWallet)

  const [data, setData] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [isRequestingCodeForLogin, setIsRequestingCodeForLogin] = useState(false)

  const requestCodeForLogin = () => {
    setIsRequestingCodeForLogin(true)
    dispatch(setIsRequestDisconnectWallet(false))
    sendMessageToApp<string>('Message', 'RequestCodeForLogin')
  }

  const resetState = useCallback(() => {
    setData('')
    setIsError(false)
    setIsRequestingCodeForLogin(false)
    dispatch(setIsWalletConnecting(false))
  }, [dispatch])

  useEffect(() => {
    if (isConnectedWallet) {
      resetState()
    }
  }, [isConnectedWallet, resetState])

  const handleListener = useCallback((event: any) => {
    try {
      const { type, data } = JSON.parse(event.data)

      if (type === 'CodeForLogin') {
        setData(data)
        setIsRequestingCodeForLogin(false)
        dispatch(setIsWalletConnecting(false))
        clearEventListener()
      }

      if (type === 'ErrorCodeForLogin') {
        setIsError(true)
        setIsRequestingCodeForLogin(false)
        dispatch(setIsWalletConnecting(false))
        clearEventListener()
      }
    } catch (error) {
      resetState()
      clearEventListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { setEventListener, clearEventListener } = useEventListener(handleListener)

  useEffect(() => {
    if (isRequestingCodeForLogin) {
      setEventListener()
    } else {
      clearEventListener()
    }
  }, [clearEventListener, isRequestingCodeForLogin, handleListener, setEventListener])

  return { data, isError, isRequestingCodeForLogin, requestCodeForLogin, resetState }
}
