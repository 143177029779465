import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HighestOfferForItem } from '@/shared/types'

import { AppState } from '..'

export interface IHighestOfferState {
  highestOffers: HighestOfferForItem
}

const initialState: IHighestOfferState = {
  highestOffers: {},
}

export const highestOfferSlice = createSlice({
  name: 'highestOffer',
  initialState,
  reducers: {
    reset: state => {
      state.highestOffers = {}
    },
    mergeData: (state, action: PayloadAction<HighestOfferForItem>) => {
      Object.keys(action.payload).forEach(key => {
        state.highestOffers[key] = action.payload[key]
      })
    },
  },
})

export const selectHightestOffers = (state: AppState) => state.highestOffer.highestOffers

export const { reset, mergeData } = highestOfferSlice.actions

export default highestOfferSlice
