import { MutableRefObject, useEffect } from 'react'

type Param = {
  targetRef: MutableRefObject<HTMLElement | null>
  onIntersect?: () => void
  root?: MutableRefObject<HTMLElement | null>
  enabled?: boolean
  options?: IntersectionObserverInit
}

const useIntersectionObserver = ({
  targetRef,
  root,
  onIntersect,
  enabled = true,
  options,
}: Param) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const targetElement = targetRef.current
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) =>
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            onIntersect?.()
          }
        }),
      {
        threshold: options?.threshold ?? 0.0001,
        ...(root?.current && { root: root?.current }),
      },
    )

    targetElement && observer.observe(targetElement)

    return () => {
      targetElement && observer.unobserve(targetElement)
    }
  }, [targetRef, enabled, options, root, onIntersect])
}

export default useIntersectionObserver
