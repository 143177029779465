import { useQuery } from '@tanstack/react-query'

import { IAccountItemCollectionsResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountLikedItemCollections = async (accountAddress?: string) => {
  const url = `/api/v1/public/accounts/${accountAddress}/like-items/collections`
  const {
    data: { data: collections },
  } = await withAxios<IBaseResponse<IAccountItemCollectionsResponse>>({ url, method: 'GET' })

  return collections
}

export const useGetPublicAccountLikedItemCollections = (
  pathname: string,
  enabled: boolean,
  accountAddress?: string,
) =>
  useQuery(
    [pathname, 'getPublicAccountLikedItemCollections', { accountAddress }],
    () => getPublicAccountLikedItemCollections(accountAddress),
    { enabled: accountAddress !== undefined && enabled, refetchOnWindowFocus: false },
  )
