import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IBaseResponse, IHiddenItemResponse } from '@/shared/types'

import withAxios from '../api'

export const hideItem = (itemId: string) => {
  return withAxios<IBaseResponse<IHiddenItemResponse>>({
    url: `/api/v1/private/items/${itemId.toLowerCase()}/hidden`,
    method: 'POST',
  })
}

export const useHideItem = (pathname: string, itemId: string) => {
  const { t } = useTranslation('web-current-account')

  const queryClient = useQueryClient()
  return useMutation(['hide'], () => hideItem(itemId), {
    onSuccess: () => {
      toast(t('web-current-account:current-account.toast-hide-nft'))
      queryClient.invalidateQueries([pathname])
    },
  })
}
