import { useQuery } from '@tanstack/react-query'

import { IOriginalsOptions } from '@/shared/types'

import withAxios from '../api'

export const getCheckoutOptionList = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/v3/public/kkr-originals/originals/${originalsId}/options`

  const { data } = await withAxios<IOriginalsOptions>({
    url,
  })

  return data
}

export const useGetCheckoutOptionList = (originalsId?: string, isSeatingItem?: boolean) =>
  useQuery(
    ['useGetCheckoutOptionList', { originalsId }],
    () => getCheckoutOptionList({ originalsId }),
    {
      enabled: originalsId !== undefined && !isSeatingItem,
      refetchOnWindowFocus: false,
    },
  )
