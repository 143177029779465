import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

import { TradeType } from '../types'
import { differentDateTime } from '../utils/timeFormatter'

interface IUseTimeProps {
  t: (lng: string) => string
  tradeType?: TradeType
  size?: 'sm' | 'lg'
}

export default function useTime({ t, tradeType = 'LISTING', size = 'sm' }: IUseTimeProps) {
  const messageMappings: { [tradeType: string]: { sm: string; lg: string } } = useMemo(
    () => ({
      LISTING: {
        sm: `${t('common:common.label-expired')}`,
        lg: `${t('common:common.label-expired-sales-period')}`,
      },
      OFFER: {
        sm: `${t('common:common.label-expired')}`,
        lg: `${t('common:common.label-expired-offer')}`,
      },
    }),
    [t],
  )

  const getExpireTime = useCallback(
    (time: string, t: (lng: string) => string): { isExpired: boolean; message: string } => {
      if (dayjs().isAfter(dayjs(time))) {
        return {
          isExpired: true,
          message: messageMappings[tradeType][size],
        }
      }

      return {
        isExpired: false,
        message: differentDateTime({ itemDateTime: time, t }),
      }
    },
    [messageMappings, size, tradeType],
  )

  return { getExpireTime }
}
