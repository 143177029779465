import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query'

import { IBaseResponse, IOriginalsHomeCategoryType, IPagination } from '@/shared/types'

import withAxios from '../api'

interface IGetOriginalsHomeCategoryItemListRequest {
  page: any
  perPage: number
  categoryId: string | undefined
}
export interface IOriginalsHomeCategoryItemListResponse extends IPagination {
  originalsCollection: IOriginalsHomeCategoryType[]
  whiteListOriginals: IOriginalsHomeCategoryType[]
}

export const getOriginalsHomeCategoryItemList = async (
  params: IGetOriginalsHomeCategoryItemListRequest,
) => {
  const url = '/api/v2/public/originals'
  const { data } = await withAxios<IBaseResponse<IOriginalsHomeCategoryItemListResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetOriginalsHomeCategoryItemList = (
  pathname: string,
  categoryId?: string,
): UseInfiniteQueryResult<IOriginalsHomeCategoryItemListResponse> =>
  useInfiniteQuery(
    [pathname, 'getOriginalsHomeCategoryItemList', { categoryId }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        page: pageParam,
        perPage: 5,
        categoryId,
      }
      const response = await getOriginalsHomeCategoryItemList(queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data =>
        (data.originalsCollection.length === 5 && data.page + 1) ?? undefined,
      keepPreviousData: true,
      suspense: true,
      useErrorBoundary: true,
      enabled: categoryId !== undefined,
    },
  )
