import { ERC721__factory } from '@modernlion/marketplace-registry'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getCurrentAccountTransferStatus } from '@/shared/services/accounts'
import { getWeb3Provider } from '@/shared/services/ethers'

import { AppState, AppThunk } from '..'

export interface ITransferItemInfo {
  collectionTitle: string
  itemTitle: string
  itemImage: string
  collectionAddress: string
  tokenId: string
  chainId: string
}

export interface ITransferStatusInfo {
  isHidden: boolean
  isListing: boolean
}

export interface ITransferState {
  transferItemInfo: ITransferItemInfo | null

  receivingAddress: string | null
  isSucceed: boolean
  isTransferLoading: boolean
  isTransferError: string | null
  isTransferFailedForUserDenied: boolean

  txHashForTransfer: string | null

  transferStatusInfo: ITransferStatusInfo | null
}

export const initialState: ITransferState = {
  transferItemInfo: null,
  receivingAddress: null,
  isSucceed: false,
  isTransferLoading: false,
  isTransferError: null,
  isTransferFailedForUserDenied: false,
  txHashForTransfer: null,
  transferStatusInfo: null,
}

export const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    resetTransferState: state => {
      state.transferItemInfo = null
      state.receivingAddress = null
      state.isSucceed = false
      state.isTransferLoading = false
      state.isTransferError = null
      state.isTransferFailedForUserDenied = false
      state.txHashForTransfer = null
      state.transferStatusInfo = null
    },
    setTransferItemInfo: (state, action: PayloadAction<ITransferItemInfo | null>) => {
      state.transferItemInfo = action.payload
    },
    setReceivingAddress: (state, action) => {
      state.receivingAddress = action.payload
    },
    setIsSucceed: (state, action) => {
      state.isSucceed = action.payload
    },
    setIsTransferLoading: (state, action) => {
      state.isTransferLoading = action.payload
    },
    setIsTransferError: (state, action) => {
      state.isTransferError = action.payload
    },
    setIsTransferFailedForUserDenied: (state, action) => {
      state.isTransferFailedForUserDenied = action.payload
    },
    setTxHashForTransfer: (state, action) => {
      state.txHashForTransfer = action.payload
    },
    setTransferStatusInfo: (state, action) => {
      state.transferStatusInfo = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getTransferStatus.fulfilled, (state, action) => {
      state.transferStatusInfo = action.payload
    })
  },
})

export const selectTransferItemInfo = (state: AppState) => state.transfer.transferItemInfo
export const selectReceivingAddress = (state: AppState) => state.transfer.receivingAddress
export const selectIsTransferError = (state: AppState) => state.transfer.isTransferError
export const selectIsTransferFailedForUserDenied = (state: AppState) =>
  state.transfer.isTransferFailedForUserDenied
export const selectTransferStatus = (state: AppState) => ({
  isSucceed: state.transfer.isSucceed,
  isLoading: state.transfer.isTransferLoading,
  isTransferError: state.transfer.isTransferError,
  isTransferFailedForUserDenied: state.transfer.isTransferFailedForUserDenied,
})
export const selectTxHashForTransfer = (state: AppState) => state.transfer.txHashForTransfer
export const selectTransferStatusInfo = (state: AppState) => state.transfer.transferStatusInfo

export const {
  resetTransferState,
  setIsSucceed,
  setIsTransferError,
  setIsTransferFailedForUserDenied,
  setIsTransferLoading,
  setReceivingAddress,
  setTransferItemInfo,
  setTxHashForTransfer,
  setTransferStatusInfo,
} = transferSlice.actions

export const getTransferStatus = createAsyncThunk(
  'transfer/getCurrentAccountTransferStatus',
  async ({ itemId }: { itemId: string }) => {
    const response = await getCurrentAccountTransferStatus(itemId)

    try {
      return { isHidden: response.isHidden, isListing: response.isListing }
    } catch (e: unknown) {
      return Promise.reject(e)
    }
  },
)

export const transfer = (): AppThunk => async (dispatch, getState) => {
  const currentAccountAddress = getState().wallet.currentProfile?.accountAddress ?? null
  const transferItemInfo = getState().transfer.transferItemInfo
  const receivingAddress = getState().transfer.receivingAddress

  if (currentAccountAddress === null) {
    return dispatch(setIsTransferError('No current account address'))
  }

  if (receivingAddress === null) {
    return dispatch(setIsTransferError('No receiving address'))
  }

  if (transferItemInfo === null) {
    return dispatch(setIsTransferError('No transfer item info'))
  }

  dispatch(setIsTransferLoading(true))

  try {
    const web3Provider = getWeb3Provider(transferItemInfo.chainId)
    if (web3Provider instanceof Error) return web3Provider

    const signer = web3Provider.getSigner(currentAccountAddress)

    const tx = await ERC721__factory.connect(
      transferItemInfo.collectionAddress,
      signer,
    ).transferFrom(currentAccountAddress, receivingAddress, transferItemInfo.tokenId)

    dispatch(setTxHashForTransfer(tx.hash))

    const receipt = await tx.wait()

    if (receipt.status === 1) {
      dispatch(setIsSucceed(true))
    } else {
      dispatch(setIsTransferError('Transaction failed'))
    }
  } catch (e: any) {
    if (e.code === 4001) dispatch(setIsTransferFailedForUserDenied(true))
    else dispatch(setIsTransferError(e.message))
  } finally {
    dispatch(setIsTransferLoading(false))
  }
}

export const transferDialogOpen =
  ({
    collectionTitle,
    itemTitle,
    itemImage,
    collectionAddress,
    tokenId,
    chainId,
    handlePreparingToOpenDialog,
    handleTransferFormDialog,
  }: {
    collectionTitle: string
    itemTitle: string
    itemImage: string
    collectionAddress: string
    tokenId: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleTransferFormDialog: (transferFormDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetTransferState())

    handlePreparingToOpenDialog(true)

    await dispatch(getTransferStatus({ itemId: `${collectionAddress}-${tokenId}` }))

    dispatch(
      setTransferItemInfo({
        collectionAddress,
        tokenId,
        collectionTitle,
        itemTitle,
        itemImage,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleTransferFormDialog(true)
  }

export const retryTransfer = (): AppThunk => async dispatch => {
  dispatch(setIsTransferError(null))
  dispatch(setIsTransferFailedForUserDenied(false))
  dispatch(transfer())
}

export default transferSlice
