import { useCallback, useEffect, useRef, useState } from 'react'

import { ManagedDialog, WAITING_ORDER_ACTION } from '../constants'
import { getWaitingRoom } from '../services/websocket'
import { CustomError, IConnectionWebSocketResponse } from '../types'
import { LocalStorage } from '../utils'
import { useDialog } from './useDialog'
import { useGetSnapshotInError } from './useGetSnapshotInError'

export const useWaitingRoom = () => {
  const { setDialog } = useDialog()

  const { getCurrentSnapshotInError } = useGetSnapshotInError()

  const [waitingRoomInfo, setWaitingRoomInfo] = useState<IConnectionWebSocketResponse | null>(null)

  const connectionId = useRef<string | null>(null)

  const [count, setCount] = useState<number>(0)
  const [estimateSec, setEstimateSec] = useState<number | null>(null)
  const timer = useRef<NodeJS.Timeout>()

  const nextActionRef = useRef<(() => void) | null>(null)

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current)
      timer.current = undefined
    }
  }, [])

  const decreaseCount = useCallback(() => {
    setCount(prevCount => {
      const throughPutCount = waitingRoomInfo?.throughPutCount ?? 15
      const newCount = prevCount - throughPutCount
      return newCount >= 0 ? newCount : 0
    })

    setEstimateSec(prevEstimateSec =>
      prevEstimateSec && prevEstimateSec - 1 >= 0 ? prevEstimateSec - 1 : 0,
    )
  }, [waitingRoomInfo?.throughPutCount])

  const startTimer = useCallback(
    (totalCount: number, estimateSec: number) => {
      if (timer.current) return

      setCount(totalCount)
      setEstimateSec(estimateSec)
      timer.current = setInterval(decreaseCount, 1000)
    },
    [decreaseCount],
  )

  const clearState = useCallback(() => {
    setCount(0)
    setEstimateSec(null)
    stopTimer()
    setWaitingRoomInfo(() => null)
    setDialog(null)
  }, [setDialog, stopTimer])

  useEffect(() => {
    const executeRoutingAndClearState = async () => {
      if (
        estimateSec === 0 && // 초기값이 null이기 때문에 0이라는건 시간이 다 되었다는 의미
        typeof nextActionRef.current === 'function'
      ) {
        await nextActionRef.current() // 라우팅 함수 실행
        nextActionRef.current = null
        LocalStorage.setItem('connectionId', 'pass')
      }
    }

    executeRoutingAndClearState()
  }, [estimateSec])

  console.log('estimateSec: ', estimateSec)

  const connectWaitingRoom = useCallback(
    (url: string, action: keyof typeof WAITING_ORDER_ACTION) => {
      const waitingRoomSocket = new WebSocket(url)

      waitingRoomSocket.addEventListener('open', () => {
        waitingRoomSocket.send(JSON.stringify({ action: WAITING_ORDER_ACTION[action] }))
      })
      waitingRoomSocket.addEventListener('message', event => {
        const dataFromServer = JSON.parse(event.data)
        if (typeof dataFromServer.totalCount === 'number') {
          setWaitingRoomInfo(dataFromServer)
          setDialog(ManagedDialog.waitingOrder)
        }
        //entry를 받았을 때
        if (dataFromServer.action === WAITING_ORDER_ACTION.entry) {
          console.log('엔트리')
          if (typeof dataFromServer.connectionId === 'string') {
            connectionId.current = dataFromServer.connectionId
            LocalStorage.setItem('connectionId', dataFromServer.connectionId)
            if (typeof nextActionRef.current === 'function') {
              nextActionRef.current()
              nextActionRef.current = null
            }
          }
        }
      })
    },
    [setDialog],
  )

  const handleRequestGetWaitingRoom = useCallback(
    async (action: keyof typeof WAITING_ORDER_ACTION) => {
      try {
        connectionId.current = null
        LocalStorage.removeItem('connectionId')
        clearState()
        const waitingRoomInfo = await getWaitingRoom()
        connectWaitingRoom(waitingRoomInfo, action)
      } catch (e: unknown) {
        const snapshotInError = getCurrentSnapshotInError()
        if (e instanceof Error) {
          new CustomError({
            name: 'GET_WAITING_ROOM_ERROR',
            message: '대기룸 정보를 가져오는데 실패했습니다.',
          }).sendToSentry(snapshotInError)
        }
      }
    },
    [clearState, connectWaitingRoom, getCurrentSnapshotInError],
  )

  return {
    handleRequestGetWaitingRoom,
    waitingRoomInfo,
    setWaitingRoomInfo,
    nextActionRef,
    startTimer,
    stopTimer,
    count,
    estimateSec,
    connectionId,
    clearState,
  }
}
