import { useDebouncedValue } from '@mantine/hooks'
import { useCallback, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { collectionActivityFilterSlice, collectionActivityFilterState } from '../store/filters'
import { ActivityFilterType, ISortOption } from '../types'

export const useCollectionActivityFilter = () => {
  const { t } = useTranslation('common')
  const [isFilterVisible, setIsFilterVisible] = useState(false)

  const [state, dispatch] = useReducer(
    collectionActivityFilterSlice.reducer,
    collectionActivityFilterState,
  )

  const { initCollectionActivityFilter, setIsClickChip, setTradeTypes, setOrderAndOrderBy } =
    collectionActivityFilterSlice.actions

  const memorizedQueryParams = useMemo(() => {
    const { tradeTypes, order, orderBy } = state
    const queryString = {
      order,
      orderBy,
      ...(tradeTypes.length > 0
        ? {
            tradeTypes: tradeTypes.filter(Boolean).join(',').trim(),
          }
        : {}),
    }
    return queryString
  }, [state])

  const [debouncedQueryParams] = useDebouncedValue(memorizedQueryParams, 200)

  const [filterQueryParams, setFilterQueryParams] = useState(debouncedQueryParams)

  const sortOptions: ISortOption[] = [
    { text: t('common.label-list-activity-recent'), order: 'createdAt', orderBy: 'DESC' },
    { text: t('common.label-list-activity-oldest'), order: 'createdAt', orderBy: 'ASC' },
  ]

  const [isClickSort, setIsClickSort] = useState(false)

  const createFilteredTradeTypes = useCallback(
    (filterType: ActivityFilterType): ActivityFilterType[] => {
      const tradeTypes = state.tradeTypes
      let selectedFilters: ActivityFilterType[]

      const typeIndex = tradeTypes.indexOf(filterType)
      if (typeIndex < 0) {
        selectedFilters = [...tradeTypes, filterType]
      } else {
        selectedFilters = [...tradeTypes.slice(0, typeIndex), ...tradeTypes.slice(typeIndex + 1)]
      }

      return selectedFilters
    },
    [state.tradeTypes],
  )

  const handleOrderAndSortBy = useCallback(
    ({ order, orderBy }: { order: string; orderBy: string }) => {
      if (state.orderBy !== orderBy) {
        setIsClickSort(true)
      } else {
        setIsClickSort(false)
      }

      dispatch(setOrderAndOrderBy({ order, orderBy }))
    },
    [setOrderAndOrderBy, state.orderBy],
  )

  const handleClickChip = useCallback(
    (isClickChip: boolean) => {
      dispatch(setIsClickChip(isClickChip))
    },
    [setIsClickChip],
  )

  const handleFilterClear = useCallback(
    ({ isFetch }: { isFetch: boolean }) => {
      dispatch(initCollectionActivityFilter())
      handleClickChip(false)
      dispatch(setTradeTypes([]))

      if (isFetch) {
        setFilterQueryParams({
          order: undefined,
          orderBy: 'DESC',
          tradeTypes: '',
        })
      }
    },
    [handleClickChip, initCollectionActivityFilter, setTradeTypes],
  )

  const handleActivityType = useCallback(
    (filterType: ActivityFilterType) => {
      const selectedFilters = createFilteredTradeTypes(filterType)

      dispatch(setTradeTypes(selectedFilters))
    },
    [createFilteredTradeTypes, setTradeTypes],
  )

  return {
    activityFilters: state.tradeTypes,
    state,
    isClickSort,
    sortOptions,
    handleFilterClear,
    handleClickChip,
    isFilterVisible,
    filterQueryParams,
    handleActivityType,
    setIsFilterVisible,
    setFilterQueryParams,
    debouncedQueryParams,
    handleOrderAndSortBy,
  }
}
