import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import {
  IBaseResponse,
  IGetCollectionListRequest,
  IGetCollectionListResponse,
} from '@/shared/types'

import withAxios from '../api'

interface IUseGetCollectionListInfinityQueryProps {
  pathname: string
  params?: IGetCollectionListRequest
}

export const getCollectionList = async (params: IGetCollectionListRequest) => {
  const { data } = await withAxios<IBaseResponse<IGetCollectionListResponse>>({
    url: '/api/v1/public/collections',
    method: 'GET',
    params,
  })

  return data
}

export const useGetCollectionList = (page: number, perPage: number, isVerified = true) =>
  useQuery(
    ['getCollectionList', { page, perPage, isVerified }],
    () => getCollectionList({ page, perPage, isVerified }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  )

export const useGetCollectionListInfinityQuery = ({
  pathname,
  params,
}: IUseGetCollectionListInfinityQueryProps) =>
  useInfiniteQuery<IGetCollectionListResponse>(
    [pathname, 'useGetCollectionListInfinityQuery', { params }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...params,
        perPage: 20,
        page: pageParam,
        isVerified: true,
        transactionScopes: 'TRADE,ALL',
      } as IGetCollectionListRequest

      const response = await getCollectionList(queryParams)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  )
