import dayjs from 'dayjs'

import { ORIGINALS_PAYMENT_TYPE } from '@/shared/constants'
import { OriginalsPaymentType } from '@/shared/types'

export const encodeQuery = <T extends string | Record<string, any>>(query: T): string => {
  return Buffer.from(JSON.stringify(query)).toString('base64')
}

export const decodeQuery = (encodedQuery: string) => {
  return JSON.parse(Buffer.from(encodedQuery, 'base64').toString('utf8'))
}

/* 
  translate는 t를 내려주시면 됩니다.
  t를 내리는 이유는 hook을 함수에서 쓰기 어렵기 때문입니다.
*/
export const getDateByFormmat = (
  dateString: string,
  translate: (key: string) => string,
  format = 'YYYY.MM.DD',
) => {
  const date = dayjs(dateString)
  let hour = date.hour()

  const minute = date.minute()
  const ampm =
    hour < 12 ? `${translate('common:common.date-am')}` : `${translate('common:common.date-pm')}`

  if (hour > 12) hour -= 12
  else if (hour === 0) hour = 12

  return `${date.format(format)} ${ampm} ${hour}:${minute < 10 ? `0${minute}` : minute}`
}

/* 
  translate는 t를 내려주시면 됩니다.
  t를 내리는 이유는 hook을 함수에서 쓰기 어렵기 때문입니다.
*/
export const getPaymentTypeText = (
  paymentType: OriginalsPaymentType,
  translate: (key: string) => string,
) => {
  const mappings = {
    [ORIGINALS_PAYMENT_TYPE.POL]: 'POL',
    [ORIGINALS_PAYMENT_TYPE.BANK]: `${translate('common:common.payment-bank')}`,
    [ORIGINALS_PAYMENT_TYPE.CARD]: `${translate('common:common.payment-credit-02')}`,
    [ORIGINALS_PAYMENT_TYPE.FREE]: `${translate('common:common.payment-free')}`,
    [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: `${translate('common:common.payment-credit-02')}`,
    [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: `${translate('common:common.label-hyundai-card')}`,
    [ORIGINALS_PAYMENT_TYPE.PAYPAL]: 'Payment with PayPal',
  }

  return mappings[paymentType]
}

/* 
  *
  다빈치 전용 환불 정책입니다.
  DAY1 9/27     DAY2 9/28    DAY3 9/29

  1. 프로그램 시작 일 10일 전까지 : 전액 환급 (9/27일 행사 기준 9/17일 오후 11:59분까지)
  2. 프로그램 시작 일 7일 전까지 : 10% 공제 후 환급 (9/27일 행사 기준 9/20일 오후 11:59분까지)
  3. 프로그램 시작 일 3일 전까지 : 20% 공제 후 환급 (9/27일 행사 기준 9/24일 오후 11:59분까지)
  4. 프로그램 시작 일 1일 전까지 : 30% 공제 후 환급 (9/27일 행사 기준 9/26일 오후 11:59분까지)
  5. 프로그램 시작 당일 프로그램 시작 전까지 : 90% 공제 후 환급 (DAY 1 예약권 9/27일, DAY 2 예약권 9/28일, DAY 3 예약권 9/29일 오전 11시 59분까지)
  6. 프로그램 시작 3일 전까지는 예약권 구매 당일 취소 시 : 전액 환급 (구매 당일 오후 11시 59분까지)
  7. 프로그램 시작 후에는 예약권 구매 취소가 불가능합니다.(9/27일 오전 11시 59분까지)
  8. 3일 패키지는 27일 기준으로 환불 요청됩니다.
  *
*/

const DAVINCI_REFUND_FEE_PERCENT = {
  NONE: 100, // 환불 불가
  FULL: 0, // 전액 환급
  TEN_PERCENT: 10, // 10% 공제
  TWENTY_PERCENT: 20, // 20% 공제
  THIRTY_PERCENT: 30, // 30% 공제
  NINETY_PERCENT: 90, // 90% 공제
}

export const refundCalculator = (
  eventStartDate: string, // ex) "2024-09-27T06:12:21.000Z"
  paymentAmount: number,
  paymentDate: string, // ex) "2024-09-11T06:12:21.000Z"
) => {
  // 날짜 및 시간 설정
  const eventDate = dayjs(eventStartDate)
  const paymentDateTime = dayjs(paymentDate)
  const today = dayjs(new Date())

  // 환불 불가 날짜 (이벤트 당일 11:59까지)
  const nonRefundableDate = eventDate.set('hour', 11).set('minute', 59).set('second', 59)

  // 구매 당일 환불 가능 날짜 (구매 당일 11:59까지)
  const purchaseSameDayRefundDate = paymentDateTime
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)

  // 초기 환불 정책 설정
  let refundPercentage = 0

  // 환불 정책 적용
  switch (paymentAmount > 0) {
    case today.isAfter(eventDate):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.FULL
      break

    case today.isBefore(eventDate.subtract(10, 'day').endOf('day')):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.NONE
      break

    case today.isBefore(eventDate.subtract(7, 'day').endOf('day')):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.TEN_PERCENT
      break

    case today.isBefore(eventDate.subtract(3, 'day').endOf('day')):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.TWENTY_PERCENT
      break

    case today.isBefore(eventDate.subtract(1, 'day').endOf('day')):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.THIRTY_PERCENT
      break

    case today.isBefore(nonRefundableDate):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.NINETY_PERCENT
      break

    case today.isBefore(purchaseSameDayRefundDate):
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.NONE // 전액 환급
      break

    default:
      refundPercentage = DAVINCI_REFUND_FEE_PERCENT.FULL // 기본적으로 환불 불가
  }

  // 환불 금액 계산
  const refundFee = (paymentAmount * refundPercentage) / 100
  const refundAmount = paymentAmount - refundFee

  // 결과 반환
  return {
    refundPercentage,
    refundAmount,
    refundFee,
  }
}

/* TODO: @이동환 QA 진행 후 삭제할 코드입니다! */
// export const testRefundCalculator = (
//   eventStartDate: string, // "2024-09-27T06:12:21.000Z"
//   paymentAmount: number,
//   paymentDate: string, // "2024-09-11T06:12:21.000Z"
// ) => {
//   // 날짜 및 시간 설정
//   // const eventDate = dayjs(eventStartDate)
//   const eventDate = dayjs(eventStartDate).set('hour', 11).set('minute', 59).set('second', 59)
//   const paymentDateTime = dayjs(paymentDate)
//   const today = dayjs(new Date())

//   console.log({
//     today,
//     eventDate,
//     paymentDateTime,
//   })
//   // 환불 불가 날짜 (이벤트 당일 11:59까지)
//   const nonRefundableDate = eventDate.set('hour', 11).set('minute', 59).set('second', 59)

//   console.log('nonRefundableDate', nonRefundableDate)

//   // 구매 당일 환불 가능 날짜 (구매 당일 11:59까지)
//   const purchaseSameDayRefundDate = paymentDateTime
//     .set('hour', 23)
//     .set('minute', 59)
//     .set('second', 59)

//   // 초기 환불 정책 설정
//   let refundPercentage = 0

//   // 환불 정책 적용
//   if (today.isAfter(eventDate)) {
//     refundPercentage = 100 // 환불 불가
//   } else if (today.isBefore(eventDate.subtract(10, 'day').endOf('day'))) {
//     refundPercentage = 0 // 전액 환급
//   } else if (today.isBefore(eventDate.subtract(7, 'day').endOf('day'))) {
//     refundPercentage = 10 // 10% 공제
//   } else if (today.isBefore(eventDate.subtract(3, 'day').endOf('day'))) {
//     refundPercentage = 20 // 20% 공제
//   } else if (today.isBefore(eventDate.subtract(1, 'day').endOf('day'))) {
//     refundPercentage = 30 // 30% 공제
//   } else if (today.isBefore(nonRefundableDate)) {
//     refundPercentage = 90 // 90% 공제
//   } else if (today.isBefore(purchaseSameDayRefundDate)) {
//     refundPercentage = 0 // 전액 환급
//   }

//   // 환불 금액 계산
//   const refundFee = (paymentAmount * refundPercentage) / 100 || 0
//   const refundAmount = paymentAmount - refundFee || 0

//   // console.log('refundPercentage', refundPercentage)
//   // console.log('refundAmount', refundAmount)
//   // console.log('refundFee', refundFee)

//   console.log('refundPercentage', refundPercentage)
//   console.log('refundAmount', refundAmount)
//   console.log('refundFee', refundFee)

//   // 결과 반환
//   return {
//     refundPercentage,
//     refundAmount,
//     refundFee,
//   }
// }
