import { getPayAmountsByPrice } from '@modernlion/marketplace-registry'
import { convertPriceToBigNumber } from '@modernlion/solidity-registry'
import { ItemType } from '@opensea/seaport-js/lib/constants'
import { CreateOrderInput, OrderWithCounter } from '@opensea/seaport-js/lib/types'
import dayjs from 'dayjs'

import { ICreateOfferFormData } from '@/shared/types'
import { createOrder, getTokenAddress } from '@/shared/utils'

import { AppThunk } from '..'
import {
  cancelAllOffer,
  getOfferItemInfo,
  registerToBuyForItem,
  resetCancelState,
  resetOfferState,
  resetSignatureState,
  setCancelFailedForUserDenied,
  setCancelIsError,
  setCancelIsRequired,
  setCounterForOffer,
  setIsCreateOfferError,
  setIsSigning,
  setOfferAction,
  setOfferFormData,
  setOrderForOffer,
  setOrderHashForOffer,
  setSignature,
  setSignIsError,
} from '../trades'
import { getBalance } from '../wallet'

export const resetOfferStates = (): AppThunk => async dispatch => {
  dispatch(resetCancelState())
  dispatch(resetSignatureState())
  dispatch(resetOfferState())
}

export const offerDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    chainId,
    handlePreparingToOpenDialog,
    handleOfferFormDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleOfferFormDialog: (offerFormDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetOfferStates())

    await dispatch(getBalance())

    handlePreparingToOpenDialog(true)
    dispatch(setOfferAction('create'))
    await dispatch(
      getOfferItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleOfferFormDialog(true)
  }

export const submitOfferFormData =
  (createOfferData: ICreateOfferFormData, isCancelRequired: boolean): AppThunk =>
  async dispatch => {
    dispatch(setOfferFormData(createOfferData))
    dispatch(setCancelIsRequired(isCancelRequired))
  }

export const signForBuyOrder = (): AppThunk => async (dispatch, getState) => {
  const currentAccountAddress = getState().wallet.currentProfile?.accountAddress ?? null
  const offerFormData = getState().offer.offerFormData
  const offerItemInfo = getState().offer.offerItemInfo
  const tradeAddress = getState().contractAddress.tradeAddress

  if (tradeAddress === null) {
    return dispatch(setIsCreateOfferError('No trade addresses'))
  }

  if (currentAccountAddress === null) {
    return dispatch(setIsCreateOfferError('No current account address'))
  }

  if (offerFormData === null) {
    return dispatch(setIsCreateOfferError('No offer form data'))
  }

  if (offerItemInfo === null) {
    return dispatch(setIsCreateOfferError('No offer item info'))
  }

  if (offerItemInfo.tradeAddress === null) {
    return dispatch(setIsCreateOfferError('No trade address'))
  }

  if (offerItemInfo.royaltyInfo === null) {
    return dispatch(setIsCreateOfferError('No trade address'))
  }

  const { marketFee, royalties, marketOwner } = offerItemInfo.royaltyInfo
  const { paymentType, expirationTime, price } = offerFormData

  const { marketRoyalty } = getPayAmountsByPrice(price, marketFee, royalties)

  const tokenAddress = getTokenAddress(paymentType)

  const resourceForCreateOrder: CreateOrderInput = {
    startTime: String(dayjs().unix()),
    endTime: String(dayjs(expirationTime).unix()),
    offer: [
      {
        amount: convertPriceToBigNumber(price, paymentType).toString(),
        token: tokenAddress,
      },
    ],
    consideration: [
      {
        itemType: ItemType.ERC721,
        token: offerItemInfo.collectionAddress,
        identifier: String(offerItemInfo.tokenId),
        recipient: currentAccountAddress,
      },
      {
        token: tokenAddress,
        amount: convertPriceToBigNumber(marketRoyalty, paymentType).toString(),
        recipient: marketOwner,
      },
    ],
    zone: tradeAddress.zone,
  }

  await createOrder(
    offerItemInfo.chainId,
    resourceForCreateOrder,
    currentAccountAddress,
    tradeAddress,
    (order: OrderWithCounter) => dispatch(setOrderForOffer(order)),
    (orderHash: string) => dispatch(setOrderHashForOffer(orderHash)),
    (counter: number) => dispatch(setCounterForOffer(counter)),
    (signature: string) => dispatch(setSignature(signature)),
    (loading: boolean) => dispatch(setIsSigning(loading)),
    (error: string) => dispatch(setSignIsError(error)),
  )
}

export const makeOffer = (): AppThunk => async (dispatch, getState) => {
  const signature = getState().signature.signature
  const order = getState().offer.order
  const orderHash = getState().offer.orderHash
  const counter = getState().offer.counter
  const offerItemInfo = getState().offer.offerItemInfo
  const offerFormData = getState().offer.offerFormData

  if (signature === null) {
    return dispatch(setIsCreateOfferError('No signature'))
  }

  if (order === null) {
    return dispatch(setIsCreateOfferError('No order'))
  }

  if (orderHash === null) {
    return dispatch(setIsCreateOfferError('No order hash'))
  }

  if (counter === null) {
    return dispatch(setIsCreateOfferError('No counter'))
  }

  if (offerItemInfo === null) {
    return dispatch(setIsCreateOfferError('No offer item info'))
  }

  if (offerFormData === null) {
    return dispatch(setIsCreateOfferError('No offer form data'))
  }

  const { collectionAddress, itemId } = offerItemInfo

  const { royaltyInfo } = offerItemInfo

  if (royaltyInfo === null) {
    return dispatch(setIsCreateOfferError('No royalty info'))
  }

  await dispatch(
    registerToBuyForItem({
      itemId,
      collectionAddress,
      offerFormData,
      order,
      orderHash,
      counter,
      royaltyInfo,
      signature,
    }),
  )
}

export const retry =
  ({
    handleWalletWaitingForOfferDialog,
    handleWalletWaitingForOfferCancelDialog,
  }: {
    handleWalletWaitingForOfferDialog: (walletWaitingForOfferDialogIsOpen: boolean) => void
    handleWalletWaitingForOfferCancelDialog: (
      walletWaitingForOfferCancelDialogIsOpen: boolean,
    ) => void
  }): AppThunk =>
  async (dispatch, getState) => {
    const offerAction = getState().offer.action

    if (offerAction === 'create' || offerAction === 'edit') {
      dispatch(setCancelIsError(null))
      dispatch(setCancelFailedForUserDenied(false))
      dispatch(setSignIsError(null))
      dispatch(setIsCreateOfferError(null))
      dispatch(setSignature(null))

      handleWalletWaitingForOfferDialog(true)
    } else if (offerAction === 'cancel') {
      dispatch(setCancelIsError(null))
      dispatch(setCancelFailedForUserDenied(false))
      dispatch(requestCancelAllOffer(handleWalletWaitingForOfferCancelDialog))
    }
  }

export const offerEditOrCancelDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    chainId,
    handlePreparingToOpenDialog,
    handleOfferEditOrCancelDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleOfferEditOrCancelDialog: (offerEditOrCancelDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetOfferStates())

    handlePreparingToOpenDialog(true)
    await dispatch(
      getOfferItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleOfferEditOrCancelDialog(true)
  }

export const offerEditDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    chainId,
    handlePreparingToOpenDialog,
    handleOfferFormDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleOfferFormDialog: (offerFormDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetOfferStates())

    handlePreparingToOpenDialog(true)
    dispatch(setOfferAction('edit'))
    await dispatch(
      getOfferItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleOfferFormDialog(true)
  }

export const offerCancelDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    chainId,
    handlePreparingToOpenDialog,
    handleOfferCancelDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    chainId: string
    handlePreparingToOpenDialog: (isPreparingToOpenDialog: boolean) => void
    handleOfferCancelDialog: (offerCancelDialogIsOpen: boolean) => void
  }): AppThunk =>
  async dispatch => {
    dispatch(resetOfferStates())

    handlePreparingToOpenDialog(true)
    dispatch(setOfferAction('cancel'))
    await dispatch(
      getOfferItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        chainId,
      }),
    )
    handlePreparingToOpenDialog(false)
    handleOfferCancelDialog(true)
  }

export const requestCancelAllOffer =
  (
    handleWalletWaitingForOfferCancelDialog: (
      walletWaitingForOfferCancelDialogIsOpen: boolean,
    ) => void,
  ): AppThunk =>
  async dispatch => {
    dispatch(cancelAllOffer())
    handleWalletWaitingForOfferCancelDialog(true)
  }
