import { useMutation } from '@tanstack/react-query'

import withAxios from '../api'

export interface ICheckRegisteredWalletResponse {
  isRegistered: boolean
}

export const getCheckRegisteredWallet = async ({ accountAddress }: { accountAddress: string }) => {
  const url = '/api/v1/public/wallets/is-registered'

  const { data } = await withAxios<ICheckRegisteredWalletResponse>({
    url,
    method: 'GET',
    params: { accountAddress },
  })

  return data
}

export const useGetCheckRegisteredWallet = () => {
  return useMutation(
    ['getCheckRegisteredWallet'],
    ({ accountAddress }: { accountAddress: string }) =>
      getCheckRegisteredWallet({ accountAddress }),
    {},
  )
}
