import withAxios from '../api'

export interface IGetWaitingRoomResponse {
  waitingRoom: string
}

export const getWaitingRoom = async () => {
  const url = 'https://b5mne4pbmsfmi3affb7tjd5iuy0hergr.lambda-url.ap-northeast-2.on.aws'
  const {
    data: { waitingRoom },
  } = await withAxios<IGetWaitingRoomResponse>({ url })

  return waitingRoom
}
