import { chainIdType } from '@modernlion/common-registry/dist/lib/constant'

export const CHAIN_ID = {
  ...chainIdType,
} as const

export const CHAIN_NAME = {
  [CHAIN_ID.ethereum]: 'ethereum',
  [CHAIN_ID.sepolia]: 'ethereum',
  [CHAIN_ID.polygon]: 'polygon',
  [CHAIN_ID.amoy]: 'polygon',
}

export const CHAIN_ICON_MAPPINGS: Record<
  string,
  {
    img: string
    name: string
  }
> = {
  [CHAIN_ID.ethereum]: { img: '/img/icon/ethereum.svg', name: 'Ethereum' },
  [CHAIN_ID.sepolia]: { img: '/img/icon/ethereum.svg', name: 'Ethereum' },
  [CHAIN_ID.polygon]: { img: '/img/icon/polygon.svg', name: 'Polygon' },
  [CHAIN_ID.amoy]: { img: '/img/icon/polygon.svg', name: 'Polygon' },
}

export const PROVIDER_KEYS = {
  [CHAIN_ID.ethereum]: 'konkritEthereum',
  [CHAIN_ID.sepolia]: 'konkritEthereum',
  [CHAIN_ID.polygon]: 'konkrit',
  [CHAIN_ID.amoy]: 'konkrit',
} as const
