import {
  IOriginalsOption,
  IUpdateConvertedOriginalsCheckoutInfo,
  IUpdateOriginalsCheckoutProps,
} from '@/shared/types'

import { ORIGINALS_PAYMENT_TYPE } from '../constants'
import { Currency } from '../store/wallet'

export const convertUpdateCheckoutInfoProps = ({
  amount,
  originalsId,
  selectedPaymentType,
  selectedOptionList,
  isSingleOption,
}: IUpdateOriginalsCheckoutProps): IUpdateConvertedOriginalsCheckoutInfo | undefined => {
  const resultSelectedOptionList = selectedOptionList.map(
    (selectedOptionItem: IOriginalsOption) => {
      const { id } = selectedOptionItem

      return { originalsOptionId: id, purchaseQuantity: isSingleOption ? amount : 1 }
    },
  )

  if (originalsId === undefined || selectedPaymentType === null) return

  if (isSingleOption) {
    return {
      originalsId,
      paymentType: selectedPaymentType,
      totalCount: amount,
      selectOptions: resultSelectedOptionList,
      currency: selectedPaymentType === ORIGINALS_PAYMENT_TYPE.PAYPAL ? Currency.USD : Currency.KRW,
    }
  } else
    return {
      originalsId,
      paymentType: selectedPaymentType,
      totalCount: selectedOptionList.length,
      selectOptions: resultSelectedOptionList,
      currency: selectedPaymentType === ORIGINALS_PAYMENT_TYPE.PAYPAL ? Currency.USD : Currency.KRW,
    }
}
