import { useInfiniteQuery } from '@tanstack/react-query'

import withAxios from '@/shared/services/api'
import { IGetOnSaleRequest, IMarketOnSaleResponse } from '@/shared/types/items'

export const getOnSaleInfo = async ({ params }: { params?: IGetOnSaleRequest }) => {
  const { data } = await withAxios<IMarketOnSaleResponse>({
    url: '/api/v1/public/items/on-sale',
    method: 'GET',
    params,
  })

  return data
}

export const useGetOnSaleInfo = ({ params }: { params?: IGetOnSaleRequest }) => {
  return useInfiniteQuery(
    ['getOnSaleInfo', { params }],
    async ({ pageParam }) => {
      const queryParams = {
        ...params,
        page: pageParam,
        perPage: 3,
      }
      const response = await getOnSaleInfo({ params: queryParams })
      return response
    },
    {
      suspense: true,
      useErrorBoundary: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: data => ({
        pages: data.pages.flatMap(page => page.items),
        pageParams: data.pageParams,
      }),
      getNextPageParam: lastPage => {
        const {
          pagination: { page, totalPage },
        } = lastPage

        if (page === totalPage) return undefined

        return page + 1
      },
    },
  )
}
