import { useQuery } from '@tanstack/react-query'

import { IAccountProfileResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountProfile = async (accountAddress: string) => {
  const url = `/api/v1/public/accounts/${accountAddress}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IAccountProfileResponse>>({ url, method: 'GET' })
  return data
}

export const useGetPublicAccountProfile = (pathname: string, accountAddress?: string) =>
  useQuery(
    [pathname, 'getPublicAccountProfile', { accountAddress }],
    () => getPublicAccountProfile(accountAddress || ''),
    { enabled: accountAddress !== undefined, refetchOnWindowFocus: false },
  )
