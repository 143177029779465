import { useDebouncedValue } from '@mantine/hooks'
import { useMemo } from 'react'

import { IAccountItemFilterState } from '../store/filters'

function isNumeric(str: string | undefined) {
  if (str === undefined) {
    return false
  }
  return !isNaN(Number(str)) && !isNaN(parseFloat(str))
}

// TODO @고영욱 필터 관련 코드(현재 사용X)
export function useMemoAccountItemFilterQueryParams(
  state: IAccountItemFilterState,
  options: { exclude?: string[] } = {},
) {
  const memorizedQueryParams = useMemo(() => {
    const { minPrice, maxPrice } = state
    const queryString = {
      isListing: state.isListing || isNumeric(minPrice) || isNumeric(maxPrice),
      paymentType: state.paymentType,
      hasOffer: state.hasOffer,
      order: state.order,
      orderBy: state.orderBy,
      minPrice: state.minPrice,
      maxPrice: state.maxPrice,
      collectionAddresses: state.collections
        .map(collection => collection.address)
        .filter(Boolean)
        .join(','),
    }
    if (!isNumeric(minPrice) && !isNumeric(maxPrice)) {
      delete queryString['paymentType']
    }
    if (options.exclude !== undefined) {
      options.exclude.forEach(key => {
        if (key === 'paymentType') {
          delete queryString[key]
        }
      })
    }

    return queryString
  }, [options.exclude, state])

  const [debouncedQueryParams] = useDebouncedValue(memorizedQueryParams, 200)

  return debouncedQueryParams
}
