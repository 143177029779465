import { useScrollLock } from '@mantine/hooks'
import Image from 'next/image'
import { useEffect } from 'react'

const FullscreenSpinner = ({ isPreparingToOpenDialog }: { isPreparingToOpenDialog: boolean }) => {
  const [scrollLocked, setScrollLocked] = useScrollLock()

  useEffect(() => {
    if (isPreparingToOpenDialog) setScrollLocked(true)
    else setScrollLocked(false)
  }, [isPreparingToOpenDialog, setScrollLocked])

  if (!scrollLocked) return <></>

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-bgPrimary bg-opacity-80 transition-opacity flex flex-col items-center justify-center">
      <div className="flex items-center justify-center">
        <div className="w-24 h-24">
          <Image
            src="/img/common/loading.svg"
            alt="loading"
            width={96}
            height={96}
            className="animate-spin"
          />
        </div>
      </div>
    </div>
  )
}

export default FullscreenSpinner
