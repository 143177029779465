import { useQuery } from '@tanstack/react-query'

import { IAccountItemCollectionsResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountLikedItemCollections = async () => {
  const url = '/api/v1/private/current-account/liked-items/collections'
  const {
    data: { data: collections },
  } = await withAxios<IBaseResponse<IAccountItemCollectionsResponse>>({ url, method: 'GET' })

  return collections
}

export const useGetCurrentAccountLikedItemCollections = (
  pathname: string,
  accountAddress: string | null,
  enabled: boolean,
) =>
  useQuery(
    [pathname, 'getCurrentAccountLikedItemCollections', { accountAddress }],
    getCurrentAccountLikedItemCollections,
    {
      enabled: accountAddress !== null && enabled,
      refetchOnWindowFocus: false,
    },
  )
