import { useQuery } from '@tanstack/react-query'

import withAxios from '@/shared/services/api'

export interface IGetCollectionCategoriesResponse {
  categories: {
    categoryId: string
    categoryTitle: string
  }[]
}

export const getCollectionCategories = async () => {
  const { data } = await withAxios<IGetCollectionCategoriesResponse>({
    url: '/api/v1/public/collections/categories',
    method: 'GET',
  })
  return data
}
export const useGetCollectionCategories = (pathname: string) => {
  return useQuery(
    [pathname, `getCollectionCategories-${pathname}`],
    () => getCollectionCategories(),
    {
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  )
}
