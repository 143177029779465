import { useQuery } from '@tanstack/react-query'

import { IAccountItemCollectionsResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountReceivedOfferCollections = async () => {
  const url = '/api/v1/private/current-account/received-offers/collections'
  const {
    data: { data: collections },
  } = await withAxios<IBaseResponse<IAccountItemCollectionsResponse>>({ url, method: 'GET' })

  return collections
}

export const useGetCurrentAccountReceivedOfferCollections = (
  pathname: string,
  accountAddress: string | null,
) =>
  useQuery(
    [pathname, 'getCurrentAccountReceivedOfferCollections', { accountAddress }],
    getCurrentAccountReceivedOfferCollections,
    { enabled: accountAddress !== null, refetchOnWindowFocus: false },
  )
