import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '..'

export interface ISelectedSeat {
  category: string
  color: string
  parent: string
  own: string
  id: string
  quantity: number
  objectType: string
}

export interface IBookEventState {
  holdToken: string | null
  selectedEventDate: string | null
  selectedSeatInfo: ISelectedSeat[] | null
  selectedEventKey: string | null
}

const initialState: IBookEventState = {
  holdToken: null,
  selectedEventDate: null,
  selectedSeatInfo: null,
  selectedEventKey: null,
}

export const bookEventSlice = createSlice({
  name: 'bookEvent',
  initialState,
  reducers: {
    reset: state => {
      state.holdToken = null
      state.selectedEventDate = null
    },
    setHoldToken: (state, action: PayloadAction<{ holdToken: string | null }>) => {
      state.holdToken = action.payload.holdToken
    },
    setSelectedEventDate: (state, action: PayloadAction<{ selectedEventDate: string | null }>) => {
      state.selectedEventDate = action.payload.selectedEventDate
    },
    setSelectedSeatsInfo: (
      state,
      action: PayloadAction<{ selectedSeatInfo: ISelectedSeat[] | null }>,
    ) => {
      state.selectedSeatInfo = action.payload.selectedSeatInfo
    },
    setSelectedEventKey: (state, action: PayloadAction<{ selectedEventKey: string | null }>) => {
      state.selectedEventKey = action.payload.selectedEventKey
    },
  },
})

export const selectHoldToken = (state: AppState) => state.bookEvent.holdToken
export const selectSelectedEventDate = (state: AppState) => state.bookEvent.selectedEventDate
export const selectSelectedSeatsInfo = (state: AppState) => state.bookEvent.selectedSeatInfo
export const selectSelectedEventKey = (state: AppState) => state.bookEvent.selectedEventKey

export const { setHoldToken, setSelectedEventDate, setSelectedSeatsInfo, setSelectedEventKey } =
  bookEventSlice.actions

export default bookEventSlice
