import { COLLECTION_CARD_OPTIONS, ITEM_CARD_OPTIONS, TRANSACTION_SCOPE } from '../constants'
import { IPagination, OrderByType } from './base'
import { SortType, TradeType } from './filter'
import { ListingPaymentType, OfferPaymentType, PaymentType } from './itemOrder'

export type ItemCardOptionType = keyof typeof ITEM_CARD_OPTIONS
export type CollectionCardOption = keyof typeof COLLECTION_CARD_OPTIONS

export type GetItemDetailOfferRequest = {
  page: number
  perPage: number
}

export interface IGetCollectionResponse {
  collectionAddress: string
  accountAddress: string
  chainId: string
  title: string
  description: string
  imgUrl: string
  backgroundImgUrl: string
  appImgUrl: string | null
  isBlock: boolean
  isVerified: boolean
  isOriginals: boolean
  categoryId: string
  website: string | null
  discord: string | null
  twitter: string | null
  status: string | null
  hasBenefits: boolean
  benefitType: string | null
  collectionRoyalties: {
    accountAddress: string
    rate: string
  }[]
  itemCount: number
  holderCount: number
  stats: ICollectionStat[]
}

export interface IConvertedCollectionForEdit extends IGetCollectionResponse {
  collectionRoyalties: {
    accountAddress: string
    rate: string
    percentage: string
  }[]
}

export interface ICollectionStat {
  paymentType: ListingPaymentType
  minPrice: number | null
  totalTradeAmount: number
}

export interface IPatchCollectionEditRequest {
  collectionAddress: string
  body: {
    royalty: {
      collectionAddress: string
      chainId: string
      accountAddress: string
      rate: number
    }[]
    imgUrl: string
    backgroundImgUrl: string
    description: string
    website: string
    discord: string
    twitter: string
  }
}

export interface IPatchCollectionEditResponse {
  collectionAddress: string
  accountAddress: string
  chainId: string
  title: string
  description: string
  imgUrl: string
  backgroundImgUrl: string
  appImgUrl: string | null
  isBlock: boolean
  isVerified: boolean
  isOriginals: boolean
  categoryId: string
  website: string | null
  discord: string | null
  twitter: string | null
  status: string | null
  hasBenefits: boolean
  benefitType: string | null
  collectionRoyalties: {
    accountAddress: string
    rate: string
  }[]
}

export interface IGetCollectionItemRequest extends ICollectionItemsDebouncedQueryParams {
  page: number
  perPage: number
  isRefresh: boolean
}

export interface ICollectionItemResponse extends IPagination {
  items: IItem[]
}

export interface IOfferItemForDetailPage {
  id: string
  collectionAddress: string
  signHash: string
  accountAddress: string
  paymentType: OfferPaymentType
  price: number
  filled: number
  isCanceled: boolean
  expiredAt: string
  createdAt: string
}

export interface IItemActivityResponse extends IPagination {
  activities: IActivityItem[]
  lastTradePrice: null | number
  lastTradePaymentType: null | PaymentType
}

export interface IGetOfferItemResponse extends IPagination {
  offers: IOfferItemForDetailPage[]
}

export type CollectionEditFormFields = {
  collectionRoyalties: { accountAddress: string; rate: string; percentage: string }[]
  imgUrl: string
  backgroundImgUrl: string
  description: string
  website: string
  discord: string
  twitter: string
}

export interface ICollectionActivityDebouncedQueryParams {
  order?: string
  orderBy: SortType
  tradeTypes?: string
}

export interface ICollectionActivityResponse extends IPagination {
  activities: IActivityItem[]
}

export interface ISearchItemListDebouncedQueryParams {
  isListing: boolean
  paymentType?: PaymentType
  order?: string
  orderBy: OrderByType
  minPrice?: string
  maxPrice?: string
  searchText: string
}

export interface ICollectionItemsDebouncedQueryParams {
  isListing: boolean
  paymentType?: PaymentType
  order?: string
  orderBy: SortType
  minPrice?: string
  maxPrice?: string
  filterPropertyIds: string
}

export type CrawlingStatus = 'ALCHEMY' | 'COMPLETED'

interface IProperties {
  id: number
  title: string
  value: string
  rarityRate: number
}

interface IItemDetailCollection {
  collectionAddress: 'string'
  chainId: 'string'
  title: 'string'
  isOriginals: boolean
  isVerified: boolean
  transactionScope: keyof typeof TRANSACTION_SCOPE
  hasBenefits: boolean
  accountAddress: 'string'
  maxTransferCount: number | null
  collectionRoyalties: {
    accountAddress: 'string'
    rate: 'string'
  }[]
}

export interface IGetItemDetailRequest {
  collectionAddress?: string
  tokenId?: string
}

export interface IItemDetail {
  id: string
  title: string
  description: string
  mediaUrl: string
  tokenId: number
  accountAddress: string
  createdAt: string
  likeCount: number
  isHidden: boolean
  properties: IProperties[]
  transferCount: number
  listing: {
    id: string
    signHash: string
    accountAddress: string
    paymentType: ListingPaymentType
    price: number
    isCanceled: boolean
    expiredAt: string
    createdAt: string
    unixExpiredAt: number
  } | null
  collection: IItemDetailCollection
  status: CrawlingStatus | null
}

export interface IUseItemDetailActivitiesInfiniteQueryProps {
  query: string
  collectionAddress: string | undefined
  tokenId: string | undefined
}

export interface IGetItemDetailParams {
  perPage?: number
  page?: number
}
export interface IGetItemDetailQueryProps {
  collectionAddress: string | undefined
  tokenId: string | undefined
  params: IGetItemDetailParams
}

export interface IGetCollectionsSearchRequest {
  searchText: string
  orderBy?: 'ASC' | 'DESC'
  order?: string
  page?: number
  perPage?: number
}

export interface IGetCollectionsSearchResponse {
  collections: ICollection[]
  page: number
  perPage: number
  totalPage: number
  totalCount: number
}

export interface ICollection {
  collectionAddress: string
  chainId: string
  title: string
  description: string
  imgUrl: string
  backgroundImgUrl: string
  appImgUrl: string | null
  accountAddress: string
  isBlock: boolean
  isOriginals: boolean
  isVerified: boolean
  hasBenefits: boolean
  benefitType: string | null
  status: string | null
  categoryId: string
}

export interface IWhiteListCollection {
  collectionAddress: string
  accountAddress: string
  chainId: string
  title: string
  description: string
  imgUrl: string
  backgroundImgUrl: string
  appImgUrl: string | null
  isBlock: boolean
  isVerified: boolean
  isOriginals: boolean
  categoryId: string
  website: string | null
  discord: string | null
  twitter: string | null
  status: string | null
  hasBenefits: boolean
  benefitType: string | null
}

export interface IGetCollectionListRequest {
  page?: number
  perPage?: number
  isVerified?: boolean
  categoryId?: string
}

export interface IGetCollectionListResponse extends IPagination {
  collections: IWhiteListCollection[]
}

export interface IActivityItem {
  id: string
  tradeType: TradeType
  collectionAddress: string
  transactionHash: string
  fromAccountAddress: string
  toAccountAddress: string
  occurredAt: string
  createdAt: string
  order: ICollectionActivityOrder
  item: ICollectionActivityItem
}

export interface ICollectionActivityItem {
  id: string
  collectionAddress: string
  chainId: string
  collectionTitle: string
  title: string
  mediaUrl: string
  tokenId: string
  accountAddress: string
  isVerified: boolean
  transactionScope: keyof typeof TRANSACTION_SCOPE
}

export interface ICollectionActivityOrder {
  id: string
  accountAddress: string
  collectionAddress: string
  createdAt: string
  expiredAt: string
  filled: number
  isCanceled: boolean
  paymentType: PaymentType
  price: string
  signHash: string
}

export interface ICollectionRoyalty {
  id: number
  collectionAddress: string
  chainId: string
  accountAddress: string
  rate: number
}

export interface IProperty {
  title: string
  values: IPropertyValue[]
}
export interface IPropertyValue {
  value: string
  amount: number
  percentage: number
  id: number
}

export interface IGetCollectionPropertiesResponse {
  properties: IProperty[]
}

export interface IItem {
  id: string
  collectionAddress: string
  chainId: string
  collectionTitle: string
  title: string
  description: string | null
  mediaUrl: string
  tokenId: string
  accountAddress: string | null
  createdAt: string
  transferCount: number
  listing: {
    id: string
    signHash: string
    orderHash: string
    accountAddress: string
    paymentType: ListingPaymentType
    price: number
    isCanceled: boolean
    filled: number
    maximumFill: number
    isLast: boolean
    makingTime: string
    unixExpiredAt: number
    expiredAt: string
    createdAt: string
  } | null
  status: CrawlingStatus | null
  collection: {
    collectionAddress: string
    chainId: string
    title: string
    description: string
    imgUrl: string
    backgroundImgUrl: string
    appImgUrl: string | null
    accountAddress: string
    isBlock: boolean
    isOriginals: boolean
    isVerified: boolean
    transactionScope: keyof typeof TRANSACTION_SCOPE
    benefitType: string | null
    website: string | null
    discord: string | null
    twitter: string | null
    status: string | null
    hasBenefits: boolean
    categoryId: string
    maxTransferCount: number
  } | null
}

export interface ICollectionStats {
  itemCount: number
  holderCount: number
  stats: ICollectionStat[]
}

export const instanceOfCollectionStats = (
  collectionStats: any,
): collectionStats is ICollectionStats => {
  return (
    'itemCount' in collectionStats && 'holderCount' in collectionStats && 'stats' in collectionStats
  )
}
