import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { useAuthContext } from '../contexts'
import { useGetUseChannelTalk } from '../services/strapi/getUseChannelTalk'
import { useAppSelector } from '../store/hooks'
import { selectCurrentAccountAddress } from '../store/wallet'
import { getEnvValue } from '../utils'
import ChannelTalkService from '../utils/ChannelTalkService'
import { detectBrowser, detectDeviceType, detectOSName } from '../utils/detectUserAgent'

const useChannelTalk = () => {
  const router = useRouter()
  const { originalsId } = router.query as { originalsId?: string }
  const { isConnectedWallet } = useAuthContext()
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)

  const [isShow, setIsShow] = useState(false)

  const channelTalkPluginKey = getEnvValue('channelTalkPluginKey') || ''

  const { data } = useGetUseChannelTalk(router.pathname)

  const isUseChannelTalk = useMemo(() => {
    if (!originalsId || !data) return false
    return data.find(el => el.originalsId === originalsId && el.isUseChannelTalk) ? true : false
  }, [data, originalsId])

  const isChannelTalkAvailable = useMemo(() => {
    if (!isUseChannelTalk || !isConnectedWallet || !currentAccountAddress || !isShow) return false
    else return true
  }, [currentAccountAddress, isConnectedWallet, isShow, isUseChannelTalk])

  useEffect(() => {
    if (!isChannelTalkAvailable) return

    const channelTalk = new ChannelTalkService()
    if (!window.ChannelIO) {
      channelTalk.loadScript()
    }
    channelTalk.boot({
      pluginKey: channelTalkPluginKey,
      memberId: currentAccountAddress,
      createdAt: new Date(),
      web: {
        browserName: detectBrowser(navigator),
        osName: detectOSName(navigator),
        device: detectDeviceType(navigator),
      },
    })

    if (isShow) {
      channelTalk.show()
    }
    if (!isShow) {
      channelTalk.hide()
    }

    return () => {
      channelTalk?.shutdown()
    }
  }, [
    isUseChannelTalk,
    isConnectedWallet,
    currentAccountAddress,
    channelTalkPluginKey,
    isShow,
    isChannelTalkAvailable,
  ])

  return {
    setIsShow,
    isShow,
    isUseChannelTalk,
  }
}
export default useChannelTalk
