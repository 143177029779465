import { useCallback, useEffect, useMemo, useState } from 'react'

import { ITermsListType } from '../types'

export const useTermsAndConditions = ({ termsList }: { termsList?: ITermsListType[] }) => {
  const [checkedTermsList, setCheckedList] = useState<string[]>([])
  const [checkedAllTerms, setCheckAllTerms] = useState(false)

  //전체 동의
  const toggleCheckAllTerms = useCallback(() => {
    if (termsList === undefined) return

    if (checkedTermsList.length === termsList.length) setCheckedList([])
    else setCheckedList(termsList.map(terms => terms.id))
    setCheckAllTerms(!checkedAllTerms)
  }, [checkedAllTerms, checkedTermsList.length, termsList])

  //단건으로 하나씩 동의
  const toggleCheckedTerms = useCallback(
    (id: string) => {
      setCheckedList(
        checkedTermsList.includes(id)
          ? checkedTermsList.filter(item => item !== id)
          : [...checkedTermsList, id],
      )
    },
    [checkedTermsList],
  )

  //필수로 동의해야 하는 것들이 모두 체크 되었는지 확인
  const isAllRequiredChecked = useMemo(() => {
    if (termsList === undefined) return false
    if (termsList.length === 0) return true
    return (
      termsList.some(item => item.isRequired) &&
      termsList.every(item => !item.isRequired || checkedTermsList.includes(item.id))
    )
  }, [checkedTermsList, termsList])

  //개별로 동의할때, 전부 동의하면 전체동의를 true로 변경, 아니면 false로 변경
  useEffect(() => {
    if (termsList === undefined) return
    setCheckAllTerms(checkedTermsList.length === termsList.length)
  }, [checkedTermsList.length, termsList])

  return {
    checkedAllTerms,
    toggleCheckedTerms,
    isAllRequiredChecked,
    toggleCheckAllTerms,
    checkedTermsList,
  }
}
