import { useMutation } from '@tanstack/react-query'

import { IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export interface IPostCollectionImageUploadRequest {
  collectionAddress: string
  body: FormData
}

export async function postCollectionImageUpload({
  collectionAddress,
  body,
}: IPostCollectionImageUploadRequest) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const url = `/api/v1/private/collections/${collectionAddress}/image-upload`
  const { data } = await withAxios<IBaseResponse<string>>({
    url,
    data: body,
    method: 'POST',
    headers,
  })
  return data
}

export function useCollectionImageUploadQuery() {
  return useMutation(['image-upload'], (params: IPostCollectionImageUploadRequest) =>
    postCollectionImageUpload({ ...params }),
  )
}
