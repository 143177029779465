import { useMutation } from '@tanstack/react-query'

import { IBaseResponse, IDeleteLikeItemRequest, IDeleteLikeItemResponse } from '@/shared/types'

import withAxios from '../api'

export async function deleteLikeItem({ itemId }: IDeleteLikeItemRequest) {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IDeleteLikeItemResponse>>({
    url: `/api/v1/private/items/${itemId}/likes`,
    method: 'DELETE',
  })

  return data
}

export function useDeleteLikeItem() {
  return useMutation(['deleteLikeItem'], ({ itemId }: { itemId: string }) =>
    deleteLikeItem({ itemId }),
  )
}
