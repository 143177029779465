import { useQuery } from '@tanstack/react-query'

import { ISeatEvent } from '@/shared/types'

import withAxios from '../api'

export const getSeatEvent = async ({
  originalsId,
  eventKey,
}: {
  originalsId?: string
  eventKey?: string
}): Promise<ISeatEvent> => {
  const url = `/api/v3/public/kkr-originals/originals/${originalsId}/seat-events/${eventKey}`

  const { data } = await withAxios<ISeatEvent>({
    url,
    method: 'GET',
  })

  return data
}

export const useGetSeatEvent = (originalsId?: string, eventKey?: string) =>
  useQuery(['useGetSeatEvent', { originalsId }], () => getSeatEvent({ originalsId, eventKey }), {
    refetchOnWindowFocus: false,
    enabled: originalsId !== undefined && eventKey !== undefined,
  })
