import { useMutation } from '@tanstack/react-query'

import { IBaseResponse, IGetOriginalsPaymentCallbackResponse } from '@/shared/types'

import withAxios from '../api'

export const postOriginalsFreePaymentsCallback = async ({
  formData,
}: {
  formData: URLSearchParams
}) => {
  const url = '/api/v2/private/kkr-payments/free/callback'
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IGetOriginalsPaymentCallbackResponse>>({
    url,
    data: formData,
    method: 'POST',
    headers,
  })
  return data
}

export const usePostOriginalsFreePaymentsCallback = () => {
  return useMutation(
    ['usePostOriginalsFreePaymentsCallback'],
    ({ formData }: { formData: URLSearchParams }) =>
      postOriginalsFreePaymentsCallback({ formData }),
  )
}
