import 'dayjs/locale/ko'

import dayjs from 'dayjs'
import { i18n } from 'next-i18next'

dayjs.locale('ko')

export const timeFormatter = (time: string, format: string) => {
  const language = i18n?.language ?? 'ko'

  return dayjs(time).locale(language).format(format)
}

export const differentDateTime = ({
  itemDateTime,
  t,
}: {
  itemDateTime: string
  t: (lng: string) => string
}) => {
  const itemDate = dayjs(itemDateTime)
  const currentDate = dayjs()

  const differentTime = currentDate.diff(itemDate)

  const years = itemDate.diff(currentDate, 'year')
  const month = itemDate.diff(currentDate, 'month')
  const days = itemDate.diff(currentDate, 'days')
  const hours = itemDate.diff(currentDate, 'hour')
  const minutes = itemDate.diff(currentDate, 'minute')
  const seconds = itemDate.diff(currentDate, 'second')

  if (differentTime < 0) {
    if (years !== 0) {
      return `${t('common:common.label-expires-in-years').replace('{value}', String(years))}`
    } else if (month !== 0) {
      return `${t('common:common.label-expires-in-months').replace('{value}', String(month))}`
    } else if (days !== 0) {
      return `${t('common:common.label-expires-in-days').replace('{value}', String(days))}`
    } else if (hours !== 0) {
      return `${t('common:common.label-expires-in-hours').replace('{value}', String(hours))}`
    } else if (minutes !== 0) {
      return `${t('common:common.label-expires-in-minutes').replace('{value}', String(minutes))}`
    } else if (seconds !== 0) {
      return `${t('common:common.label-expires-in-seconds').replace('{value}', String(seconds))}`
    }
  }
  if (differentTime > 0) {
    if (years !== 0) {
      return `${t('common:common.label-years-ago').replace('{value}', String(Math.abs(years)))}`
    } else if (month !== 0) {
      return `${t('common:common.label-months-ago').replace('{value}', String(Math.abs(month)))}`
    } else if (days !== 0) {
      return `${t('common:common.label-days-ago').replace('{value}', String(Math.abs(days)))}`
    } else if (hours !== 0) {
      return `${t('common:common.label-hours-ago').replace('{value}', String(Math.abs(hours)))}`
    } else if (minutes !== 0) {
      return `${t('common:common.label-minutes-ago').replace('{value}', String(Math.abs(minutes)))}`
    } else if (seconds !== 0) {
      return `${t('common:common.label-seconds-ago').replace('{value}', String(Math.abs(seconds)))}`
    }
  }
  return ''
}
