export const PORTONE_STATUS = {
  PAID: 'paid',
  PENDING: 'pending',
  FAILED: 'failed',
  READY: 'ready',
}

export const PAYMENTS_RESULT_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
}
