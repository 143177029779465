import { useQuery } from '@tanstack/react-query'

import { HighestOfferForItem, IHighestOfferResponse } from '@/shared/types/highestOffer'

import withAxios from '../api'

export const getHighestOffers = async (itemIds?: string[]): Promise<HighestOfferForItem> => {
  const url = '/api/v1/public/items/offer/highest-offers'
  const {
    data: { highestOffers },
  } = await withAxios<IHighestOfferResponse>({
    url,
    method: 'POST',
    data: { itemIds },
  })

  const highestOffersArray = highestOffers.map(highestOffer => [
    highestOffer.itemId,
    highestOffer.highestOffer,
  ])
  return Object.fromEntries(highestOffersArray)
}

export const useGetHighestOffers = (pathname: string, itemIds?: string[]) => {
  return useQuery([pathname, 'getHighestOffers', { itemIds }], () => getHighestOffers(itemIds), {
    enabled: itemIds !== undefined && itemIds.length > 0,
  })
}
