import { useMutation } from '@tanstack/react-query'

import { IOriginalsCheckoutPaymentsProps, IPostOriginalsCardPaymentsResponse } from '@/shared/types'

import withAxios from '../api'

export async function updateOriginalsCheckoutCardPayments({
  originalsId,
  currency,
  selectOptions,
  paymentType,
}: IOriginalsCheckoutPaymentsProps) {
  const url = '/api/v3/private/kkr-payments/originals-checkout/card'
  const { data } = await withAxios<IPostOriginalsCardPaymentsResponse>({
    url,
    method: 'POST',
    data: {
      originalsId,
      currency,
      selectOptions,
      paymentType,
    },
  })

  return data
}

export function useUpdateOriginalsCheckoutCardPayments() {
  return useMutation(
    ['useUpdateOriginalsCheckoutCardPayments'],
    ({ originalsId, currency, selectOptions, paymentType }: IOriginalsCheckoutPaymentsProps) =>
      updateOriginalsCheckoutCardPayments({
        originalsId,
        currency,
        selectOptions,
        paymentType,
      }),
  )
}
