import { useQuery } from '@tanstack/react-query'

import { IBaseResponse, IOriginalsVerifyResponse } from '@/shared/types'

import withAxios from '../api'

export const getOriginalsVerify = async ({ originalId }: { originalId?: string }) => {
  const url = `/api/v1/private/originals/${originalId}/verify`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IOriginalsVerifyResponse>>({ url, method: 'GET' })
  return data
}

export const useGetOriginalsVerify = (pathname: string, originalId?: string) =>
  useQuery(
    [pathname, 'getOriginalsVerify', { originalId }],
    () => getOriginalsVerify({ originalId }),
    {
      enabled: originalId !== undefined,
    },
  )
