import { useMemo } from 'react'

import { IOriginalsOption, IPurchasedItem } from '@/shared/types'

interface IUseDisabledOptionItem {
  originalsOptionList?: IOriginalsOption[]
  purchasedList: IPurchasedItem[]
  selectedOptionList: [] | IOriginalsOption[]
  productCount: string
  optionItem: IOriginalsOption
  maxQuantityPerOriginals?: number
}

export const useDisabledOptionItem = ({
  purchasedList,
  selectedOptionList,
  optionItem,
  maxQuantityPerOriginals,
}: IUseDisabledOptionItem) => {
  const isCurrentSelectedItem = useMemo(() => {
    return selectedOptionList.some(selectedOption => selectedOption.id === optionItem.id)
  }, [optionItem.id, selectedOptionList])

  /**
   * disable 조건 1: 현재 option이 이미 선택된 옵션들 중에서 선택 불가능한 옵션이라면 disable
   *   1-1 해당 옵션이 이미 선택된 옵션들의 선택불가능 리스트에 있을경우
   *   1-2 해당 옵션이 이미 구매한 옵션들의 선택 불가능 리스트에 있을경우
   */
  const isUnSelectableOptionItem = useMemo(() => {
    const allUnselectableOptionIds = [
      ...new Set(selectedOptionList.flatMap(option => option.unselectableOptionIds || [])),
      ...new Set(purchasedList.flatMap(option => option.unselectableOptionIds || [])),
    ]

    return allUnselectableOptionIds.includes(optionItem.id)
  }, [optionItem.id, purchasedList, selectedOptionList])

  /**
   * disable 조건 2: 현재 옵션의 재고가 없을 경우
   */
  const isSoldOut = useMemo(() => {
    return optionItem.stockCount < 1
  }, [optionItem.stockCount])

  /**
   * disable 조건 3: 현재 옵션을 유저가 보유할 수 있는만큼 이미 구매한 경우
   */
  const isOverMaxQuantityPerWallet = useMemo(() => {
    const matchedOptionItem = purchasedList.find(item => item.originalsOptionId === optionItem.id)

    const currentOptionItemTotalPurchaseQuantity = matchedOptionItem?.quantity ?? 0

    return optionItem.maxQuantityPerWallet <= currentOptionItemTotalPurchaseQuantity
  }, [optionItem.id, optionItem.maxQuantityPerWallet, purchasedList])

  /**
   * disable 조건 4: 현재 컬렉션에서 보유 가능한 최대 갯수를 넘었을때
   */
  const isOverTotalQuantityPerOriginals = useMemo(() => {
    if (maxQuantityPerOriginals === undefined) return false
    const totalQuantity = purchasedList.reduce((sum, item) => sum + item.quantity, 0)
    return totalQuantity >= maxQuantityPerOriginals
  }, [maxQuantityPerOriginals, purchasedList])

  /**
   * disable 조건 1,2,3,4 중 하나에 해당하면 disable 처리
   */
  const isDisabled = useMemo(() => {
    return (
      isUnSelectableOptionItem ||
      isSoldOut ||
      isOverMaxQuantityPerWallet ||
      isOverTotalQuantityPerOriginals
    )
  }, [
    isOverMaxQuantityPerWallet,
    isOverTotalQuantityPerOriginals,
    isSoldOut,
    isUnSelectableOptionItem,
  ])

  return {
    isDisabled,
    isSoldOut,
    isOverMaxQuantityPerWallet,
    isCurrentSelectedItem,
    isUnSelectableOptionItem,
    isOverTotalQuantityPerOriginals,
  }
}
