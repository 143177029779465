import { useMutation } from '@tanstack/react-query'

import { IOriginalsPortOnePaymentsCallbackResponse } from '@/shared/types'

import withAxios from '../api'

export const postOriginalsPortOnePaymentsCallback = async ({
  imp_uid,
  merchant_uid,
}: {
  imp_uid: string
  merchant_uid: string
}) => {
  const url = '/api/v3/private/kkr-payments/seats/portone-callback'

  const { data } = await withAxios<IOriginalsPortOnePaymentsCallbackResponse>({
    url,
    data: { imp_uid, merchant_uid },
    method: 'POST',
  })
  return data
}

export const usePostOriginalsPortOnePaymentsCallback = () => {
  return useMutation(
    ['usePostOriginalsPortOnePaymentsCallback'],
    ({ imp_uid, merchant_uid }: { imp_uid: string; merchant_uid: string }) =>
      postOriginalsPortOnePaymentsCallback({ imp_uid, merchant_uid }),
  )
}
