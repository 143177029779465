import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import {
  IBaseResponse,
  IGetOriginalsListDebouncedQueryParams,
  IGetOriginalsListRequestParams,
  IOriginals,
  IOriginalsListResponse,
} from '@/shared/types'

import withAxios from '../api'

export const getOriginalsList = async (params: IGetOriginalsListRequestParams) => {
  const url = '/api/v1/public/originals'
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IOriginalsListResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const getOriginalsClaimItem = async (collectionAddress?: string) => {
  const url = `/api/v1/public/originals/${collectionAddress}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IOriginals>>({ url, method: 'GET' })
  return data
}

export const useGetOriginalsClaimItem = (
  pathname: string,
  collectionAddress?: string,
  isClaimOriginalsItem?: boolean,
) => {
  return useQuery(
    [pathname, 'getOriginalsItemList'],
    () => getOriginalsClaimItem(collectionAddress),
    {
      enabled:
        isClaimOriginalsItem !== undefined &&
        isClaimOriginalsItem &&
        collectionAddress !== undefined,
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetOriginalsListInfiniteQuery = (
  pathname: string,
  debouncedQueryParams?: IGetOriginalsListDebouncedQueryParams,
) =>
  useInfiniteQuery<IOriginalsListResponse>(
    [pathname, 'getOriginalsListInfiniteQuery', { debouncedQueryParams }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 20,
      } as IGetOriginalsListRequestParams
      const response = await getOriginalsList(queryParams)
      return response
    },
    {
      getNextPageParam: data =>
        (data?.originalsCollection?.length === 20 && data.page + 1) ?? undefined,
    },
  )

export const useGetOriginalsList = (
  pathname: string,
  queryParams: IGetOriginalsListRequestParams,
) => useQuery([pathname, 'getOriginalsList', { queryParams }], () => getOriginalsList(queryParams))
