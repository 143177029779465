import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IOriginalsRankingGuideData } from '@/shared/types'

import { AppState } from '..'

export interface IOriginalsState {
  originalsRankingGuideData: IOriginalsRankingGuideData | null
  waitingRoomConnectionId: string | null
  buttonQrLink: string | null
  metadataKey: string | null
}

const initialState: IOriginalsState = {
  originalsRankingGuideData: null,
  waitingRoomConnectionId: null,
  buttonQrLink: null,
  metadataKey: null,
}

export const originalsSlice = createSlice({
  name: 'originals',
  initialState,
  reducers: {
    resetOriginalsState: state => {
      state.originalsRankingGuideData = null
      state.waitingRoomConnectionId = null
      state.buttonQrLink = null
      state.metadataKey = null
    },
    setOriginalsRankingGuideData: (
      state,
      action: PayloadAction<IOriginalsRankingGuideData | null>,
    ) => {
      state.originalsRankingGuideData = action.payload
    },
    setWaitingRoomConnectionId: (state, action: PayloadAction<string | null>) => {
      state.waitingRoomConnectionId = action.payload
    },
    setButtonQrLink: (state, action: PayloadAction<string | null>) => {
      state.buttonQrLink = action.payload
    },
    setMetadataKey: (state, action: PayloadAction<string | null>) => {
      state.metadataKey = action.payload
    },
  },
})

export const selectOriginalsRankingGuideData = (state: AppState) =>
  state.originals.originalsRankingGuideData
export const selectWaitingRoomConnectionId = (state: AppState) =>
  state.originals.waitingRoomConnectionId
export const selectButtonQrLink = (state: AppState) => state.originals.buttonQrLink
export const selectMetadataKey = (state: AppState) => state.originals.metadataKey

export const {
  resetOriginalsState,
  setOriginalsRankingGuideData,
  setWaitingRoomConnectionId,
  setButtonQrLink,
  setMetadataKey,
} = originalsSlice.actions

export default originalsSlice
