import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LISTING_PAYMENT_TYPE } from '@/shared/constants'
import { ListingPaymentType } from '@/shared/types'
import {
  FilterChip,
  IChipListingType,
  IChipMaxPriceType,
  IChipMinPriceType,
  SortType,
} from '@/shared/types/filter'
import { isValidPrice } from '@/shared/utils'

export interface ICollectionItemsFilterState {
  isListing: boolean
  filterPropertyIds: Array<{ id: number; chipName: string }>
  paymentType?: ListingPaymentType
  paymentTypeList: ListingPaymentType[] | null
  minPrice?: string
  maxPrice?: string
  chips: FilterChip[]
  order?: string
  orderBy: SortType
  isClickChip: boolean
  isFilterApplied: boolean
}

export const collectionItemsFilterState: ICollectionItemsFilterState = {
  isListing: false,
  filterPropertyIds: [],
  paymentType: LISTING_PAYMENT_TYPE.POL,
  paymentTypeList: [],
  chips: [],
  order: undefined,
  orderBy: 'ASC',
  isFilterApplied: false,
  isClickChip: false,
}

export const collectionItemsFilterSlice = createSlice({
  name: 'collectionItemsFilter',
  initialState: collectionItemsFilterState,
  reducers: {
    initCollectionItemsFilter: () => collectionItemsFilterState,
    setIsClickChip: (state, action) => {
      state.isClickChip = action.payload
    },
    setOrderAndOrderBy: (state, action) => {
      state.order = action.payload.order
      state.orderBy = action.payload.orderBy
    },
    setPaymentTypeList: (state, action: PayloadAction<ListingPaymentType[] | null>) => {
      state.paymentTypeList = action.payload
    },
    toggleIsListing: state => {
      state.isListing = !state.isListing

      if (state.isListing) {
        const newChip = {
          type: 'listing',
          isListing: state.isListing,
        } as IChipListingType
        state.chips = [...state.chips, { listingChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.listingChip?.type !== 'listing'
        })
      }
    },
    initPrice: state => {
      state.minPrice = undefined
      state.maxPrice = undefined
      state.chips = state.chips
        .filter(chip => chip.maxPriceChip === undefined)
        .filter(chip => chip.minPriceChip === undefined)
    },
    setPaymentType: (state, action) => {
      const oldPaymentType = state.paymentType
      const newPaymentType = action.payload.paymentType
      state.paymentType = newPaymentType

      if (oldPaymentType !== newPaymentType) {
        state.maxPrice = ''
        state.minPrice = ''

        state.chips = state.chips.filter(chip => {
          return chip?.maxPriceChip?.type !== 'maxPrice'
        })
        state.chips = state.chips.filter(chip => {
          return chip?.minPriceChip?.type !== 'minPrice'
        })
      }
    },
    setMinPrice: (state, action) => {
      if (state.paymentType === undefined) {
        return
      }

      if (!isValidPrice(action.payload, state.paymentType)) {
        return
      }

      state.minPrice = action.payload

      if (state.minPrice) {
        const newChip = {
          type: 'minPrice',
          price: state.minPrice,
          paymentType: state.paymentType,
        } as IChipMinPriceType

        state.chips = state.chips.filter(chip => {
          return chip?.minPriceChip?.type !== 'minPrice'
        })

        state.chips = [...state.chips, { minPriceChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.minPriceChip?.type !== 'minPrice'
        })
      }
    },
    setMaxPrice: (state, action) => {
      if (state.paymentType === undefined) {
        return
      }

      if (!isValidPrice(action.payload, state.paymentType)) {
        return
      }

      state.maxPrice = action.payload

      if (state.maxPrice) {
        const newChip = {
          type: 'maxPrice',
          price: state.maxPrice,
          paymentType: state.paymentType,
        } as IChipMaxPriceType

        state.chips = state.chips.filter(chip => {
          return chip?.maxPriceChip?.type !== 'maxPrice'
        })
        state.chips = [...state.chips, { maxPriceChip: newChip }]
      } else {
        state.chips = state.chips.filter(chip => {
          return chip?.maxPriceChip?.type !== 'maxPrice'
        })
      }
    },
    removeChip: (state, action) => {
      const chipToRemove = action.payload
      if (Object.keys(chipToRemove)[0] === 'listingChip') {
        state.isListing = false
      }
      if (Object.keys(chipToRemove)[0] === 'minPriceChip') {
        state.minPrice = ''
      }
      if (Object.keys(chipToRemove)[0] === 'maxPriceChip') {
        state.maxPrice = ''
      }

      state.chips = state.chips.filter(chip => {
        return JSON.stringify(chip) !== JSON.stringify(chipToRemove)
      })
    },
    setFilterPropertyIds: (
      state,
      action: PayloadAction<Array<{ id: number; chipName: string }>>,
    ) => {
      state.filterPropertyIds = action.payload
    },
    removePropertyFilter: (state, action: PayloadAction<number>) => {
      state.filterPropertyIds = state.filterPropertyIds.filter(item => item.id !== action.payload)
    },
  },
})
