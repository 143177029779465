import { useQuery } from '@tanstack/react-query'

import { IRoyaltyInfo } from '@/shared/types'

import withAxios from '../api'

export const getRoyaltyInfo = async (itemId?: string) => {
  const { data } = await withAxios<IRoyaltyInfo>({
    url: `/api/v1/private/items/${itemId?.toLowerCase()}/royalty-infos`,
    method: 'GET',
  })
  return data
}

export const useGetRoyaltyInfo = (pathname: string, itemId?: string) => {
  return useQuery(
    [pathname, 'getRoyaltyInfo', { itemId }],
    async () => await getRoyaltyInfo(itemId),
    { enabled: itemId !== undefined },
  )
}
