import { IOfferItem } from '@/shared/types'
import { fulfillOrder, makeOrderWithCounterForOffer } from '@/shared/utils'

import { AppThunk } from '..'
import {
  getAcceptItemInfo,
  resetAcceptState,
  resetSignatureState,
  setIsAcceptError,
  setIsAcceptFailedForUserDenied,
  setIsAcceptSigning,
  setIsAcceptSuccessful,
  setTxHashForAccept,
} from '../trades'

export const resetAcceptStates = (): AppThunk => async dispatch => {
  dispatch(resetSignatureState())
  dispatch(resetAcceptState())
}

export const acceptDialogOpen =
  ({
    itemId,
    collectionTitle,
    collectionAddress,
    tokenId,
    itemTitle,
    mediaUrl,
    offerItem,
    chainId,
    handleAcceptSummaryDialog,
  }: {
    itemId: string
    collectionTitle: string
    collectionAddress: string
    tokenId: string
    itemTitle: string
    mediaUrl: string
    offerItem: IOfferItem
    chainId: string
    handleAcceptSummaryDialog: (acceptSummaryDialogIsOpen: boolean) => void
  }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(resetAcceptStates())

    await dispatch(
      getAcceptItemInfo({
        itemId,
        collectionTitle,
        collectionAddress,
        tokenId,
        itemTitle,
        mediaUrl,
        offerItem,
        chainId,
      }),
    )

    const acceptItemInfo = getState().accept.acceptItemInfo
    if (acceptItemInfo === null || acceptItemInfo.offerItem === null) return

    handleAcceptSummaryDialog(true)
  }

export const signForSellOrder = (): AppThunk => async (dispatch, getState) => {
  const currentAccountAddress = getState().wallet.currentProfile?.accountAddress ?? null
  const acceptItemInfo = getState().accept.acceptItemInfo

  if (currentAccountAddress === null) {
    return dispatch(setIsAcceptError('No current account address'))
  }

  if (acceptItemInfo === null) {
    return dispatch(setIsAcceptError('No accept item info'))
  }

  if (acceptItemInfo.offerItem === null) {
    return dispatch(setIsAcceptError('No offer item info'))
  }

  const { tokenId, offerItem } = acceptItemInfo
  const {
    offer: { tradeAddress },
  } = offerItem

  const order = makeOrderWithCounterForOffer(String(tokenId), offerItem.offer.signHash, offerItem)

  await fulfillOrder(
    acceptItemInfo.chainId,
    currentAccountAddress,
    order,
    tradeAddress,
    (txHash: string) => dispatch(setTxHashForAccept(txHash)),
    () => dispatch(setIsAcceptSuccessful(true)),
    (loading: boolean) => dispatch(setIsAcceptSigning(loading)),
    (error: string) => dispatch(setIsAcceptError(error)),
    () => dispatch(setIsAcceptFailedForUserDenied(true)),
  )
}

export const retryAccept =
  (
    handleWalletWaitingForAcceptDialog: (walletWaitingForAcceptDialogIsOpen: boolean) => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const isAcceptError = getState().accept.isAcceptError
    const isAcceptFailedForUserDenied = getState().accept.isAcceptFailedForUserDenied

    if (isAcceptError || isAcceptFailedForUserDenied) {
      dispatch(setIsAcceptError(null))
      handleWalletWaitingForAcceptDialog(true)
    }
  }
