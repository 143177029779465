import { createSlice } from '@reduxjs/toolkit'

import { IChipCollectionType, ICollection, OrderByType } from '@/shared/types'

export interface IAccountOfferFilterState {
  collections: IChipCollectionType[]
  order?: string
  orderBy: OrderByType
  selectedCollectionIds: string[]
  ownCollections: ICollection[]
  isClickCollection: boolean
}

export const accountOfferFilterState: IAccountOfferFilterState = {
  order: undefined,
  orderBy: 'DESC',
  collections: [],
  ownCollections: [],
  selectedCollectionIds: [],
  isClickCollection: false,
}

// '보낸 제안', '받은제안' 관련된 필터 값을 함께 사용하고 있습니다.
export const accountOfferFilterSlice = createSlice({
  name: 'accountSentOfferFilter',
  initialState: accountOfferFilterState,
  reducers: {
    initAccountSentOfferFilter: () => accountOfferFilterState,
    setIsClickCollection: (state, action) => {
      state.isClickCollection = action.payload
    },
    setOrderAndOrderBy: (state, action) => {
      state.order = action.payload.order
      state.orderBy = action.payload.orderBy
    },
    setOwnCollections: (state, action) => {
      state.ownCollections = action.payload || []
    },
    toggleSelectedCollection: (state, action) => {
      const collectionAddress = action.payload.collectionAddress
      if (state.selectedCollectionIds.includes(collectionAddress)) {
        state.selectedCollectionIds = state.selectedCollectionIds.filter(
          id => id !== collectionAddress,
        )
        state.collections = state.collections.filter(
          collection => collection.address !== collectionAddress,
        )
      } else {
        const tmp = state.ownCollections.filter(
          collection => collection.collectionAddress === collectionAddress,
        )
        const selectedCollection = tmp.length > 0 ? tmp[0] : undefined
        if (selectedCollection) {
          state.selectedCollectionIds = [...state.selectedCollectionIds, collectionAddress]
          state.collections = [
            ...state.collections,
            {
              name: selectedCollection.title,
              address: selectedCollection.collectionAddress,
              type: 'collection',
            } as IChipCollectionType,
          ]
        }
      }
    },
    removeCollectionFilter: (state, action) => {
      const collectionAddress = action.payload
      state.collections = state.collections.filter(
        collection => collection.address !== collectionAddress,
      )
      state.selectedCollectionIds = state.selectedCollectionIds.filter(
        address => address !== collectionAddress,
      )
    },
  },
})
