import { TRADE_TYPE } from '../constants'
import { ActivityFilterType } from '../types'

export const TRADE_TYPES_MAPPING = {
  LISTING: [TRADE_TYPE.LISTING, TRADE_TYPE.LISTING_CANCEL],
  OFFER: [TRADE_TYPE.OFFER, TRADE_TYPE.OFFER_CANCEL],
  SALE: [TRADE_TYPE.LISTING_SALE, TRADE_TYPE.OFFER_SALE],
}

export const createNewActivityTypes = (
  filterType: ActivityFilterType,
  tradeTypes: ActivityFilterType[],
) => {
  let selectedActivityTypes: ActivityFilterType[]

  const typeIndex = tradeTypes.indexOf(filterType)
  if (typeIndex < 0) {
    selectedActivityTypes = [...tradeTypes, filterType]
  } else {
    selectedActivityTypes = [...tradeTypes.slice(0, typeIndex), ...tradeTypes.slice(typeIndex + 1)]
  }

  return { selectedActivityTypes }
}
