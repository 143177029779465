import { useQuery } from '@tanstack/react-query'

import { IBaseResponse, IMetadataValueForOriginalsItem } from '@/shared/types'

import withAxios from '../api'

export const getMetadataValueForOriginalsItem = async ({
  metadataKey,
}: {
  originalsId?: string
  metadataKey?: string
}) => {
  const url = `/api/v3/public/kkr-originals/metadata/originals-options/${metadataKey}`
  const { data } = await withAxios<IMetadataValueForOriginalsItem>({
    url,
  })
  return data
}

export const useGetMetadataValueForOriginalsItem = (pathname: string, metadataKey?: string) =>
  useQuery(
    [pathname, 'getMetadataValueForOriginalsItem', { metadataKey }],
    () => getMetadataValueForOriginalsItem({ metadataKey }),
    {
      enabled: metadataKey !== undefined,
    },
  )
