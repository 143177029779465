import { useQuery, UseQueryResult } from '@tanstack/react-query'
import bigDecimal from 'js-big-decimal'

import { IBaseResponse, IConvertedCollectionForEdit, IGetCollectionResponse } from '@/shared/types'

import withAxios from '../api'

export const getCollection = async (
  collectionAddress?: string,
): Promise<IGetCollectionResponse> => {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IGetCollectionResponse>>({
    url: `/api/v1/public/collections/${collectionAddress}`,
    method: 'GET',
  })
  return data
}

export const useGetCollection = (
  pathname: string,
  collectionAddress?: string,
): UseQueryResult<IConvertedCollectionForEdit> => {
  return useQuery(
    [pathname, 'getCollection', { collectionAddress }],
    async () => getCollection(collectionAddress),
    {
      enabled: collectionAddress !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      select: data => ({
        ...data,
        collectionRoyalties: data.collectionRoyalties.map(royalty => ({
          ...royalty,
          percentage: bigDecimal.multiply(royalty.rate, '100'),
        })),
      }),
    },
  )
}
