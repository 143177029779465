import { useQuery } from '@tanstack/react-query'

import { IOriginalsCheckWhiteList } from '@/shared/types'

import withAxios from '../api'

export const getCheckWhiteList = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/v3/private/kkr-originals/originals/${originalsId}/white-list`
  const { data } = await withAxios<IOriginalsCheckWhiteList>({ url, method: 'GET' })

  return data
}

export const useGetCheckWhiteList = (
  pathname: string,
  isConnectedWallet: boolean,
  originalsId?: string,
) =>
  useQuery(
    [pathname, 'getCheckWhiteList', { originalsId }],
    () => getCheckWhiteList({ originalsId }),
    {
      enabled: originalsId !== undefined && isConnectedWallet === true,
    },
  )
