import dynamic from 'next/dynamic'

const CanNotUsePaymentDialogSSR = dynamic(() => import('./CanNotUsePaymentDialog'), { ssr: false })
const CheckoutImpossibleDialogSSR = dynamic(() => import('./CheckoutImpossibleDialog'), {
  ssr: false,
})
const CheckoutNotWhiteListDialogSSR = dynamic(() => import('./CheckoutNotWhiteListDialog'), {
  ssr: false,
})
const SoldOutDialogSSR = dynamic(() => import('./SoldOutDialog'), { ssr: false })
const WaitingForPaymentsDialogSSR = dynamic(() => import('./WaitingForPaymentsDialog'), {
  ssr: false,
})
const WaitingOrderDialogSSR = dynamic(() => import('./WaitingOrderDialog'), { ssr: false })
const TermsDialogSSR = dynamic(() => import('./TermsDialog'), { ssr: false })

export {
  CanNotUsePaymentDialogSSR,
  CheckoutImpossibleDialogSSR,
  CheckoutNotWhiteListDialogSSR,
  SoldOutDialogSSR,
  TermsDialogSSR,
  WaitingForPaymentsDialogSSR,
  WaitingOrderDialogSSR,
}
