import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SEARCH_RESULT_TAB_KEY } from '@/shared/constants/search'

import { AppState } from '..'

export interface ISearchState {
  searchText: string
  currentTab: keyof typeof SEARCH_RESULT_TAB_KEY
}

const initialState: ISearchState = {
  searchText: '',
  currentTab: SEARCH_RESULT_TAB_KEY.all,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setCurrentTab: (state, action: PayloadAction<keyof typeof SEARCH_RESULT_TAB_KEY>) => {
      state.currentTab = action.payload
    },
  },
})

export const { setCurrentTab, setSearchText } = searchSlice.actions

export const selectCurrentTab = (state: AppState) => state.search.currentTab
export const selectSearchText = (state: AppState) => state.search.searchText

export default searchSlice
