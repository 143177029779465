import axios from 'axios'

export const exchangingAccessToken = async (
  refreshToken: string,
): Promise<
  | {
      accessToken: string
      refreshToken: string
      refreshTokenExpiresAt: number
    }
  | undefined
> => {
  const response = await axios.post('/api/oauth/token', {
    grantType: 'refresh_token',
    refreshToken: refreshToken,
  })
  const { access_token, refresh_token } = response.data
  const currentTimestamp = new Date().getTime()
  const refreshTokenExpiresAt =
    currentTimestamp + Number(process.env.OAUTH_REFRESH_TOKEN_EXPIRES_IN)
  return { accessToken: access_token, refreshToken: refresh_token, refreshTokenExpiresAt }
}
