import { useState } from 'react'

import { IGetCollectionCategoriesResponse } from '@/shared/services/collections'
import { makeCategoryTabList } from '@/shared/utils'

const useCategoryTabList = ({ data }: { data: IGetCollectionCategoriesResponse | undefined }) => {
  const INIT_CATEGORY_ID = ''
  const [currentTab, setCurrentTab] = useState<string | undefined>(INIT_CATEGORY_ID)

  const tabList = makeCategoryTabList({ data: data?.categories, handler: setCurrentTab })

  return {
    tabList,
    currentTab,
  }
}

export default useCategoryTabList
