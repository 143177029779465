import { useDebouncedValue } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { ManagedDialog } from '../constants'
import { useGetCurrentAccountReceivedOfferCollections } from '../services/accounts'
import { accountOfferFilterSlice, accountOfferFilterState } from '../store/filters'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { acceptDialogOpen } from '../store/thunks/thunksForAccept'
import { selectCurrentAccountAddress } from '../store/wallet'
import { ICollection, IReceivedOfferItem } from '../types'
import { useDialog } from './useDialog'
import { useRefresh } from './useRefresh'

// TODO @고영욱 필터 관련 코드(현재 사용X)
export const useAccountReceivedOffer = () => {
  const { pathname } = useRouter()
  const appDispatch = useAppDispatch()
  const currentAccountAddress = useAppSelector(selectCurrentAccountAddress)
  const { handleSpecificDialog } = useDialog()

  const handleAcceptSummaryDialog = handleSpecificDialog(ManagedDialog.acceptSummary)

  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const { refresh, refreshRefetch, setRefetchFunc } = useRefresh()

  const [state, dispatch] = useReducer(accountOfferFilterSlice.reducer, accountOfferFilterState)

  const { data: receivedOfferCollections } = useGetCurrentAccountReceivedOfferCollections(
    pathname,
    currentAccountAddress,
  )

  const {
    setOwnCollections,
    removeCollectionFilter,
    toggleSelectedCollection,
    initAccountSentOfferFilter,
  } = accountOfferFilterSlice.actions

  const memoizedQueryParams = useMemo(
    () => ({
      order: state.order,
      orderBy: state.orderBy,
      collectionAddresses: state.collections
        .map(collection => collection.address)
        .filter(Boolean)
        .join(','),
    }),
    [state],
  )

  const [debouncedQueryParams] = useDebouncedValue(memoizedQueryParams, 200)
  const [filterQueryParams, setFilterQueryParams] = useState(debouncedQueryParams)

  useEffect(() => {
    dispatch(setOwnCollections(receivedOfferCollections?.collections))
  }, [receivedOfferCollections?.collections, setOwnCollections])

  const handleFilterClear = useCallback(
    ({ isFetch = true }: { isFetch: boolean }) => {
      dispatch(initAccountSentOfferFilter())
      dispatch(setOwnCollections(receivedOfferCollections?.collections))

      if (isFetch) {
        setFilterQueryParams({
          order: undefined,
          orderBy: 'DESC',
          collectionAddresses: '',
        })
      }
    },
    [receivedOfferCollections?.collections, initAccountSentOfferFilter, setOwnCollections],
  )

  const handleCollectionClick = useCallback(
    (collection: ICollection) => dispatch(toggleSelectedCollection(collection)),
    [toggleSelectedCollection],
  )

  const handleRemoveCollectionFilter = useCallback(
    (collectionAddress: string) => {
      dispatch(removeCollectionFilter(collectionAddress))
    },
    [removeCollectionFilter],
  )

  const handleAcceptOfferItem = useCallback(
    ({ receivedOfferInfo }: { receivedOfferInfo: IReceivedOfferItem }) => {
      appDispatch(
        acceptDialogOpen({
          offerItem: receivedOfferInfo.offerInfo,
          itemId: receivedOfferInfo.id,
          tokenId: receivedOfferInfo.tokenId,
          itemTitle: receivedOfferInfo.title,
          chainId: receivedOfferInfo.chainId,
          mediaUrl: receivedOfferInfo.mediaUrl,
          collectionTitle: receivedOfferInfo.collectionTitle,
          collectionAddress: receivedOfferInfo.collectionAddress,
          handleAcceptSummaryDialog,
        }),
      )
    },
    [appDispatch, handleAcceptSummaryDialog],
  )

  return {
    state,
    refresh,
    handleFilterClear,
    refreshRefetch,
    setRefetchFunc,
    isFilterVisible,
    setIsFilterVisible,
    filterQueryParams,
    setFilterQueryParams,
    debouncedQueryParams,
    handleCollectionClick,
    handleAcceptOfferItem,
    handleRemoveCollectionFilter,
    collectionList: receivedOfferCollections?.collections,
  }
}
