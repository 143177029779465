import { IUserAgeResponse } from '@/shared/types'

import withAxios from '../api'

export const getUserAge = async (eventDate?: string) => {
  try {
    const url = '/api/v3/private/kkr-auth/user/age'
    const { data } = await withAxios<IUserAgeResponse>({
      url,
      method: 'GET',
      params: { eventDate },
    })

    return data
  } catch (e) {
    return {
      isVerified: false,
      age: null,
    }
  }
}
