import { useQuery } from '@tanstack/react-query'

import { IBaseResponse, IMarketInfo } from '@/shared/types'

import withAxios from '../api'

export const getMarketsInfo = async () => {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IMarketInfo>>({
    url: '/api/v1/public/markets/info',
    method: 'GET',
  })
  return data
}

export const useMarketsInfoQuery = (pathname: string) => {
  return useQuery([pathname, 'getMarketsInfo'], async () => {
    return await getMarketsInfo()
  })
}
