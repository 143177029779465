import { useForm } from 'react-hook-form'

import { CollectionEditFormFields, IConvertedCollectionForEdit } from '../types'

export const useCollectionEditForm = ({
  collection,
}: {
  collection: IConvertedCollectionForEdit
}) => {
  const collectionRoyaltiesFields = collection.collectionRoyalties?.map(collectionRoyalty => ({
    accountAddress: collectionRoyalty.accountAddress,
    rate: String(collectionRoyalty.rate),
    percentage: collectionRoyalty.percentage,
  }))
  return useForm<CollectionEditFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      collectionRoyalties: collectionRoyaltiesFields,
      imgUrl: collection.imgUrl ?? '',
      backgroundImgUrl: collection.backgroundImgUrl ?? '',
      website: collection.website ?? '',
      discord: collection.discord ?? '',
      twitter: collection.twitter ?? '',
      description: collection.description ?? '',
    },
  })
}
