import { ManagedDialog } from '@/shared/constants'

import {
  ApplyDialogSSR,
  ChangeRegionSettingsDialogSSR,
  CheckRaffleResultDialogSSR,
  CollectionReportDialogSSR,
  ConnectWalletDialogSSR,
  DisconnectedWalletDialogSSR,
  ExceedTransferDialogSSR,
  GoAppHomeDialogSSR,
  NoticeCopyAddressDialogSSR,
  OriginalsRankingGuideDialogSSR,
  ProfileEditDialogSSR,
  PurchaseCancelDialogSSR,
  QrDialogSSR,
  SwapChainSelectDialogSSR,
  SwapDialogSSR,
  TradeSupportItemDialogSSR,
  TransferFailDialogSSR,
  TransferFormDialogSSR,
  TxProgressForTransferDialogSSR,
  WalletWaitingForTransferDialogSSR,
} from './managed-dialogs'
import {
  CanNotUsePaymentDialogSSR,
  CheckoutImpossibleDialogSSR,
  CheckoutNotWhiteListDialogSSR,
  SoldOutDialogSSR,
  TermsDialogSSR,
  WaitingForPaymentsDialogSSR,
  WaitingOrderDialogSSR,
} from './managed-dialogs/payment'
import {
  AcceptFailDialogSSR,
  AcceptSummaryDialogSSR,
  LackOfBalanceForPurchaseDialogSSR,
  ListingCancelDialogSSR,
  ListingCompleteDialogSSR,
  ListingEditOrCancelDialogSSR,
  ListingFailDialogSSR,
  ListingFormDialogSSR,
  ListingSummaryDialogSSR,
  OfferCancelDialogSSR,
  OfferCompleteDialogSSR,
  OfferEditOrCancelDialogSSR,
  OfferFailDialogSSR,
  OfferFormDialogSSR,
  OfferSummaryDialogSSR,
  PurchaseFailDialogSSR,
  PurchaseSummaryDialogSSR,
  TxProgressForAcceptDialogSSR,
  TxProgressForCancelDialogSSR,
  TxProgressForPurchaseDialogSSR,
  UnSupportTradeDialogSSR,
  WaitingForReceivedOfferedItemDialogSSR,
  WalletWaitingForAcceptDialogSSR,
  WalletWaitingForListingCancelDialogSSR,
  WalletWaitingForListingDialogSSR,
  WalletWaitingForOfferCancelDialogSSR,
  WalletWaitingForOfferDialogSSR,
  WalletWaitingForPurchaseDialogSSR,
} from './managed-dialogs/trade'

export interface IDialogManagerProps {
  dialog: ManagedDialog | null
  closeDialog: () => void
}

const DialogManager = ({ dialog, closeDialog }: IDialogManagerProps) => {
  return (
    <>
      <CanNotUsePaymentDialogSSR
        isOpened={dialog === ManagedDialog.canNotUsePayment}
        closeDialog={closeDialog}
      />
      <CheckoutImpossibleDialogSSR
        isOpened={dialog === ManagedDialog.checkoutImpossible}
        closeDialog={closeDialog}
      />
      <CheckoutNotWhiteListDialogSSR
        isOpened={dialog === ManagedDialog.checkoutNotWhitelist}
        closeDialog={closeDialog}
      />
      <SoldOutDialogSSR isOpened={dialog === ManagedDialog.soldout} closeDialog={closeDialog} />

      <WaitingForPaymentsDialogSSR
        isOpened={dialog === ManagedDialog.waitingForPayments}
        closeDialog={closeDialog}
      />
      <WaitingOrderDialogSSR
        isOpened={dialog === ManagedDialog.waitingOrder}
        closeDialog={closeDialog}
      />
      <AcceptFailDialogSSR
        isOpened={dialog === ManagedDialog.acceptFail}
        closeDialog={closeDialog}
      />
      <AcceptSummaryDialogSSR
        isOpened={dialog === ManagedDialog.acceptSummary}
        closeDialog={closeDialog}
      />
      <LackOfBalanceForPurchaseDialogSSR
        isOpened={dialog === ManagedDialog.lackOfBalanceForPurchase}
        closeDialog={closeDialog}
      />
      <ListingCancelDialogSSR
        isOpened={dialog === ManagedDialog.listingCancel}
        closeDialog={closeDialog}
      />
      <ListingCompleteDialogSSR
        isOpened={dialog === ManagedDialog.listingComplete}
        closeDialog={closeDialog}
      />
      <ListingEditOrCancelDialogSSR
        isOpened={dialog === ManagedDialog.listingEditOrCancel}
        closeDialog={closeDialog}
      />
      <ListingFailDialogSSR
        isOpened={dialog === ManagedDialog.listingFail}
        closeDialog={closeDialog}
      />
      <ListingFormDialogSSR
        isOpened={dialog === ManagedDialog.listingForm}
        closeDialog={closeDialog}
      />
      <ListingSummaryDialogSSR
        isOpened={dialog === ManagedDialog.listingSummary}
        closeDialog={closeDialog}
      />
      <OfferCancelDialogSSR
        isOpened={dialog === ManagedDialog.offerCancel}
        closeDialog={closeDialog}
      />
      <OfferCompleteDialogSSR
        isOpened={dialog === ManagedDialog.offerComplete}
        closeDialog={closeDialog}
      />
      <OfferEditOrCancelDialogSSR
        isOpened={dialog === ManagedDialog.offerEditOrCancel}
        closeDialog={closeDialog}
      />

      <WaitingForReceivedOfferedItemDialogSSR
        isOpened={dialog === ManagedDialog.waitingForReceivedOfferedItem}
        closeDialog={closeDialog}
      />
      <OfferFailDialogSSR isOpened={dialog === ManagedDialog.offerFail} closeDialog={closeDialog} />
      <OfferFormDialogSSR isOpened={dialog === ManagedDialog.offerForm} closeDialog={closeDialog} />
      <OfferSummaryDialogSSR
        isOpened={dialog === ManagedDialog.offerSummary}
        closeDialog={closeDialog}
      />
      <PurchaseFailDialogSSR
        isOpened={dialog === ManagedDialog.purchaseFail}
        closeDialog={closeDialog}
      />
      <PurchaseSummaryDialogSSR
        isOpened={dialog === ManagedDialog.purchaseSummary}
        closeDialog={closeDialog}
      />
      <TxProgressForAcceptDialogSSR
        isOpened={dialog === ManagedDialog.txProgressForAccept}
        closeDialog={closeDialog}
      />
      <TxProgressForCancelDialogSSR
        isOpened={dialog === ManagedDialog.txProgressForCancel}
        closeDialog={closeDialog}
      />
      <TxProgressForPurchaseDialogSSR
        isOpened={dialog === ManagedDialog.txProgressForPurchase}
        closeDialog={closeDialog}
      />
      <WalletWaitingForAcceptDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForAccept}
        closeDialog={closeDialog}
      />
      <WalletWaitingForListingCancelDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForListingCancel}
        closeDialog={closeDialog}
      />
      <WalletWaitingForListingDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForListing}
        closeDialog={closeDialog}
      />
      <WalletWaitingForOfferCancelDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForOfferCancel}
        closeDialog={closeDialog}
      />
      <WalletWaitingForOfferDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForOffer}
        closeDialog={closeDialog}
      />
      <WalletWaitingForPurchaseDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForPurchase}
        closeDialog={closeDialog}
      />
      <CollectionReportDialogSSR
        isOpened={dialog === ManagedDialog.collectionReport}
        closeDialog={closeDialog}
      />
      <ConnectWalletDialogSSR
        isOpened={dialog === ManagedDialog.connectWallet}
        closeDialog={closeDialog}
      />
      <DisconnectedWalletDialogSSR
        isOpened={dialog === ManagedDialog.disconnectedWallet}
        closeDialog={closeDialog}
      />
      <OriginalsRankingGuideDialogSSR
        isOpened={dialog === ManagedDialog.rankingGuide}
        closeDialog={closeDialog}
      />
      <ProfileEditDialogSSR
        isOpened={dialog === ManagedDialog.profileEdit}
        closeDialog={closeDialog}
      />
      <QrDialogSSR isOpened={dialog === ManagedDialog.qr} closeDialog={closeDialog} />
      <SwapDialogSSR isOpened={dialog === ManagedDialog.uniswap} closeDialog={closeDialog} />
      <SwapChainSelectDialogSSR
        isOpened={dialog === ManagedDialog.swapChainSelect}
        closeDialog={closeDialog}
      />
      <TradeSupportItemDialogSSR
        isOpened={dialog === ManagedDialog.tradeSupportItem}
        closeDialog={closeDialog}
      />
      <TransferFailDialogSSR
        isOpened={dialog === ManagedDialog.transferFail}
        closeDialog={closeDialog}
      />
      <TransferFormDialogSSR
        isOpened={dialog === ManagedDialog.transferForm}
        closeDialog={closeDialog}
      />

      <TxProgressForTransferDialogSSR
        isOpened={dialog === ManagedDialog.txProgressForTransfer}
        closeDialog={closeDialog}
      />
      <WalletWaitingForTransferDialogSSR
        isOpened={dialog === ManagedDialog.walletWaitingForTransfer}
        closeDialog={closeDialog}
      />
      <GoAppHomeDialogSSR isOpened={dialog === ManagedDialog.goAppHome} closeDialog={closeDialog} />
      <ApplyDialogSSR isOpened={dialog === ManagedDialog.apply} closeDialog={closeDialog} />
      <CheckRaffleResultDialogSSR
        isOpened={dialog === ManagedDialog.checkRaffleResult}
        closeDialog={closeDialog}
      />
      <TermsDialogSSR isOpened={dialog === ManagedDialog.terms} closeDialog={closeDialog} />
      <UnSupportTradeDialogSSR
        isOpened={dialog === ManagedDialog.unSupportTrade}
        closeDialog={closeDialog}
      />
      <ExceedTransferDialogSSR
        isOpened={dialog === ManagedDialog.exceedTransfer}
        closeDialog={closeDialog}
      />
      <NoticeCopyAddressDialogSSR
        isOpened={dialog === ManagedDialog.noticeCopyAddress}
        closeDialog={closeDialog}
      />
      <PurchaseCancelDialogSSR
        isOpened={dialog === ManagedDialog.purchaseCancel}
        closeDialog={closeDialog}
      />
      <ChangeRegionSettingsDialogSSR
        isOpened={dialog === ManagedDialog.changeRegionSettings}
        closeDialog={closeDialog}
      />
    </>
  )
}

export default DialogManager
