import { useMutation } from '@tanstack/react-query'

import withAxios from '../api'

export async function getItemsMetaReload(itemId: string) {
  const { data } = await withAxios({
    url: '/api/v1/public/items/meta-reload',
    method: 'GET',
    params: { itemId: itemId.toLowerCase() },
  })

  return data
}

export function useItemsMetaReloadQuery(itemId: string) {
  return useMutation(['meta-reload'], () => getItemsMetaReload(itemId))
}
