import { useQuery } from '@tanstack/react-query'

import { IAccountTransferStatusResponse, IBaseResponse } from '@/shared/types'

import withAxios from '../api'

export const getCurrentAccountTransferStatus = async (itemId?: string) => {
  const url = '/api/v1/private/current-account/transfer-status'
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IAccountTransferStatusResponse>>({
    url,
    method: 'GET',
    params: {
      itemId,
    },
  })
  return data
}

export const useGetCurrentAccountTransferStatus = ({
  itemId,
  accountAddress,
}: {
  itemId?: string
  accountAddress?: string
}) =>
  useQuery(
    [`currentAccount:${accountAddress}:${itemId}-getCurrentAccountTransferStatus`, accountAddress],
    async () => {
      return await getCurrentAccountTransferStatus(itemId)
    },
    {
      enabled: accountAddress !== undefined && itemId !== undefined,
      refetchOnWindowFocus: false,
    },
  )
