import bigDecimal from 'js-big-decimal'

import { PaymentType } from '../types'

export const greaterThan0_001IfETH = (value: string): boolean =>
  bigDecimal.compareTo(value, '0.001') >= 0

export const greaterThan0_001IfPOL = (value: string): boolean =>
  bigDecimal.compareTo(value, '0.001') >= 0

export const isValidPrice = (value: string, paymentType: PaymentType): boolean => {
  if (paymentType === 'ETH' || paymentType === 'WETH') return greaterThan0_001IfETH(value)
  if (paymentType === 'POL' || paymentType === 'WPOL') return greaterThan0_001IfPOL(value)
  return false
}
