import { useCallback, useEffect, useRef, useState } from 'react'

export const useRefresh = () => {
  const [refresh, setRefresh] = useState(false)
  const refetch = useRef<() => void | null>(null)

  const setRefetchFunc = useCallback((refetchFunc: () => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refetch.current = refetchFunc
  }, [])

  useEffect(() => {
    if (refetch.current === null) return
    if (refresh) {
      refetch.current()
      setRefresh(false)
    }
  }, [refresh])

  return {
    refresh,
    refreshRefetch: () => setRefresh(true),
    setRefetchFunc,
  }
}
