import { ROUTE_PATH } from '../constants'
import { AppState } from '../store'
import { ISnapshotInError } from '../types'

export const getErrorName = (status: number) => {
  let name = 'Api Error'
  switch (status) {
    case 400:
      name = 'Bad Request'
      break
    case 401:
      name = 'Unauthorized'
      break
    case 403:
      name = 'Forbidden'
      break
    case 404:
      name = 'Not Found'
      break
    case 405:
      name = 'Method Not Allowed'
      break
    case 410:
      name = 'Gone'
      break
    case 500:
      name = 'Internal Server Error'
      break
    case 503:
      name = 'Time Out'
      break
    default:
      name = `[${status}] Api Error`
  }

  return name
}

export const getSnapshotInError = ({
  pathname,
  currentPage,
  state,
  extra,
}: {
  pathname?: string
  currentPage?: keyof typeof ROUTE_PATH
  state?: AppState
  extra?: Record<string, unknown>
}): ISnapshotInError => {
  let page
  switch (currentPage) {
    case 'HOME':
      page = '홈'
      break
    case 'CURRENT_ACCOUNT':
      page = '현재 로그인한 유저 페이지'
      break
    case 'CURRENT_ACCOUNT_HIDDEN':
      page = '현재 로그인한 유저 페이지 - 숨김'
      break
    case 'CURRENT_ACCOUNT_LIKED':
      page = '현재 로그인한 유저 페이지 - 좋아요'
      break
    case 'CURRENT_ACCOUNT_ACTIVITY':
      page = '현재 로그인한 유저 페이지 - 활동내역'
      break
    case 'CURRENT_ACCOUNT_SENT_OFFER':
      page = '현재 로그인한 유저 페이지 - 보낸 제안'
      break
    case 'CURRENT_ACCOUNT_RECEIVED_OFFER':
      page = '현재 로그인한 유저 페이지 - 받은 제안'
      break
    case 'CURRENT_ACCOUNT_COLLECTION':
      page = '현재 로그인한 유저 페이지 - 컬렉션'
      break
    case 'PAYMENT_HISTORY':
      page = '현재 로그인한 유저 페이지 - 결제내역'
      break
    case 'ACCOUNT':
      page = '다른 유저 페이지'
      break
    case 'ACCOUNT_LIKED':
      page = '다른 유저 페이지 - 좋아요'
      break
    case 'ACCOUNT_ACTIVITY':
      page = '다른 유저 페이지 - 활동내역'
      break
    case 'COLLECTIONS':
      page = '컬렉션'
      break
    case 'COLLECTION_DETAIL':
      page = '컬렉션 상세'
      break
    case 'COLLECTION_ACTIVITY':
      page = '컬렉션 상세 - 활동내역'
      break
    case 'COLLECTION_EDIT':
      page = '컬렉션 상세 - 수정'
      break
    case 'COLLECTION_PAYOUT':
      page = '컬렉션 상세 - 정산'
      break
    case 'SEARCH_DETAIL':
      page = '검색'
      break
    case 'ITEM_DETAIL':
      page = '아이템 상세'
      break
    case 'ITEM_DETAIL_DETAIL':
      page = '아이템 상세 - 상세'
      break
    case 'ITEM_DETAIL_ACTIVITY':
      page = '아이템 상세 - 활동내역'
      break
    case 'ITEM_DETAIL_OFFER':
      page = '아이템 상세 - 제안 현황'
      break
    case 'ORIGINALS':
      page = '오리지널스'
      break
    case 'ORIGINALS_BRAND_PAGE':
      page = '오리지널스 - 브랜드 페이지'
      break
    case 'ORIGINALS_CHECKOUT':
      page = '오리지널스 - 결제 페이지'
      break
    case 'ORIGINALS_CHECKOUT_SUCCESS':
      page = '오리지널스 - 결제 성공 페이지'
      break
    case 'ORIGINALS_CHECKOUT_FAIL':
      page = '오리지널스 - 결제 실패 페이지'
      break
    case 'ORIGINALS_CHECKOUT_CALLBACK':
      page = '오리지널스 - 결제 콜백 페이지'
      break
    case 'ORIGINALS_RANKINGS':
      page = '오리지널스 - 랭킹 페이지'
      break
  }

  const snapshot = {
    pathname,
    page,
    state,
    extra,
  }

  return snapshot
}
