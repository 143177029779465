import { useQuery } from '@tanstack/react-query'

import { IItemInactivateListingListResponse } from '@/shared/types'

import withAxios from '../api'

export const getInactiveListingList = async (itemId?: string) => {
  const {
    data: { listings },
  } = await withAxios<IItemInactivateListingListResponse>({
    url: `/api/v1/private/items/${itemId?.toLowerCase()}/inactive-listings`,
    method: 'GET',
  })

  return listings
}

export const useGetInactiveListingList = (
  pathname: string,
  accountAddress: string | null,
  itemId?: string,
) => {
  return useQuery(
    [pathname, 'getInactiveListingList', { accountAddress, itemId }],
    () => getInactiveListingList(itemId),
    { enabled: itemId !== undefined && accountAddress !== null },
  )
}
