import { toast } from 'react-toastify'

export const showToast = ({ text, toastId }: { text: string; toastId: string }) =>
  toast(
    () => {
      return <p className="text-start whitespace-pre-line">{text}</p>
    },
    {
      toastId: toastId,
    },
  )
