import { useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { NextRouter, useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'

import {
  ManagedDialog,
  ORIGINALS_PAYMENT_ERROR_CODE,
  ORIGINALS_PAYMENT_TYPE,
  PAYMENTS_RESULT_STATUS,
  PORTONE_STATUS,
  ROUTE_PATH,
} from '../constants'
import {
  usePostOriginalsPortOnePaymentsCallback,
  useUpdateSeatsCheckoutBankInfo,
  useUpdateSeatsCheckoutCardInfo,
  useUpdateSeatsCheckoutPaypalInfo,
} from '../services/originals'
import {
  selectHoldToken,
  selectSelectedEventKey,
  selectSelectedSeatsInfo,
} from '../store/bookEvent'
import { useAppSelector } from '../store/hooks'
import { Currency } from '../store/wallet'
import {
  ICallbackError,
  IHandlePaymentErrorProps,
  IHandlePortOnePaymentSuccessProps,
  IPaymentsFuncProps,
  IPostOriginalsSeatingBankPaymentResponse,
  IPostOriginalsSeatingCardPaymentResponse,
  IPostOriginalsSeatingPayPalPaymentResponse,
} from '../types'
import { replaceParams } from '../utils'
import { useDialog } from './useDialog'

// 타입 가드 함수
const isCallbackError = (error: any): error is ICallbackError => {
  return (
    error &&
    typeof error.error === 'string' &&
    typeof error.message === 'string' &&
    typeof error.statusCode === 'number'
  )
}

const handlePortOnePaymentsSuccess = ({
  originalsId,
  callbackRes,
  setDialog,
  router,
  setIsRequestingPayment,
}: IHandlePortOnePaymentSuccessProps) => {
  const handleNavigate = (result: string, errorCodeOrOrdNo: string) => {
    setDialog(null)
    setIsRequestingPayment(false)
    router.replace(
      replaceParams(ROUTE_PATH.ORIGINALS_CHECKOUT_CALLBACK, {
        originalsId,
        result: result,
        errorCodeOrOrdNo,
      }),
    )
  }

  switch (callbackRes.status) {
    case PORTONE_STATUS.PAID:
    case PORTONE_STATUS.PENDING:
      handleNavigate(PAYMENTS_RESULT_STATUS.SUCCESS, callbackRes.ordNo)
      break
    default:
      if (callbackRes.resultMsg) {
        handleNavigate(PAYMENTS_RESULT_STATUS.FAIL, callbackRes.resultMsg)
      } else {
        handleNavigate(PAYMENTS_RESULT_STATUS.FAIL, 'COMMON_PAY_ERROR')
      }
  }
}

const handlePortOnePaymentsError = ({
  error,
  setDialog,
  router,
  originalsId,
  setIsRequestingPayment,
}: IHandlePaymentErrorProps) => {
  const handleNavigate = (errorType: string) => {
    const url = replaceParams(ROUTE_PATH.ORIGINALS_CHECKOUT_FAIL, {
      originalsId,
      errorCode: errorType,
    })
    setDialog(null)
    setIsRequestingPayment(false)
    router.replace(url)
  }

  if (axios.isAxiosError(error)) {
    const errorRes = error.response?.data
    const errorCode = errorRes?.error

    if (errorCode && isCallbackError(errorRes) && ORIGINALS_PAYMENT_ERROR_CODE[errorCode]) {
      handleNavigate(errorCode)
      return
    }
  }

  handleNavigate('COMMON_PAY_ERROR')
}
export const usePortOne = ({ paymentType, setIsRequestingPayment }: IPaymentsFuncProps) => {
  const IMP = window.IMP
  const router = useRouter()

  const { originalsId } = router.query as {
    originalsId?: string
  }
  const selectedSeatsInfo = useAppSelector(selectSelectedSeatsInfo)
  const selectedEventKey = useAppSelector(selectSelectedEventKey)
  const selectedHoldToken = useAppSelector(selectHoldToken)
  const { mutateAsync: postSeatsCheckoutPaypalInfo } = useUpdateSeatsCheckoutPaypalInfo()
  const { mutateAsync: postSeatsCheckoutCardInfo } = useUpdateSeatsCheckoutCardInfo()
  const { mutateAsync: postSeatsCheckoutBankInfo } = useUpdateSeatsCheckoutBankInfo()
  const { mutateAsync: postPortOnePaymentsCallback } = usePostOriginalsPortOnePaymentsCallback()
  const { setDialog } = useDialog()
  const queryClient = useQueryClient()
  const impRef = useRef<boolean | null>(null)

  const isPaymentUnavailable =
    !originalsId || !selectedEventKey || !selectedSeatsInfo || !selectedHoldToken || !paymentType

  const seatsCheckoutPayload = useMemo(() => {
    if (isPaymentUnavailable) return
    const selectOptions = selectedSeatsInfo.map((seat, i) => {
      return {
        purchaseQuantity: seat.quantity,
        seatObjectId: seat.id,
      }
    })
    return {
      originalsId: originalsId,
      eventKey: selectedEventKey,
      currency: paymentType === ORIGINALS_PAYMENT_TYPE.PAYPAL ? Currency.USD : Currency.KRW,
      selectOptions,
      paymentType,
      holdToken: selectedHoldToken,
    }
  }, [
    isPaymentUnavailable,
    originalsId,
    paymentType,
    selectedEventKey,
    selectedHoldToken,
    selectedSeatsInfo,
  ])

  useEffect(() => {
    if (IMP && impRef.current === null) {
      IMP.init(`${process.env.NEXT_PUBLIC_PORTONE_MID}`)
      impRef.current = true
    }
  }, [IMP])

  const popupBankPayment = () => {
    if (isPaymentUnavailable || !seatsCheckoutPayload) return
    setDialog(ManagedDialog.waitingForPayments)
    setIsRequestingPayment(true)
    try {
      postSeatsCheckoutBankInfo(seatsCheckoutPayload, {
        onSuccess: (checkoutBankInfoRes: IPostOriginalsSeatingBankPaymentResponse) => {
          IMP.request_pay(checkoutBankInfoRes, function (req: any) {
            postPortOnePaymentsCallback(
              { imp_uid: req.imp_uid, merchant_uid: req.merchant_uid },
              {
                onSuccess: callbackRes => {
                  setDialog(null)
                  if (callbackRes.status === PORTONE_STATUS.READY) {
                    setDialog(null)
                    setIsRequestingPayment(false)
                    return
                  }
                  handlePortOnePaymentsSuccess({
                    originalsId,
                    callbackRes,
                    setDialog,
                    router,
                    setIsRequestingPayment,
                  })
                },
                onError: error => {
                  handlePortOnePaymentsError({
                    error,
                    setDialog,
                    router,
                    originalsId,
                    setIsRequestingPayment,
                  })
                },
              },
            )
          })
        },
        onError: error => {
          handlePortOnePaymentsError({
            error,
            setDialog,
            router,
            originalsId,
            setIsRequestingPayment,
          })
        },
      })
    } catch (error) {
      handlePortOnePaymentsError({ error, setDialog, router, originalsId, setIsRequestingPayment })
    }
  }

  const popupCardPayment = () => {
    if (isPaymentUnavailable || !seatsCheckoutPayload) return
    setDialog(ManagedDialog.waitingForPayments)
    setIsRequestingPayment(true)
    try {
      postSeatsCheckoutCardInfo(seatsCheckoutPayload, {
        onSuccess: (checkoutCardInfoRes: IPostOriginalsSeatingCardPaymentResponse) => {
          IMP.request_pay(checkoutCardInfoRes, function (req: any) {
            postPortOnePaymentsCallback(
              { imp_uid: req.imp_uid, merchant_uid: req.merchant_uid },
              {
                onSuccess: callbackRes => {
                  handlePortOnePaymentsSuccess({
                    originalsId,
                    callbackRes,
                    setDialog,
                    router,
                    setIsRequestingPayment,
                  })
                },
                onError: error => {
                  handlePortOnePaymentsError({
                    error,
                    setDialog,
                    router,
                    originalsId,
                    setIsRequestingPayment,
                  })
                },
              },
            )
          })
        },
        onError: error => {
          handlePortOnePaymentsError({
            error,
            setDialog,
            router,
            originalsId,
            setIsRequestingPayment,
          })
        },
      })
    } catch (error) {
      handlePortOnePaymentsError({ error, setDialog, router, originalsId, setIsRequestingPayment })
    }
  }

  const popupPayPal = () => {
    if (isPaymentUnavailable || !seatsCheckoutPayload) return

    try {
      postSeatsCheckoutPaypalInfo(seatsCheckoutPayload, {
        onSuccess: (checkoutPaypalInfoRes: IPostOriginalsSeatingPayPalPaymentResponse) => {
          queryClient.setQueryData(['useUpdateSeatsCheckoutPaypalInfo'], { success: true })

          const dataWithBtnOption = {
            ...checkoutPaypalInfoRes,
            bypass: { paypal_v2: { 'disable-funding': 'card' } },
          }
          IMP.loadUI('paypal-spb', dataWithBtnOption, async (req: any) => {
            setDialog(ManagedDialog.waitingForPayments)
            setIsRequestingPayment(true)
            postPortOnePaymentsCallback(
              { imp_uid: req.imp_uid, merchant_uid: req.merchant_uid },
              {
                onSuccess: callbackRes => {
                  handlePortOnePaymentsSuccess({
                    originalsId,
                    callbackRes,
                    setDialog,
                    router,
                    setIsRequestingPayment,
                  })
                },
                onError: error => {
                  handlePortOnePaymentsError({
                    error,
                    setDialog,
                    router,
                    originalsId,
                    setIsRequestingPayment,
                  })
                },
              },
            )
          })
        },
        onError: error => {
          handlePortOnePaymentsError({
            error,
            setDialog,
            router,
            originalsId,
            setIsRequestingPayment,
          })
        },
      })
    } catch (error) {
      handlePortOnePaymentsError({ error, setDialog, router, originalsId, setIsRequestingPayment })
    }
  }

  const mappingsPaymentFunc = {
    [ORIGINALS_PAYMENT_TYPE.POL]: () => undefined,
    [ORIGINALS_PAYMENT_TYPE.BANK]: popupBankPayment,
    [ORIGINALS_PAYMENT_TYPE.FREE]: () => undefined,
    [ORIGINALS_PAYMENT_TYPE.CARD]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.PAYPAL]: popupPayPal,
  }

  return {
    paymentFunc: paymentType === null ? null : mappingsPaymentFunc[paymentType],
  }
}
