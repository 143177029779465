export interface ITradeAddress {
  conduit: string
  conduitKey: string
  seaport: string
  zone: string
}

export interface ITradeAddressForOrderItem {
  conduit: string
  conduitKey: string
  seaport: string
  zone: string
  zoneHash: string
}

export const instanceofTradeAddress = (tradeAddress: any): tradeAddress is ITradeAddress => {
  return (
    'conduit' in tradeAddress &&
    'conduitKey' in tradeAddress &&
    'seaport' in tradeAddress &&
    'zone' in tradeAddress
  )
}
