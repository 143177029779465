import { TRANSACTION_SCOPE } from '../constants'
import { IItem, IItemDetail } from '../types'

export const checkIsTradable = (item: IItem | IItemDetail): boolean =>
  item.collection?.transactionScope === TRANSACTION_SCOPE.ALL ||
  item.collection?.transactionScope === TRANSACTION_SCOPE.TRADE

export const checkIsTransferable = (item: IItem | IItemDetail): boolean =>
  item.collection?.transactionScope === TRANSACTION_SCOPE.ALL ||
  item.collection?.transactionScope === TRANSACTION_SCOPE.TRANSFER ||
  item.collection?.transactionScope === TRANSACTION_SCOPE.INTERNAL_TRANSFER
