import { IItemUtilitiesRequest } from '@/shared/services/market'

import { IMarketOnSaleItem } from '../types'

export const getHasBenefitOnSaleIdList = (
  data: IMarketOnSaleItem[] | undefined,
): IItemUtilitiesRequest => {
  if (data === undefined) return { itemIds: [] }

  const result = data
    .filter(item => item.hasBenefit === true)
    .flatMap(item => `${item.collectionAddress}-${item.tokenId}`)

  return {
    itemIds: result,
  }
}
