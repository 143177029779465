import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { ROUTE_PATH } from '../constants'
import { AppState } from '../store'
import { getSnapshotInError } from '../utils'

export const useGetSnapshotInError = () => {
  const [currentPage, setCurrentPage] = useState<keyof typeof ROUTE_PATH>()
  const [extra, setExtra] = useState<Record<string, unknown>>()

  const { pathname } = useRouter()
  const currentAppState = useSelector((state: AppState) => state)

  const getCurrentSnapshotInError = useCallback(() => {
    return getSnapshotInError({
      pathname,
      state: currentAppState,
      ...(currentPage ? { currentPage } : {}),
      ...(extra ? { extra } : {}),
    })
  }, [currentAppState, currentPage, extra, pathname])

  return {
    setCurrentPage,
    setExtra,
    getCurrentSnapshotInError,
  }
}
