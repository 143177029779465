import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { IBaseResponse, IGetCollectionPayoutResponse } from '@/shared/types'

import withAxios from '../api'

export const getCollectionPayout = async (
  collectionAddress?: string,
): Promise<IBaseResponse<IGetCollectionPayoutResponse>> => {
  const { data } = await withAxios<IBaseResponse<IGetCollectionPayoutResponse>>({
    url: `/api/v1/private/current-account/${collectionAddress}/payout`,
    method: 'GET',
  })
  return data
}

export const useGetCollectionPayout = (
  pathname: string,
  isOwner: boolean,
  collectionAddress?: string,
): UseQueryResult<IBaseResponse<IGetCollectionPayoutResponse>> => {
  return useQuery(
    [pathname, 'getCollectionPayout', { collectionAddress, isOwner }],
    () => getCollectionPayout(collectionAddress),
    { enabled: isOwner },
  )
}
