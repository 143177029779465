import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query'

import {
  FLOATING_BAR_BUTTON_ACTION,
  OPTION_SELECT_TYPE,
  ORIGINALS_DISCOUNT_TYPE,
  ORIGINALS_PAYMENT_TYPE,
  ORIGINALS_PROJECT_TYPE,
  PORTONE_STATUS,
} from '@/shared/constants'

import { Currency } from '../store/wallet'
import { PAYMENT_STATUS_TYPE } from './../constants/originals'
import { IPagination } from './base'

export type ShowType = 'NOW' | 'UPCOMING' | 'PAST'
export type OriginalsSaleType = 'pre' | 'earlyBird' | 'public'
export type OriginalsProjectType = keyof typeof ORIGINALS_PROJECT_TYPE
export type OriginalsContractMappingsKeys = 'medi-society' | 'ohnim' | 'habitus'
export type OriginalsPaymentType = keyof typeof ORIGINALS_PAYMENT_TYPE

export interface IOriginalsBrandPageProps {
  showFloatingBar: boolean
  schedule?: IOriginalsScheduleResponse
  fetchOriginalsSchedule: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<IOriginalsScheduleResponse, unknown>>
  callButtonAction: (buttonAction: keyof typeof FLOATING_BAR_BUTTON_ACTION) => void
}

export interface IOriginalsCheckoutType {
  buyLimit: number
  isPrivateSale: boolean
  paymentEndAt: string
  paymentStartAt: string
  paymentType: OriginalsPaymentType
  price: number
  totalCount: number
  saleType: OriginalsSaleType
}

export interface IOriginals {
  id: string
  collectionAddress: string | null
  hostName: string
  title: string
  subTitle: string
  saleStartAt: string | null
  saleEndAt: string | null
  isSoldOut: boolean
  image: string
  thumbnail: string
  agreement: boolean
  checkoutTypes: IOriginalsCheckoutType[]
  projectType: OriginalsProjectType
  chainId: string
  network: string
  metadataType: string
  maxMintingCount: number | null
}

export interface IOriginalsScheduleResponse {
  originalsId: string
  title: string
  subTitle: string
  tooltip: string | null
  saleStartAt: Date
  saleEndAt: Date
  buttonTitle: string | null
  buttonLink: string | null
  buttonAction: keyof typeof FLOATING_BAR_BUTTON_ACTION
  nowDate: Date
}

export interface IGetOriginalsListRequestParams {
  showType: ShowType
  page?: number
  perPage?: number
}

export type IGetOriginalsListDebouncedQueryParams = IGetOriginalsListRequestParams

export interface IOriginalsListResponse extends IPagination {
  originalsCollection: IOriginals[]
}

export interface ITermsListType {
  id: string
  title: string
  subTitle: string
  description: string
  isRequired: boolean
  type: string
}

export interface IOriginalsItemResponse {
  id: string
  collectionAddress: string
  hostName: string
  title: string
  subTitle: string
  squareImage: string
  paymentTypes: (keyof typeof ORIGINALS_PAYMENT_TYPE)[]
  termsList: ITermsListType[]
  maxQuantityPerOriginals: number
}

export interface IOriginalsCheckWhiteList {
  isWhiteList: boolean
}

export interface IGetOriginalsBankPaymentResponse {
  addDeductionYn: string
  apiVer: string
  callbackUrl: string
  createdAt: string
  criPsblYn: string
  dutyFreeYn: string
  hdInfo: string
  id: string
  mercntId: string
  mercntParam1: string
  mercntParam2: string
  ordNo: string
  processType: string
  productNm: string
  regularpayYn: string
  shopNm: string
  signature: string
  trDay: string
  trPrice: string
  trTime: string
  updatedAt: string
}

export interface IGetOriginalsFreePaymentCallbackResponse {
  createdAt: string
  id: string
  ordNo: string
  originalsId: string
  updatedAt: string
}

export interface IGetOriginalsPaymentCallbackResponse {
  url: string
}

export type IGetOriginalsPaymentResponse =
  | IGetOriginalsBankPaymentResponse
  | IGetOriginalsPaymentCallbackResponse
  | IGetOriginalsFreePaymentCallbackResponse
export interface IOriginalsPortOnePaymentsCallbackResponse {
  status: keyof typeof PORTONE_STATUS
  ordNo: string
  resultMsg: string | null
}
export interface IPostOriginalsSeatingBankPaymentResponse {
  pg: string
  payMethod: string
  merchantUid: string
  name: string
  currency: Currency
  amount: number
  mRedirectUrl: string
  buyerTel: string
  bypass: {
    settle: {
      addDeductionYn: string
      criPsblYn: string
      custCi: string
    }
  }
}

export interface IPostOriginalsSeatingCardPaymentResponse {
  pg: string
  payMethod: string
  merchantUid: string
  name: string
  currency: Currency
  amount: number
  mRedirectUrl: string
  buyerName: string
}
export interface IPostOriginalsSeatingPayPalPaymentResponse {
  pg: string
  payMethod: string
  merchantUid: string
  name: string
  currency: Currency
  amount: number
  mRedirectUrl: string
  mid: string
  products: [
    {
      id: string
      name: string
      code: string
      unitPrice: number
      quantity: number
    },
  ]
}

export interface IPostOriginalsBankPaymentsResponse {
  pg: string
  pay_method: string
  merchant_uid: string
  name: string
  currency: Currency
  amount: number
  confirm_url: string
  notice_url: string
  buyer_tel: string
  buyer_name: string
  bypass: {
    settle: {
      addDeductionYn: string
      criPsblYn: string
    }
  }
}

export interface IPostOriginalsCardPaymentsResponse {
  pg: string
  pay_method: string
  merchant_uid: string
  name: string
  currency: Currency
  amount: number
  confirm_url: string
  notice_url: string
  buyer_name: string
}

export interface IPostOriginalsPaypalPaymentsResponse {
  pg: string
  pay_method: string
  merchant_uid: string
  name: string
  currency: Currency
  amount: number
  confirm_url: string
  notice_url: string
  mid: string
  products: [
    {
      id: string
      name: string
      code: string
      unit_price: number
      quantity: number
    },
  ]
  digital: true
}

export type IGetOriginalsSeatingPaymentResponse =
  | IPostOriginalsSeatingBankPaymentResponse
  | IPostOriginalsSeatingCardPaymentResponse
  | IPostOriginalsSeatingPayPalPaymentResponse
export interface IOriginalsETHPaymentsStatusResponse {
  createdAt: string
  id: string
  mercntParam1: string
  mercntParam2: string
  ordNo: string
  paidAt: string
  paymentType: string
  productNm: string
  status: 'SUCCESS'
  updatedAt: string
}

export interface IOriginalsVerifyResponse {
  isVerified: boolean
  merkleTreeProof: string[]
}

export interface IAvailableCheckoutOrdNo {
  id: string
  ordNo: string
  trPrice: string
  taxPrice: string | null
  vatPrice: string | null
  dutyFreePrice: string | null
  productNm: string
  dutyFreeYn: string
  criPsblYn: string
  addDeductionYn: string
  shopNm: string
  cphoneNo: string | null
  email: string | null
  callbackUrl: string
  cancelUrl: string
  mercntParam1: string
  mercntParam2: string
  userId: string
  collectionAddress: string
  productCount: number
  paymentType: OriginalsPaymentType
  status: string | null
  unitPrice: string
  paidAt: string | null
  updatedAt: string
  createdAt: string
}

export interface ICheckoutDetailItems {
  eventDate: string
  originalsOptionId: string
  originalsOptionTitle: string
  hostName: string
  imageUrl: string
  productCount: number
  unitPrice: number
  totalPrice: number
  status: keyof typeof PAYMENT_STATUS_TYPE
  refundPercentage: number
  refundAmount: number
  refundFee: number
}

export interface IOriginalsPaymentResultResponse {
  ordNo: string
  paymentType: OriginalsPaymentType
  totalPrice: number
  paidAt: string
  originalsId: string
  checkoutDetailItems: ICheckoutDetailItems[]
  totalRefundAmount: number
  totalRefundFee: number
  refundReceipt: null | {
    cancelPrice: number
  }
}

export interface IRefundInfo {
  originalsOptionId: string
  cancelPrice: number
}

export interface IOriginalsPaymentReceipts {
  paymentType: OriginalsPaymentType
  id: string
  collectionAddress?: string
  ordNo: string
  trPrice: string
  paidAt: string
  productCount: number | null
  status: keyof typeof PAYMENT_STATUS_TYPE
  originals: {
    id: string
    collectionAddress?: string
    hostName: string
    title: string
    subTitle?: string
    squareImage: string
  }
}

export interface IOriginalsPaymentResponse extends IPagination {
  paymentReceipts: IOriginalsPaymentReceipts[]
}

export const instanceOfOriginalsSchedule = (
  schedule: any,
): schedule is IOriginalsScheduleResponse => {
  return (
    'originalsId' in schedule &&
    'title' in schedule &&
    'subTitle' in schedule &&
    'tooltip' in schedule &&
    'saleStartAt' in schedule &&
    'saleEndAt' in schedule &&
    'buttonTitle' in schedule &&
    'buttonLink' in schedule &&
    'buttonAction' in schedule
  )
}

export interface IMetadataValueForOriginalsItem {
  name: string
  description: string
  image: string
  attributes: {
    trait_type: string
    value: string
  }[]
  originals: {
    name: string
    title: string
    image: string
  }
}

export interface IPrice {
  type: OriginalsPaymentType
  value: number
  percentage: string | null
}

export interface IOptionItem {
  optionTitle: string
  optionSubTitle: string
  price: IPrice[]
  buyLimit: number
  totalCount: number
  metadataKey: string
  maxMintingCount: number
}

export interface IOptionItemWithGroupInfo extends IOptionItem {
  groupId: string
  optionSelectType: keyof typeof OPTION_SELECT_TYPE
}

export interface IGroupOption {
  groupId: string
  groupName: string
  optionSelectType: keyof typeof OPTION_SELECT_TYPE
  option: IOptionItem[]
}

export interface IFestivalOptions {
  options: IGroupOption[]
}

export interface IOriginalsOption {
  id: string
  originalsId: string
  title: string
  subTitle: string
  maxQuantityPerPurchase: number
  maxQuantityPerWallet: number
  totalQuantity: number
  paymentStartAt: Date
  paymentEndAt: Date
  displayOrder: number
  stockCount: number
  unselectableOptionIds: string[]
  prices: {
    type: keyof typeof ORIGINALS_PAYMENT_TYPE
    originPrice: number
    discountType: keyof typeof ORIGINALS_DISCOUNT_TYPE
    discountValue: number
    salePrice: number
  }[]
}
export interface IOriginalsOptions {
  originalsOptions: IOriginalsOption[]
}
export interface ISelectedOption extends IOptionItem {
  productCount: string
}

export interface IPurchasedItem {
  originalsOptionId: string
  quantity: number
  unselectableOptionIds: string[]
}

export interface ISelectedCheckoutOptionItem {
  metadataKey: string
  productCount: string
}
export interface IUpdateOriginalsCheckoutProps {
  amount: number
  originalsId: string | undefined
  selectedOptionList: IOriginalsOption[] | []
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE | null
  isSingleOption: boolean
}

export interface IUpdateConvertedOriginalsCheckoutInfo {
  originalsId: string
  paymentType: keyof typeof ORIGINALS_PAYMENT_TYPE
  totalCount: number
  selectOptions: { originalsOptionId: string; purchaseQuantity: number }[]
  currency: Currency
}
export interface IStockInfo {
  originalsId: string
  collectionAddress: string
  metadataKey: string
  stockCount: number
  totalCount: number
}
export interface IStockInfos {
  stockInfos: IStockInfo[]
}

export interface ICheckoutOptionTitle {
  OPTION_TITLE: string
  OPTION_SUB_TITLE: string
}

export interface ISeatEventsInfo {
  id: string
  eventKey: string
  eventDate: string
  availableCountByCategoryLabel: Record<string, number>
}

export interface ISeatEvent {
  id: string
  eventKey: string
  eventDate: string
  maxQuantityPerPurchase: number
}

export interface IHoldSeatsResponse {
  holdSeatObjects: [
    {
      objectId: string
      quantity: number
    },
  ]
  holdToken: {
    expiresAt: string
    expiresInSeconds: number
    holdToken: string
  }
}

export interface ISeatsInfoResponse {
  objectId: string
  categoryKey: string
  categoryLabel: string
  prices: {
    currency: string
    originPrice: number
    type: keyof typeof ORIGINALS_PAYMENT_TYPE
    discountType: keyof typeof ORIGINALS_DISCOUNT_TYPE
    discountValue: number
    salePrice: number
  }[]
}

export interface IUserProfileResponse {
  dateOfBirth: null | 'string' // ex) 19960712
  dialCode: 'string' // + 제외된 국가 코드값 ex) '82'
  name: null | 'string'
  phoneNumber: 'string' // ex) 01012341234
}

export interface IUserAgeResponse {
  isVerified: boolean
  age: number | null // 연령 인증 안한 경우 null
}

export interface IRefundDetail {
  id: string
  refundId: string
  productQuantity: number
  productPrice: number
  cancelUnitPrice: number
  createdAt: string // ex)'2024-08-01T00:18:55.304Z'
  updatedAt: string // ex)'2024-08-01T00:18:55.304Z'
  originalsRefundDetailExtra: {
    id: string
    originalsOptionId: string
  }
}

export interface IRefundResponseProps {
  id: string
  originalsId: string
  accountAddress: string
  ordNo: string
  requester: string
  approver: null
  cancelPrice: number
  status: keyof typeof PAYMENT_STATUS_TYPE
  refundRequestedAt: string // ex)'2024-08-01T00:18:55.304Z'
  refundAt: null | string
  refundDetails: IRefundDetail[]
}
