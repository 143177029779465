import { useQuery } from '@tanstack/react-query'

import withAxios from '@/shared/services/api'
import { ICollectionRankingRequest, ICollectionRankingResponse } from '@/shared/types'

export const getCollectionRankingInfo = async (params: ICollectionRankingRequest) => {
  const { data } = await withAxios<ICollectionRankingResponse>({
    url: '/api/v1/public/collections/trade-ranking',
    method: 'GET',
    params,
  })

  return data
}

export const useGetCollectionRankingInfo = ({ itemCount }: ICollectionRankingRequest) => {
  return useQuery(['getCollectionRankingInfo'], () => getCollectionRankingInfo({ itemCount }), {
    suspense: true,
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
  })
}
