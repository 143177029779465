import { useMutation } from '@tanstack/react-query'

import { IHoldSeatsResponse } from '@/shared/types'

import withAxios from '../api'

export async function postHoldSeats({
  originalsId,
  eventKey,
  holdSeatObjects,
}: {
  originalsId: string
  eventKey: string
  holdSeatObjects: {
    objectId: string
    quantity: number
  }[]
}) {
  const url = `/api/v3/private/kkr-originals/originals/${originalsId}/hold-seats`
  const { data } = await withAxios<IHoldSeatsResponse>({
    url,
    method: 'POST',
    data: {
      eventKey,
      holdSeatObjects,
    },
  })

  return data
}

export function usePostHoldSeats() {
  return useMutation(
    ['usePostHoldSeats'],
    ({
      originalsId,
      eventKey,
      holdSeatObjects,
    }: {
      originalsId: string
      eventKey: string
      holdSeatObjects: {
        objectId: string
        quantity: number
      }[]
    }) => postHoldSeats({ originalsId, eventKey, holdSeatObjects }),
  )
}
