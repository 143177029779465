import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

function useCopyURL() {
  const { t } = useTranslation(['common'])
  const handleAddressCopy = (url: string) => {
    navigator.clipboard.writeText(url)
    toast(t('common:common.toast-copied-wallet-address'), {
      toastId: 'share',
    })
  }

  return { handleAddressCopy }
}

export default useCopyURL
