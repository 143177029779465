import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TRANSACTION_SCOPE } from '@/shared/constants'

import { AppState } from '..'

export interface ITransactionScopeState {
  transactionScope: null | keyof typeof TRANSACTION_SCOPE
}

const initialState: ITransactionScopeState = {
  transactionScope: null,
}

export const transactionScopeSlice = createSlice({
  name: 'transactionScope',
  initialState,
  reducers: {
    setTransactionScope: (state, action: PayloadAction<keyof typeof TRANSACTION_SCOPE | null>) => {
      state.transactionScope = action.payload
    },
    resetTransactionScope: state => {
      state.transactionScope = null
    },
  },
})

export const { setTransactionScope, resetTransactionScope } = transactionScopeSlice.actions

export const selectTransactionScope = (state: AppState) => state.transactionScope.transactionScope

export default transactionScopeSlice
