import { useInfiniteQuery } from '@tanstack/react-query'

import { GetItemDetailOfferRequest, IBaseResponse, IGetOfferItemResponse } from '@/shared/types'

import withAxios from '../api'

export async function getItemDetailOffer(
  collectionAddress?: string,
  tokenId?: string,
  params?: GetItemDetailOfferRequest,
) {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IGetOfferItemResponse>>({
    url: `/api/v1/public/collections/${collectionAddress}/${tokenId}/offers`,
    method: 'GET',
    params,
  })
  return data
}

export const useGetItemDetailOffers = (
  pathname: string,
  collectionAddress?: string,
  tokenId?: string,
) =>
  useInfiniteQuery<IGetOfferItemResponse>(
    [pathname, 'getItemDetailOffer'],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        page: pageParam,
        perPage: 20,
      }
      const data = await getItemDetailOffer(collectionAddress, tokenId, queryParams)
      return data
    },
    {
      getNextPageParam: data => (data?.offers?.length === 20 && data.page + 1) ?? undefined,
      enabled: collectionAddress !== undefined && tokenId !== undefined,
      refetchOnWindowFocus: false,
    },
  )
