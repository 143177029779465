import { useQuery } from '@tanstack/react-query'

import { IItemListingListResponse } from '@/shared/types'

import withAxios from '../api'

export const getListingList = async (itemId?: string) => {
  const {
    data: { listings },
  } = await withAxios<IItemListingListResponse>({
    url: `/api/v1/private/items/${itemId?.toLowerCase()}/listings`,
    method: 'GET',
  })
  return listings
}

export const useGetListingList = (pathname: string, itemId?: string) => {
  return useQuery(
    [pathname, 'getListingList', { itemId }],
    async () => await getListingList(itemId),
    { enabled: itemId !== undefined },
  )
}
