import { IBaseResponse, ICollectionStats } from '@/shared/types'

import withAxios from '../api'

export const getCollectionStats = async (collectionAddress?: string): Promise<ICollectionStats> => {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<ICollectionStats>>({
    url: `/api/v1/public/collections/${collectionAddress?.toLowerCase()}/stats`,
    method: 'GET',
  })
  return data
}
