import { useQuery } from '@tanstack/react-query'

import { ILowestPriceListingResponse } from '@/shared/types'

import withAxios from '../api'

export const getLowestPrice = async (itemId?: string) => {
  const {
    data: { listing },
  } = await withAxios<ILowestPriceListingResponse>({
    url: `/api/v1/public/items/${itemId?.toLowerCase()}/listing/lowest-price`,
    method: 'GET',
  })

  return listing
}

export const useGetLowestPrice = (pathname: string, itemId?: string) => {
  return useQuery([pathname, 'getLowestPrice', { itemId }], () => getLowestPrice(itemId), {
    enabled: itemId !== undefined,
    refetchOnWindowFocus: false,
  })
}
