import dynamic from 'next/dynamic'

const CollectionReportDialogSSR = dynamic(() => import('./CollectionReportDialog'), { ssr: false })
const ConnectWalletDialogSSR = dynamic(() => import('./ConnectWalletDialog'), { ssr: false })
const DisconnectedWalletDialogSSR = dynamic(() => import('./DisconnectedWalletDialog'), {
  ssr: false,
})
const OriginalsRankingGuideDialogSSR = dynamic(() => import('./OriginalsRankingGuideDialog'), {
  ssr: false,
})
const ProfileEditDialogSSR = dynamic(() => import('./ProfileEditDialog'), { ssr: false })
const QrDialogSSR = dynamic(() => import('./QrDialog'), { ssr: false })
const SwapDialogSSR = dynamic(() => import('./SwapDialog'), { ssr: false })
const SwapChainSelectDialogSSR = dynamic(() => import('./SwapChainSelectDialog'), { ssr: false })
const TradeSupportItemDialogSSR = dynamic(() => import('./TradeSupportItemDialog'), { ssr: false })
const TransferFailDialogSSR = dynamic(() => import('./TransferFailDialog'), { ssr: false })
const TransferFormDialogSSR = dynamic(() => import('./TransferFormDialog'), { ssr: false })

const ExceedTransferDialogSSR = dynamic(() => import('./ExceedTransferDialog'), { ssr: false })
const TxProgressForTransferDialogSSR = dynamic(() => import('./TxProgressForTransferDialog'), {
  ssr: false,
})
const WalletWaitingForTransferDialogSSR = dynamic(
  () => import('./WalletWaitingForTransferDialog'),
  {
    ssr: false,
  },
)
const ApplyDialogSSR = dynamic(() => import('./ApplyDialog'), {
  ssr: false,
})

const GoAppHomeDialogSSR = dynamic(() => import('./GoAppHomeDialog'), { ssr: false })

const CheckRaffleResultDialogSSR = dynamic(() => import('./CheckRaffleResultDialog'), {
  ssr: false,
})
const NoticeCopyAddressDialogSSR = dynamic(() => import('./NoticeCopyAddress'), {
  ssr: false,
})
const PurchaseCancelDialogSSR = dynamic(() => import('./PurchaseCancelDialog'), {
  ssr: false,
})

const ChangeRegionSettingsDialogSSR = dynamic(() => import('./ChangeRegionSettingsDialog'), {
  ssr: false,
})

export {
  ApplyDialogSSR,
  ChangeRegionSettingsDialogSSR,
  CheckRaffleResultDialogSSR,
  CollectionReportDialogSSR,
  ConnectWalletDialogSSR,
  DisconnectedWalletDialogSSR,
  ExceedTransferDialogSSR,
  GoAppHomeDialogSSR,
  NoticeCopyAddressDialogSSR,
  OriginalsRankingGuideDialogSSR,
  ProfileEditDialogSSR,
  PurchaseCancelDialogSSR,
  QrDialogSSR,
  SwapChainSelectDialogSSR,
  SwapDialogSSR,
  TradeSupportItemDialogSSR,
  TransferFailDialogSSR,
  TransferFormDialogSSR,
  TxProgressForTransferDialogSSR,
  WalletWaitingForTransferDialogSSR,
}
