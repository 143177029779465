import { useEffect, useMemo } from 'react'

import { IOriginalsScheduleResponse } from '../types'
import { useCountDown } from './useCountDown'

interface IUseFloatingBarStatusPropsType {
  schedule?: IOriginalsScheduleResponse
}
export const useFloatingBarState = ({ schedule }: IUseFloatingBarStatusPropsType) => {
  const { time, setCountDownDate, setNowFromServer } = useCountDown()

  const isNegative = (num: number) => {
    return num < 0
  }

  const scheduleState = useMemo(() => {
    const initialState = {
      countDownDate: 0,
      isSaleInProgress: false,
      isSaleFinish: false,
      isShowScheduleBar: false,
    }
    if (schedule === undefined) return initialState

    const now = new Date(schedule.nowDate).getTime()

    const startDistance = new Date(schedule.saleStartAt).getTime() - now
    const endDistance = new Date(schedule.saleEndAt).getTime() - now

    const isSaleInProgress = isNegative(startDistance)
    const isSaleFinish = isNegative(endDistance)

    const countDownDate = !isSaleInProgress ? schedule.saleStartAt : schedule.saleEndAt

    const isShowScheduleBar = !time.on && !isSaleFinish

    return {
      countDownDate,
      isSaleInProgress,
      isSaleFinish,
      isShowScheduleBar,
    }
  }, [schedule, time.on])

  useEffect(() => {
    if (scheduleState === null) return
    if (schedule !== undefined && schedule.nowDate) {
      setNowFromServer(new Date(schedule.nowDate))
    }
    if (scheduleState.countDownDate !== null) {
      setCountDownDate(new Date(scheduleState.countDownDate))
    }
  }, [scheduleState, schedule, setCountDownDate, setNowFromServer])

  return { time, scheduleState }
}
