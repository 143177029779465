import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query'

import {
  IBaseResponse,
  ICollectionItemResponse,
  ICollectionItemsDebouncedQueryParams,
  IGetCollectionItemRequest,
} from '@/shared/types'

import withAxios from '../api'

export const getCollectionItems = async (
  collectionAddress?: string,
  params?: IGetCollectionItemRequest,
): Promise<ICollectionItemResponse> => {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<ICollectionItemResponse>>({
    url: `/api/v1/public/collections/${collectionAddress?.toLowerCase()}/items`,
    method: 'GET',
    params,
  })
  return data
}

export const useInfiniteGetCollectionItems = (
  pathname: string,
  isRefresh: boolean,
  collectionAddress?: string,
  debouncedQueryParams?: ICollectionItemsDebouncedQueryParams,
): UseInfiniteQueryResult<ICollectionItemResponse> => {
  return useInfiniteQuery(
    [pathname, 'collectionItem', { collectionAddress, debouncedQueryParams }],
    async ({ pageParam }) => {
      const queryParams = {
        ...debouncedQueryParams,
        page: pageParam,
        perPage: 20,
        isRefresh,
      } as IGetCollectionItemRequest
      const response = await getCollectionItems(collectionAddress, queryParams)
      return response
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      enabled: collectionAddress !== undefined,
    },
  )
}
