import { useQuery } from '@tanstack/react-query'

import withAxios from '@/shared/services/api'
import { getEnvValue } from '@/shared/utils'

export interface IUtilitiesItem {
  itemId: string
  benefitDescription: string | null
  benefitItems:
    | {
        benefitTitle: string
        benefitStatus: boolean
        benefitEndAt: number
      }[]
    | null
}

export interface IItemUtilitiesResponse {
  benefits: IUtilitiesItem[]
}

export interface IItemUtilitiesRequest {
  itemIds: string[]
}

interface IGetItemUtilitiesProps extends IItemUtilitiesRequest {
  hasBenefits?: boolean
}

export const getItemUtilities = async ({ itemIds }: IItemUtilitiesRequest) => {
  const UTILITIES_API_BASE_URL =
    getEnvValue('utilitiesApiBaseUrl') ||
    'https://7ob1rv41me.execute-api.ap-northeast-2.amazonaws.com'

  const url = `${UTILITIES_API_BASE_URL}/api/v2/public/utilities`

  const { data } = await withAxios<IItemUtilitiesResponse>({
    url,
    method: 'POST',
    data: { itemIds },
  })

  return data
}

// TODO @고영욱 ItemDetailDescription 파악 후, suspense: true 적용
export const useGetItemUtilities = ({ itemIds, hasBenefits }: IGetItemUtilitiesProps) => {
  return useQuery(['getItemUtilities', { itemIds }], async () => getItemUtilities({ itemIds }), {
    refetchOnWindowFocus: false,
    enabled: itemIds.length !== 0 && hasBenefits === true,
  })
}
