import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '..'

export interface IOfferedItemIds {
  offeredItemIds: string[]
  requestedItemIds: string[]
}

const initialState: IOfferedItemIds = {
  offeredItemIds: [],
  requestedItemIds: [],
}

export const offeredItemIdsSlice = createSlice({
  name: 'offeredItemIds',
  initialState,
  reducers: {
    resetOfferedItemIds: state => {
      state.offeredItemIds = []
      state.requestedItemIds = []
    },
    resetRequestedOfferedIds: state => {
      state.requestedItemIds = []
    },
    mergeOfferedItemIds: (state, action: PayloadAction<{ offeredItemIds: string[] }>) => {
      state.offeredItemIds = [
        ...new Set([...state.offeredItemIds, ...action.payload.offeredItemIds]),
      ]
    },
    mergeRequestedItemIds: (state, action: PayloadAction<{ requestedItemIds: string[] }>) => {
      state.requestedItemIds = [
        ...new Set([...state.requestedItemIds, ...action.payload.requestedItemIds]),
      ]
    },
  },
})

export const selectOfferedItemIds = (state: AppState) => state.offeredItemIds.offeredItemIds
export const selectRequestedItemIds = (state: AppState) => state.offeredItemIds.requestedItemIds
export const {
  resetOfferedItemIds,
  resetRequestedOfferedIds,
  mergeOfferedItemIds,
  mergeRequestedItemIds,
} = offeredItemIdsSlice.actions

export default offeredItemIdsSlice
