import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '..'

export interface ISignatureState {
  signature: string | null
  isSigning: boolean
  isSignError: string | null
}

export const initialState: ISignatureState = {
  signature: null,
  isSigning: false,
  isSignError: null,
}

export const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    resetSignatureState: state => {
      state.signature = null
      state.isSigning = false
      state.isSignError = null
    },
    setIsSigning: (state, action: PayloadAction<boolean>) => {
      state.isSigning = action.payload
    },
    setSignIsError: (state, action: PayloadAction<string | null>) => {
      state.isSignError = action.payload
    },
    setSignature: (state, action: PayloadAction<string | null>) => {
      state.signature = action.payload
    },
  },
})

export const selectSignature = (state: AppState) => state.signature.signature
export const selectIsSigning = (state: AppState) => state.signature.isSigning
export const selectIsSignError = (state: AppState) => state.signature.isSignError
export const selectSignatureStatus = (state: AppState) => ({
  ...state.signature,
  isSigned: state.signature.signature !== null,
})

export const { resetSignatureState, setIsSigning, setSignIsError, setSignature } =
  signatureSlice.actions

export default signatureSlice
