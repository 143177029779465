export const shortenAddress = (address: string | null, isMobile = false) => {
  if (!address) return ''
  if (isMobile) return `${address.slice(0, 2)}...${address.slice(-2)}`
  return `${address.slice(0, 4)}...${address.slice(-4)}`
}
export const shortenDisplayName = (displayName: string | null) => {
  if (displayName === null) return ''
  if (displayName.length <= 8) return displayName
  return `${displayName.slice(0, 8)}...`
}
