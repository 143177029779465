import { useDebouncedValue } from '@mantine/hooks'
import { useMemo } from 'react'

import { ICollectionItemsFilterState } from '../store/filters/collectionItemsFilterSlice'

export function useMemoCollectionFilterQueryParams(
  state: ICollectionItemsFilterState,
  options: { exclude?: string[] } = {},
) {
  const memorizedQueryParams = useMemo(() => {
    const { minPrice, maxPrice } = state
    const queryString = {
      isListing: state.isListing || isNumeric(minPrice) || isNumeric(maxPrice),
      paymentType: state.paymentType,
      order: state.order,
      orderBy: state.orderBy,
      minPrice: state.minPrice,
      maxPrice: state.maxPrice,
      filterPropertyIds:
        state?.filterPropertyIds
          ?.map(item => item.id)
          .filter(Boolean)
          .join(',')
          .trim() || '',
    }
    if (!isNumeric(minPrice) && !isNumeric(maxPrice)) {
      delete queryString['paymentType']
    }
    if (options.exclude !== undefined) {
      options.exclude.forEach(key => {
        if (key === 'paymentType') {
          delete queryString[key]
        }
      })
    }

    return queryString
  }, [options.exclude, state])

  const [debouncedQueryParams] = useDebouncedValue(memorizedQueryParams, 200)

  function isNumeric(str: string | undefined) {
    if (str === undefined) {
      return false
    }
    return !isNaN(Number(str)) && !isNaN(parseFloat(str))
  }

  return debouncedQueryParams
}
