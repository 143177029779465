import {
  DEV_CDN_HOST_URL,
  DEV_S3_HOST_URL,
  PROD_CDN_HOST_URL,
  PROD_S3_HOST_URL,
} from '../constants/optimizeImageUrl'

export default function optimizeImage(url: string | null, width?: number, height?: number) {
  if (url === null || url === '') return '/img/placeholder/item-card-replace.png'

  const isDevS3HostUrl = url?.includes(DEV_S3_HOST_URL)
  const isProdS3HostUrl = url?.includes(PROD_S3_HOST_URL)

  const s3HostUrl = isDevS3HostUrl ? DEV_S3_HOST_URL : PROD_S3_HOST_URL
  const cdnHostUrl = isDevS3HostUrl ? DEV_CDN_HOST_URL : PROD_CDN_HOST_URL

  if (!isDevS3HostUrl && !isProdS3HostUrl) return url ?? '/img/placeholder/item-card-replace.png'

  const imageReg = /.png|.jpg|.jpeg|.gif|.svg/g

  const replaceHostUrl = (url: string) => {
    let result = url

    if (imageReg.test(url)) {
      result = url.replace(s3HostUrl, cdnHostUrl)
    }

    return result
  }

  const replaceResizeUrl = (url: string) =>
    url
      .replace('cloudfront.net/', 'cloudfront.net/thumbnail/')
      .replace(imageReg, str => `__resize__${width ?? 500}x${height ?? 0}${str}`)

  const optimizeImageUrl = (url: string) => {
    const replacedHostUrl = replaceHostUrl(url)
    if (width === undefined && height === undefined) return replacedHostUrl
    const replacedResizeUrl = replaceResizeUrl(replacedHostUrl)
    return replacedResizeUrl
  }

  return optimizeImageUrl(url)
}
