import { Tooltip } from '@mantine/core'
import { ReactNode } from 'react'

interface IToolTipProps {
  text: string
  direction?: 'left' | 'right' | 'top' | 'bottom'
  children: ReactNode
  offset?: number
  arrowSize?: number
}

export default function ToolTip({
  text,
  direction = 'top',
  children,
  offset = 0,
  arrowSize = 4,
}: IToolTipProps) {
  return (
    <>
      <Tooltip
        label={text}
        withArrow={true}
        position={direction}
        arrowSize={arrowSize}
        offset={offset}>
        <div>{children}</div>
      </Tooltip>
    </>
  )
}
