import { useMediaQuery } from '@mantine/hooks'
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react'

import { APP_PATH_TYPE, ManagedDialog } from '../constants'
import {
  useDialog,
  useGetLoginCode,
  useGetSnapshotInError,
  useGtmCustomEvent,
  useIsReactNativeWebView,
} from '../hooks'
import { getCurrentAccountProfile } from '../services/accounts'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  connectWallet,
  disconnectWallet,
  selectCurrentProfile,
  selectIsConnectedWallet,
  selectIsRequestDisconnectWallet,
  selectIsWalletConnecting,
  setCurrentProfileState,
} from '../store/wallet'
import { CustomError } from '../types'
import { getDeepLink, getEnvValue, LocalStorage } from '../utils'

interface IAuthContext {
  isConnectedWallet: boolean
  isWebView: boolean | null
  requestCodeForLogin: () => void
  isConnectingWallet: boolean
  isRequestingCodeForLogin: boolean
  isRequestingLoginCodeError: boolean
}

const AuthContext = createContext<IAuthContext>({
  isConnectedWallet: false,
  isWebView: false,
  requestCodeForLogin: () => undefined,
  isConnectingWallet: false,
  isRequestingCodeForLogin: false,
  isRequestingLoginCodeError: false,
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const isWebView = useIsReactNativeWebView()
  const isMobile = useMediaQuery('(max-width: 690px)')

  const isDev = getEnvValue('mode') === 'development'
  const { setDialog } = useDialog()
  const isFirstCall = useRef<boolean>(false)
  const { setUserId } = useGtmCustomEvent()

  const { getCurrentSnapshotInError } = useGetSnapshotInError()

  const dispatch = useAppDispatch()
  const isConnectingWallet = useAppSelector(selectIsWalletConnecting)
  const isConnectedWallet = useAppSelector(selectIsConnectedWallet)
  const currentProfile = useAppSelector(selectCurrentProfile)
  const isRequestDisconnectWallet = useAppSelector(selectIsRequestDisconnectWallet)

  const appLink = getDeepLink(APP_PATH_TYPE.HOME, isDev)
  const {
    data,
    isError: isRequestingLoginCodeError,
    requestCodeForLogin,
    isRequestingCodeForLogin,
  } = useGetLoginCode()

  const fetchCurrentAccountProfile = useCallback(async () => {
    try {
      const profile = await getCurrentAccountProfile()
      dispatch(
        setCurrentProfileState({
          accountAddress: profile.accountAddress,
          chainId: profile.chainId,
          userName: profile.username,
        }),
      )
      setUserId(profile.accountAddress)
    } catch (e: unknown) {
      dispatch(disconnectWallet())
    }
  }, [dispatch, setUserId])

  const bindLogoutFuncAtWindowObj = useCallback(() => {
    if (isWebView) {
      window.logout = () => {
        dispatch(disconnectWallet())
      }
    }
  }, [dispatch, isWebView])

  useEffect(() => {
    if (!isConnectedWallet && data === '' && isRequestDisconnectWallet === false && isWebView) {
      requestCodeForLogin()
      isFirstCall.current = true
    }
  }, [data, isConnectedWallet, isRequestDisconnectWallet, isWebView, requestCodeForLogin])

  useEffect(() => {
    if (
      data !== '' &&
      !isConnectedWallet &&
      !isConnectingWallet &&
      isWebView &&
      isRequestingCodeForLogin === false &&
      isFirstCall.current === true
    ) {
      isFirstCall.current = false
      dispatch(connectWallet(data))
    }

    if (isRequestingLoginCodeError) {
      const snapshotInError = getCurrentSnapshotInError()
      new CustomError({
        name: 'LOGIN_ERROR',
        message: '로그인 코드를 받지 못했습니다.',
      }).sendToSentry({
        ...snapshotInError,
        extra: {
          data,
        },
      })
    }
  }, [
    data,
    dispatch,
    getCurrentSnapshotInError,
    isConnectedWallet,
    isConnectingWallet,
    isRequestingCodeForLogin,
    isRequestingLoginCodeError,
    isWebView,
  ])

  useEffect(() => {
    if (isMobile === undefined || isWebView === null) return
    if (!isMobile && !isWebView) {
      LocalStorage.setItem('appLink', appLink)
      setDialog(ManagedDialog.qr)
    }
  }, [appLink, isMobile, isWebView, setDialog])

  useEffect(() => {
    if (!isConnectedWallet || currentProfile !== null) return
    fetchCurrentAccountProfile()
  }, [currentProfile, fetchCurrentAccountProfile, isConnectedWallet])

  return (
    <AuthContext.Provider
      value={{
        isConnectedWallet,
        isWebView,
        requestCodeForLogin,
        isConnectingWallet,
        isRequestingCodeForLogin,
        isRequestingLoginCodeError,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
