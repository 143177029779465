export const DEFAULT_ROYALTIES = {
  marketRoyalty: '0',
  collectionRoyalties: ['0'],
  priceExcludingRoyalties: '0',
}

export const DEFAULT_TOTAL_COLLECTION_ROYALTY_INFO = {
  totalCollectionRoyalties: '0',
  totalCollectionRoyaltiesPercent: '0',
}
