import { useInfiniteQuery } from '@tanstack/react-query'

import {
  IAccountActivityDebouncedQueryParams,
  IAccountActivityListResponse,
  IBaseResponse,
  IGetAccountActivityListRequest,
} from '@/shared/types'

import withAxios from '../api'

export const getPublicAccountActivities = async (
  accountAddress?: string,
  params?: IGetAccountActivityListRequest,
) => {
  const url = `/api/v1/public/accounts/${accountAddress}/activities`
  const { data } = await withAxios<IBaseResponse<IAccountActivityListResponse>>({
    url,
    method: 'GET',
    params,
  })
  return data
}

export const useGetPublicAccountActivities = (
  pathname: string,
  refresh: boolean,
  accountAddress?: string,
  debouncedQueryParams?: IAccountActivityDebouncedQueryParams,
) =>
  useInfiniteQuery<IAccountActivityListResponse>(
    [pathname, 'getPublicAccountActivities', { debouncedQueryParams }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...debouncedQueryParams,
        refresh,
        perPage: 10,
        page: pageParam,
        tradeTypes: 'SALE,LISTING,TRANSFER',
      } as IGetAccountActivityListRequest
      if (queryParams.collectionAddresses === '') {
        delete queryParams['collectionAddresses']
      }
      const response = await getPublicAccountActivities(accountAddress, queryParams)
      if (response.message !== 'ok') throw Error(response.message)
      return response.data
    },
    {
      getNextPageParam: data => (data.page < data.totalPage ? data.page + 1 : undefined),
      enabled: accountAddress !== undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
