import { useMemo } from 'react'

import { TRANSACTION_SCOPE } from '../constants'
import { IActivityItem, IItem, IItemDetail } from '../types'

export const useTransferCountInfo = (item: (IItem & IActivityItem) | IItemDetail) => {
  return useMemo(() => {
    const { transferCount, collection } = item
    const transactionScope = collection?.transactionScope
    const maxTransferCount = collection?.maxTransferCount ?? null

    const isUnlimitedTransferCount =
      maxTransferCount === null && transactionScope === TRANSACTION_SCOPE.INTERNAL_TRANSFER

    const remainingTransferCount =
      !isUnlimitedTransferCount && maxTransferCount !== null
        ? maxTransferCount - transferCount
        : undefined

    const isExceedTransferCountByItem =
      typeof remainingTransferCount === 'number' && remainingTransferCount <= 0

    return {
      remainingTransferCount,
      isExceedTransferCountByItem,
    }
  }, [item])
}
