import { getPayAmountsByPrice } from '@modernlion/marketplace-registry'
import { OrderWithCounter } from '@opensea/seaport-js/lib/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { getCollectionStats } from '@/shared/services/collections'
import {
  createOffer,
  getHighestPrice,
  getInactiveListingList,
  getOfferList,
  getPublicHighestPrice,
  getRoyaltyInfo,
  getTradeAddress,
} from '@/shared/services/itemOrder'
import {
  ICreateOfferFormData,
  instanceOfCollectionStats,
  instanceOfListingItemList,
  instanceOfOfferItem,
  instanceOfOfferItemList,
  instanceOfRoyaltyInfo,
  instanceofTradeAddress,
  IOfferItemInfo,
  IRoyaltyInfo,
} from '@/shared/types'

import { AppState } from '..'

export interface IOfferState {
  action: 'create' | 'edit' | 'cancel' | null
  offerItemInfo: IOfferItemInfo | null
  offerFormData: ICreateOfferFormData | null

  isFetchingOfferItemInfo: boolean
  isFetchingOfferItemInfoError: string | null

  order: OrderWithCounter | null
  orderHash: string | null
  counter: number | null

  isOfferCreated: boolean
  isOfferCreating: boolean
  isCreateOfferError: string | null
}

const initialState: IOfferState = {
  action: null,
  offerItemInfo: null,
  offerFormData: null,
  isFetchingOfferItemInfo: false,
  isFetchingOfferItemInfoError: null,
  order: null,
  orderHash: null,
  counter: null,
  isOfferCreated: false,
  isOfferCreating: false,
  isCreateOfferError: null,
}

export const getOfferItemInfo = createAsyncThunk(
  'offer/getOfferItemInfo',
  async ({
    itemId,
    collectionAddress,
    tokenId,
    collectionTitle,
    itemTitle,
    mediaUrl,
    chainId,
  }: {
    itemId: string
    collectionAddress: string
    tokenId: number | string
    collectionTitle: string
    itemTitle: string
    mediaUrl: string
    chainId: string
  }) => {
    const promises = [
      getTradeAddress(),
      getRoyaltyInfo(itemId),
      getCollectionStats(collectionAddress),
      getHighestPrice(itemId),
      getOfferList(itemId),
      getPublicHighestPrice(itemId),
      getInactiveListingList(itemId),
    ]
    try {
      const [
        tradeAddress,
        royaltyInfo,
        collectionStats,
        offerItemToEdit,
        offerItemListToCancel,
        publicHighestPriceOffer,
        inactiveListingList,
      ] = await Promise.all(promises)

      return {
        itemId,
        collectionAddress,
        tokenId,
        collectionTitle,
        itemTitle,
        mediaUrl,
        chainId,
        royaltyInfo: instanceOfRoyaltyInfo(royaltyInfo) ? royaltyInfo : null,
        collectionStats: instanceOfCollectionStats(collectionStats) ? collectionStats : null,
        offerItemToEdit: instanceOfOfferItem(offerItemToEdit) ? offerItemToEdit : null,
        offerItemListToCancel: instanceOfOfferItemList(offerItemListToCancel)
          ? offerItemListToCancel
          : null,
        publicHighestPriceOffer: instanceOfOfferItem(publicHighestPriceOffer)
          ? publicHighestPriceOffer
          : null,
        inactiveListingList: instanceOfListingItemList(inactiveListingList)
          ? inactiveListingList
          : null,
        tradeAddress: instanceofTradeAddress(tradeAddress) ? tradeAddress : null,
      }
    } catch (e: unknown) {
      return Promise.reject(e)
    }
  },
)

export const registerToBuyForItem = createAsyncThunk(
  'offer/createOffer',
  async ({
    itemId,
    collectionAddress,
    offerFormData,
    order,
    orderHash,
    counter,
    royaltyInfo,
    signature,
  }: {
    itemId: string
    collectionAddress: string
    offerFormData: ICreateOfferFormData
    order: OrderWithCounter
    orderHash: string
    counter: number
    royaltyInfo: IRoyaltyInfo
    signature: string
  }) => {
    const { price, paymentType } = offerFormData
    const { salt, startTime, endTime, zoneHash } = order.parameters
    const { marketFee, royalties } = royaltyInfo
    const { priceExcludingRoyalties } = getPayAmountsByPrice(price, marketFee, royalties)

    try {
      await createOffer(itemId, {
        collectionAddress,
        signHash: signature,
        orderHash,
        paymentType,
        price,
        payAmount: priceExcludingRoyalties,
        salt,
        maximumFill: 1,
        makingTime: dayjs.unix(startTime as number).format(),
        expiredAt: dayjs.unix(endTime as number).format(),
        counter,
        zoneHash,
      })
      return true
    } catch (error: unknown) {
      return Promise.reject(error)
    }
  },
)

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    resetOfferState: state => {
      state.action = null
      state.offerItemInfo = null
      state.offerFormData = null
      state.isFetchingOfferItemInfo = false
      state.isFetchingOfferItemInfoError = null
      state.order = null
      state.orderHash = null
      state.counter = null
      state.isOfferCreated = false
      state.isOfferCreating = false
      state.isCreateOfferError = null
    },
    setOfferAction: (state, action: PayloadAction<'create' | 'edit' | 'cancel' | null>) => {
      state.action = action.payload
    },
    setOfferItemInfo: (state, action: PayloadAction<IOfferItemInfo | null>) => {
      state.offerItemInfo = action.payload
    },
    setOfferFormData: (state, action: PayloadAction<ICreateOfferFormData | null>) => {
      state.offerFormData = action.payload
    },
    setOrderForOffer: (state, action: PayloadAction<OrderWithCounter | null>) => {
      state.order = action.payload
    },
    setOrderHashForOffer: (state, action: PayloadAction<string | null>) => {
      state.orderHash = action.payload
    },
    setCounterForOffer: (state, action: PayloadAction<number | null>) => {
      state.counter = action.payload
    },
    setIsOfferCreated: (state, action: PayloadAction<boolean>) => {
      state.isOfferCreated = action.payload
    },
    setIsOfferCreating: (state, action: PayloadAction<boolean>) => {
      state.isOfferCreating = action.payload
    },
    setIsCreateOfferError: (state, action: PayloadAction<string | null>) => {
      state.isCreateOfferError = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getOfferItemInfo.pending, state => {
      state.offerItemInfo = null
      state.offerFormData = null
      state.isFetchingOfferItemInfo = true
      state.isFetchingOfferItemInfoError = null
    })
    builder.addCase(getOfferItemInfo.fulfilled, (state, action) => {
      state.offerItemInfo = action.payload
      state.isFetchingOfferItemInfo = false
    })
    builder.addCase(getOfferItemInfo.rejected, (state, action) => {
      state.isFetchingOfferItemInfo = false
      if (action.error.message) {
        state.isFetchingOfferItemInfoError = action.error.message
      }
    })
    builder.addCase(registerToBuyForItem.pending, state => {
      state.isOfferCreating = true
    })
    builder.addCase(registerToBuyForItem.fulfilled, state => {
      state.isOfferCreated = true
      state.isOfferCreating = false
    })
    builder.addCase(registerToBuyForItem.rejected, (state, action) => {
      state.isOfferCreating = false
      if (action.error.message) {
        state.isCreateOfferError = action.error.message
      }
    })
  },
})

export const selectOfferAction = (state: AppState) => state.offer.action
export const selectOfferItemInfo = (state: AppState) => state.offer.offerItemInfo
export const selectIsFetchingOfferItemInfo = (state: AppState) =>
  state.offer.isFetchingOfferItemInfo
export const selectIsFetchingOfferInfoError = (state: AppState) =>
  state.offer.isFetchingOfferItemInfoError
export const selectOfferFormData = (state: AppState) => state.offer.offerFormData
export const selectIsOfferCreated = (state: AppState) => state.offer.isOfferCreated
export const selectIsOfferCreating = (state: AppState) => state.offer.isOfferCreating
export const selectIsCreateOfferError = (state: AppState) => state.offer.isCreateOfferError
export const selectCreateOfferStatus = (state: AppState) => ({
  isOfferCreated: state.offer.isOfferCreated,
  isOfferCreating: state.offer.isOfferCreating,
  isCreateOfferError: state.offer.isCreateOfferError,
})

export const {
  resetOfferState,
  setOfferAction,
  setOfferItemInfo,
  setOfferFormData,
  setOrderForOffer,
  setOrderHashForOffer,
  setCounterForOffer,
  setIsOfferCreated,
  setIsCreateOfferError,
  setIsOfferCreating,
} = offerSlice.actions

export default offerSlice
