import { useQuery } from '@tanstack/react-query'

import { IBaseResponse, ITradeAddress } from '@/shared/types'

import withAxios from '../api'

export const getTradeAddress = async () => {
  const {
    data: { data },
  } = await withAxios<IBaseResponse<ITradeAddress>>({ url: '/api/v1/public/contracts/trade' })
  return data
}

export const useGetTradeAddress = (pathname: string) => {
  return useQuery([pathname, 'getTradeAddress'], getTradeAddress)
}
