import {
  ISearchAllCollectionsData,
  ISearchCollectionsResponse,
  ISearchOnboardingsData,
  ISearchOnboardingsResponse,
} from '../types'

export const searchCollectionsTransformer = (
  collections: ISearchCollectionsResponse,
): ISearchAllCollectionsData => {
  const collectionData = collections.data.attributes
  return {
    title: collectionData.title,
    moreText: collectionData.allCollections[0].moreText,
    internalUrl: collectionData.allCollections[0].internalUrl,
    items: collectionData.allCollections[0].items.map(item => ({
      title: item.title,
      collectionAddress: item.collectionAddress,
      image: item.image.data.attributes.formats.thumbnail.url,
      categoryTitle: item.category,
      isOriginals: item.isOriginals,
      isVerified: item.isVerified,
      chainId: item.chainId,
    })),
  }
}

export const searchOnboardingsTransformer = (
  onboardings: ISearchOnboardingsResponse,
): ISearchOnboardingsData => {
  const onboardingData = onboardings.data.attributes
  return {
    title: onboardingData.title,
    onboardings: onboardingData.onboardings.map(onboarding => ({
      title: onboarding.title,
      description: onboarding.description,
      image: onboarding.image.data.attributes.formats.thumbnail.url,
      internalUrl: onboarding.internalUrl,
      externalUrl: onboarding.externalUrl,
    })),
  }
}
